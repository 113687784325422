.bracket {
    .editable {
        &:focus-visible {
            outline: none !important;
        }

        span:focus-visible {
            outline: none !important;
        }
    }

    .roundColumn.flex {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 100%;

        &.round2 {
            width: unset;
        }

        .roundspan {
            .box {
                width: stretch;
                margin: calc(5px * var(--sizemultiplier));
                overflow: hidden;

                .boxname {
                    border-radius: unset;

                }
            }
        }

        &.middle {
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            margin: calc(var(--postheight) * -0.08) auto;
        }
    }

    .bracket-min {
        .box {
            width: calc(var(--postwidth) * 0.15) !important;

            .boxtexture {
                left: 0;
            }

            .boxname{
                display: flex;
                align-items: center;
                font-size: calc(var(--namesize) * 0.3 * var(--sizemultiplier));
                height: 100%;
                padding: 0 calc(3px * var(--sizemultiplier));

                .spanname {
                    flex: 1;
                    text-align: left;
                    margin-left: calc(3px * var(--sizemultiplier));
                    z-index: 3;
                }

                .spanstat {
                    letter-spacing: calc(var(--statspacing)* 0.5);
                }

                .boximagecontainer {
                    margin: 0;
                    border-radius: 0;
                    height: 100%;
                    aspect-ratio: 1;
                    width: auto;
                    order: 0;

                    .boximage {
                        background-size: cover !important;
                    }
                }

                .playerImageContainer {
                    position: relative;
                    width: calc(20px * var(--sizemultiplier));
                    object-fit: contain;

                    .playerimage {
                        height: 100%;
                    }
                }
            }
        }
    }
        
    &.bracket64 {
        .middle {
            .box {
                width: calc(var(--postwidth) * 0.1) !important;
            }
        }

        .round64 {
            .box {
                height: calc(var(--postheight) * 0.02);
                max-height: calc(var(--postheight) * 0.02);
                margin: calc(1px * var(--sizemultiplier))  calc(5px * var(--sizemultiplier)) !important;
            }
        }

        .round32 {
            .box {
                height: calc(var(--postheight) * 0.04);
                max-height: calc(var(--postheight) * 0.04);
            }
        }

        .box {
            height: calc(var(--postheight) * 0.08);
            max-height: calc(var(--postheight) * 0.08);
        }
    }

    &.bracket32 {
        .middle {
            .box {
                width: calc(var(--postwidth) * 0.1) !important;
            }
        }

        .round32 {
            .box {
                height: calc(var(--postheight) * 0.04);
                max-height: calc(var(--postheight) * 0.04);
                margin: calc(1px * var(--sizemultiplier))  calc(5px * var(--sizemultiplier)) !important;
            }
        }

        .box {
            height: calc(var(--postheight) * 0.08);
            max-height: calc(var(--postheight) * 0.08);
        }

        &.one-sided {
            grid-template-columns: repeat(6, 1fr);

            .round32, .round16 {
                .box {
                    height: calc(var(--postheight) * 0.02);
                    max-height: calc(var(--postheight) * 0.02);
                }
            }

            .box {
                height: calc(var(--postheight) * 0.04);
                max-height: calc(var(--postheight) * 0.04);
            }
        }
    }

    &.bracket16 {
        .middle {
            margin: calc(var(--postheight) * -0.13) auto !important;

            .box {
                width: calc(var(--postwidth) * 0.16) !important;
            }
        }

        .round16 {
            .box {
                height: calc(var(--postheight) * 0.08);
                max-height: calc(var(--postheight) * 0.08);
                margin: calc(1px * var(--sizemultiplier))  calc(5px * var(--sizemultiplier)) !important;
            }
        }

        .box {
            height: calc(var(--postheight) * 0.16);
            max-height: calc(var(--postheight) * 0.16);
        }

        &.one-sided {
            grid-template-columns: repeat(5, 1fr);

            .round16 {
                .box {
                    height: calc(var(--postheight) * 0.04);
                    max-height: calc(var(--postheight) * 0.04);
                }
            }

            .box {
                height: calc(var(--postheight) * 0.08);
                max-height: calc(var(--postheight) * 0.08);
            }
        }
    }

    &.bracket12 {
        grid-template-columns: repeat(8, 1fr);

        .middle {
            margin-top: calc(var(--postheight) * -0.12) !important;

            .box {
                width: calc(var(--postwidth) * 0.15) !important;
            }
        }

        .round12 {
            justify-content: space-between;
        }

        .round8 {
            justify-content: space-evenly;
        }

        .box {
            height: calc(var(--postheight) * 0.15);
            max-height: calc(var(--postheight) * 0.15);
        }

        &.one-sided {
            grid-template-columns: repeat(5, 1fr);

            .round12 {
                justify-content: space-evenly;
                margin-top: calc(var(--postheight) * 0.04);

                .box {
                    height: calc(var(--postheight) * 0.07);
                    max-height: calc(var(--postheight) * 0.07);
                }
            }

            .round8 {
                justify-content: space-evenly;
                margin-top: 0;

                .box {
                    height: calc(var(--postheight) * 0.07);
                    max-height: calc(var(--postheight) * 0.07);
                }
            }

            .box {
                height: calc(var(--postheight) * 0.14);
                max-height: calc(var(--postheight) * 0.14);
            }
        }
    }

    &.bracket8 {
        .middle {
            margin: calc(var(--postheight) * -0.16) auto !important;

            .box {
                width: calc(var(--postwidth) * 0.19) !important;
            }
        }

        .round8 {
            justify-content: space-between;
        }

        .box {
            height: calc(var(--postheight) * 0.19);
            max-height: calc(var(--postheight) * 0.19);
        }

        &.one-sided {
            grid-template-columns: repeat(4, 1fr);

            .round8 .box {
                height: calc(var(--postheight) * 0.16);
                max-height: calc(var(--postheight) * 0.16);
            }

            .round8 .box {
                height: calc(var(--postheight) * 0.08);
                max-height: calc(var(--postheight) * 0.08);
            }
        }
    }

    &.bracket4 {
        .middle {
            margin-top: calc(var(--postheight) * -0.22) !important;

            .box {
                width: calc(var(--postwidth) * 0.25) !important;
            }
        }

        .box {
            height: calc(var(--postheight) * 0.25);
            max-height: calc(var(--postheight) * 0.25);
        }

        &.one-sided {
            grid-template-columns: repeat(3, 1fr);

            .box {
                height: calc(var(--postheight) * 0.17);
                max-height: calc(var(--postheight) * 0.17);
            }
        }
    }
}