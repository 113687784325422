.jerseyForm {
	textarea,
	.number-input {
		height: 30px !important;
		place-content: center;
	}
}

.icon-circle-button {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: white;
	border-radius: 35px;
	height: 35px;
	width: 35px;
	color: black;
	overflow: hidden;
}

.icon-circle-button svg {
	width: 100%;
	height: 100%;
	margin-top: auto;
}

.icon-menu button.szh-menu-button {
	width: auto;
	height: 40px;
}

.icon-menu .szh-menu {
	display: flex;
    flex-wrap: wrap;
    max-width: 175px;
    justify-content: center;
}

.icon-menu .szh-menu li {
	padding: 5px;
}

.flexfields {
	flex-direction: column;
	gap: 5px;
}

.field-row {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: row;
	gap: 5px;
	width: 100%;
}

.field-row-3 {
	flex-direction: column;
}

.field-row-3 .formGroup {
	overflow: auto;
	flex-direction: row;
	width: 100%;
	padding-bottom: 15px;
}

.field-row-3 .formGroup > * {
	min-width: 125px;
}

.jerseyForm {
	width: 100%;
	flex-flow: nowrap !important;
}

.jerseyForm .css-pwpwrn-container {
	margin-bottom: 0;
	height: 30px;
}

.jerseyForm .css-19voyu5-control,
.jerseyForm .css-14fy4w3-ValueContainer,
.jerseyForm .css-7u4jq0-IndicatorsContainer {
	height: 30px;
}

.jerseyForm .css-19voyu5-control {
	border-color: transparent;
}

.jerseyForm textArea,
.jerseyForm .number-input {
	padding: 0;
}

.jerseyForm .formGroup > span {
	width: 100%;
}

@media (max-width: 800px) {
	form.jerseyForm {
		flex-flow: column !important;
	}

	.field-row {
		flex-wrap: wrap;
		justify-content: center;
	}

	.field-row > * {
		min-width: 150px;
	}
}