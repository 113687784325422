.jerseyformat { 
	display: flex;
	flex-direction: column;
	justify-content: flex-end;

	.jerseysvg {
		position: absolute;
		z-index: 0;
	}
}

.jersey-name {
	position: absolute;
	width: 100%;
	text-align: center;
	color: white;
	font-family: var(--playernamefont);
	line-height: 1;
	letter-spacing: calc(var(--playernamespacing) * var(--sizemultiplier));
}

.name-svg {
	position: absolute;
	top: calc(var(--playernamemargin) - 5%);

	.name-svg-text {
		font-size: calc(var(--playernamesize) * .75 * var(--sizemultiplier));
		font-family: var(--playernamefont);
		letter-spacing: calc(var(--playernamespacing) * var(--sizemultiplier));
	}
}

.jersey-number {
	position: absolute;
    top: var(--playernumbermargin);
	width: 100%;
	text-align: center;
	color: white;
	font-size: calc(var(--playernumbersize) * var(--sizemultiplier));
	font-family: var(--playernumberfont);
	line-height: 1;
	letter-spacing: calc(var(--playernumberspacing) * var(--sizemultiplier));
	z-index: 1;
}

.jersey-accomplishments {
	display: flex;
	width: 100%;
	justify-content: space-around;
	position: relative;
	margin-bottom: 2%;
	z-index: 1;

	.jersey-accomplishment {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: calc(10px * var(--sizemultiplier));

		.icon-circle {
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: white;
			border-radius: calc(154px * var(--sizemultiplier));
			height: calc(154px * var(--sizemultiplier));
			width: calc(154px * var(--sizemultiplier));
			color: black;
			overflow: hidden;

			svg {
				width: 100%;
				height: 100%;
				margin-top: auto;
			}
		}

		.accomplishment-text {
			text-align: center;
			line-height: 1;
			font-weight: bold;
			font-family: var(--iconfont);
			font-size: calc(var(--iconsize) * var(--sizemultiplier));
		}
	}
}

.week-banners {
	display: flex;
	justify-content: space-around;
	align-items: center;
	position: relative;
	width: 100%;
	z-index: 1;
	margin-bottom: calc(-1px * var(--sizemultiplier));

	.banner-spacer {
		display: flex;
		flex: 1;
		min-width: 0;

		.week-banner {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			text-align: center;
			flex: 1;
			min-width: 0;
			overflow: hidden;
			font-family: var(--bannerfont);
			font-size: calc(var(--bannersize) * var(--sizemultiplier));
			margin: 0 calc(5px* var(--sizemultiplier));

			.week-num {
				font-size: 180%;
				margin-top: calc(-7px* var(--sizemultiplier));
			}
		}
	}
}