.article-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 20px;
  }
  
  .article-content {
	max-width: 800px;
	text-align: center;
  }
  
  .article-title {
	font-size: 2rem;
	margin-bottom: 10px;
  }
  
  .article-description {
	font-size: 1.2rem;
	color: #666;
	margin-bottom: 20px;
  }
  
  .article-body {
	/* Add your article body styles here */
  }

  .article-body h2 {
	font-size: 1.4rem;
  }
  
  .article-carousel {
	width: 100%;
	max-width: min(90vw, 550px);
	margin: 40px auto;
  }

  .swiper-slide-image {
	width: 100%;
	height: auto;
	border-radius: 10px;
  }
  