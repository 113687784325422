html {
	height: 100%;
	overflow: hidden;
}
.App {
	position: relative;
	display: flex;
	flex-direction: column;
	margin-bottom: 5px;
	font-family: 'roboto condensed', sans-serif;
}
@font-face {
	font-family: 'adidas';
	src: local('adidas'), url(./assets/fonts/adidas.otf) format('opentype');
}
@font-face {
	font-family: 'anton';
	src: local('anton'), url(./assets/fonts/Anton.ttf) format('truetype');
}
@font-face {
	font-family: 'aurach';
	src: local('aurach'), url(./assets/fonts/Aurach\ Heavy.ttf) format('truetype');
}
@font-face {
	font-family: 'beantown';
	src: local('beantown'), url(./assets/fonts/Beantown.ttf) format('truetype');
}
@font-face {
	font-family: 'berlina';
	src: local('berlina'), url(./assets/fonts/Berlina.ttf) format('truetype');
}
@font-face {
	font-family: 'carbon';
	src: local('carbon'), url(./assets/fonts/carbon\ bl.ttf) format('truetype');
}
@font-face {
	font-family: 'enfatica';
	src: local('enfatica'), url(./assets/fonts/Enfatica.otf) format('opentype');
}
@font-face {
	font-family: 'exo';
	src: local('exo'), url(./assets/fonts/Exo2-Bold.ttf) format('truetype');
}
@font-face {
	font-family: 'graduate';
	src: local('graduate'), url(./assets/fonts/Graduate-Regular.ttf) format('truetype');
}
@font-face {
	font-family: 'hemi';
	src: local('hemi'), url(./assets/fonts/hemi\ head\ bd\ it.ttf) format('truetype');
}
@font-face {
	font-family: 'jersey';
	src: local('jersey'), url(./assets/fonts/jersey.ttf) format('truetype');
}
@font-face {
	font-family: 'league gothic';
	src: local('league gothic'), url(./assets/fonts/LeagueGothic-Regular.otf) format('opentype');
}
@font-face {
	font-family: 'league spartan';
	src: local('league spartan'), url(./assets/fonts/LeagueSpartan-Bold.otf) format('opentype');
}
@font-face {
	font-family: 'metropolis';
	src: local('metropolis'), url(./assets/fonts/Metropolis-Medium.otf) format('opentype');
}
@font-face {
	font-family: 'montserrat';
	src: local('montserrat'), url(./assets/fonts/Montserrat-Regular.ttf) format('truetype');
}
@font-face {
	font-family: 'octin';
	src: local('octin'), url(./assets/fonts/octin\ college\ rg.ttf) format('truetype');
}
@font-face {
	font-family: 'open sans';
	src: local('open sans'), url(./assets/fonts/OpenSans-Regular.ttf) format('truetype');
}
@font-face {
	font-family: 'ostrich sans';
	src: local('ostrich sans'), url(./assets/fonts/OstrichSans-Medium.otf) format('opentype');
}
@font-face {
	font-family: 'oswald';
	src: local('oswald'), url(./assets/fonts/Oswald-Regular.ttf) format('truetype');
}
@font-face {
	font-family: 'playball';
	src: local('playball'), url(./assets/fonts/Playball-Regular.ttf) format('truetype');
}
@font-face {
	font-family: 'pop warner';
	src: local('pop warner'), url(./assets/fonts/Pop\ Warner.ttf) format('truetype');
}
@font-face {
	font-family: "roboto bold";
	src: local('roboto bold'), url(./assets/fonts/RobotoCondensed-Bold.ttf) format('truetype');
}
@font-face {
	font-family: "robotobold";
	src: local('robotobold'), url(./assets/fonts/RobotoCondensed-Bold.ttf) format('truetype');
}
@font-face {
	font-family: 'roboto condensed';
	src: local('roboto condensed'), url(./assets/fonts/RobotoCondensed-Regular.ttf) format('truetype');
}
@font-face {
	font-family: 'soccer';
	src: local('soccer'), url(./assets/fonts/SoccerLeague.ttf) format('truetype');
}
@font-face {
	font-family: 'stadium';
	src: local('stadium'), url(./assets/fonts/stadium.ttf) format('truetype');
}
@font-face {
	font-family: 'staubach';
	src: local('staubach'), url(./assets/fonts/Staubach.ttf) format('truetype');
}
@font-face {
	font-family: 'uberlin';
	src: local('uberlin'), url(./assets/fonts/Uberlin.ttf) format('truetype');
}
@font-face {
	font-family: 'ubuntu';
	src: local('ubuntu'), url(./assets/fonts/UbuntuMono-R.ttf) format('truetype');
}
@font-face {
	font-family: 'uni sans';
	src: local('uni sans'), url(./assets/fonts/Uni\ Sans\ Thin.otf) format('opentype');
}
@font-face {
	font-family: 'varsity';
	src: local('varsity'), url(./assets/fonts/varsity-team.bold.otf) format('opentype');
}

@font-face {
	font-family: 'bebas neue';
	src: local('bebas neue'), url(./assets/fonts/BebasNeue-Regular.ttf) format('truetype');
}
@font-face {
	font-family: 'american captain';
	src: local('american captain'), url(./assets/fonts/American\ Captain.ttf) format('truetype');
}
@font-face {
	font-family: 'uniform reg';
	src: local('uniform reg'), url(./assets/fonts/UniformRegular.otf) format('opentype');
}
@font-face {
	font-family: 'jersey';
	src: local('jersey'), url(./assets/fonts/Jersey\ M54.ttf) format('truetype');
}
@font-face {
	font-family: 'lemon milk light';
	src: local('lemon milk light'), url(./assets/fonts/LEMONMILK-Light.otf) format('opentype');
}
@font-face {
	font-family: 'lemon milk reg';
	src: local('lemon milk reg'), url(./assets/fonts/LEMONMILK-Regular.otf) format('opentype');
}
@font-face {
	font-family: 'new athletic';
	src: local('new athletic'), url(./assets/fonts/New\ Athletic\ M54.ttf) format('truetype');
}
@font-face {
	font-family: "the bold one";
	src: local('the bold one'), url(./assets/fonts/theboldfont.ttf) format('truetype');
}
@font-face {
	font-family: "theboldone";
	src: local('theboldone'), url(./assets/fonts/theboldfont.ttf) format('truetype');
}
@font-face {
	font-family: "uniform bold";
	src: local('uniform bold'), url(./assets/fonts/UniformBold.otf) format('opentype');
}
@font-face {
	font-family: "uniformbold";
	src: local('uniformbold'), url(./assets/fonts/UniformBold.otf) format('opentype');
}
@font-face {
	font-family: 'edo';
	src: local('edo'), url(./assets/fonts/edo.ttf) format('truetype');
}
@font-face {
	font-family: 'true lies';
	src: local('true lies'), url(./assets/fonts/True\ Lies.ttf) format('truetype');
}
@font-face {
	font-family: 'anonymous';
	src: local('anonymous'), url(./assets/fonts/AnonymousPro.ttf) format('truetype');
}
@font-face {
	font-family: 'antonio';
	src: local('antonio'), url(./assets/fonts/antonio.ttf) format('truetype');
}
@font-face {
	font-family: 'droid';
	src: local('droid'), url(./assets/fonts/DroidSansMono.ttf) format('truetype');
}
@font-face {
	font-family: 'plex';
	src: local('plex'), url(./assets/fonts/IBMPlexMono.ttf) format('truetype');
}
@font-face {
	font-family: 'monofont';
	src: local('monofont'), url(./assets/fonts/monofont.otf) format('opentype');
}
@font-face {
	font-family: 'poppinsbold';
	src: local('poppinsbold'), url(./assets/fonts/Poppins-Bold.ttf) format('truetype');
}
@font-face {
	font-family: 'poppinslight';
	src: local('poppinslight'), url(./assets/fonts/Poppins-Light.ttf) format('truetype');
}
@font-face {
	font-family: 'poppins';
	src: local('poppins'), url(./assets/fonts/Poppins-Medium.ttf) format('truetype');
}
@font-face {
	font-family: 'redwing';
	src: local('redwing'), url(./assets/fonts/Redwing.otf) format('opentype');
}
@font-face {
	font-family: 'rift';
	src: local('rift'), url(./assets/fonts/Rift.otf) format('opentype');
}
@font-face {
	font-family: 'robotoslab';
	src: local('robotoslab'), url(./assets/fonts/roboto-slab.ttf) format('truetype');
}
@font-face {
	font-family: 'square';
	src: local('square'), url(./assets/fonts/square_block.ttf) format('truetype');
}
@font-face {
	font-family: 'taurus';
	src: local('taurus'), url(./assets/fonts/Taurus-Mono-Outline-Bold.otf) format('opentype');
}
@font-face {
	font-family: 'trebuchet';
	src: local('trebuchet'), url(./assets/fonts/Trebuchet.ttf) format('truetype');
}
@font-face {
	font-family: 'viper';
	src: local('viper'), url(./assets/fonts/viper-squadron.ttf) format('truetype');
}
@font-face {
	font-family: 'block';
	src: local('Blockletter'), url(./assets/fonts/Blockletter.otf) format('opentype');
}
.Header {
	background-color: white;
	border-bottom: 1px solid #e4edff;
	display: flex;
	justify-content: center;
	position: relative;
}
.Dashboard .Header {
	background: linear-gradient(90deg, var(--primarycolor), rgb(111, 0, 255));
	border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}
.Header h1 {
	font-size: 30px;
	color: var(--primarycolor);
	font-weight: 700;
	margin: 6px;
}
.Dashboard .Header h1 {
	color: white;
}
.Header button {
	align-self: center;
	border-radius: 6px;
	font-size: 12px;
	padding: 5px 9px;
	text-transform: uppercase;
	background-color: var(--primarycolor);
	color: white;
	font-weight: bold;
	border: 2px solid var(--primarycolor);
	margin-right: 5px;
}
.filler a {
	color: white !important;
	text-align: center;
}
body {
	height: 100%;
	margin: 0px;
	overflow: auto;
}
.Statmaker {
	flex: 1;
	overflow-y: auto;
	background: white;
	padding: 0 2%;
}
.Perfect {
	min-width: 1000px;
}
.Dashboard form,
.Perfect form {
	display: flex;
	justify-content: center;
	flex-flow: wrap;
	z-index: 1;
}
.Dashboard label,
.Perfect label {
	font-size: 18px;
	text-align: center;
	font-weight: bold;
	margin-bottom: 0px;
	user-select: none;
}
.radiobtns {
	font-size: 20px;
	text-align: center;
	padding-top: 20px;
}
.Dashboard textarea,
.Perfect textarea,
.number-input {
	background: #f3f3f3;
	border: none;
	border-radius: 6px;
	font-size: 16px;
	font-weight: bold;
	line-height: 1;
	margin: 2px;
	resize: none;
}
.savedPostFilter {
	background: white;
	border: 1px solid #cccccc;
	border-radius: 4px;
	font-size: 11px;
	padding: 6px;
	font-weight: bold;
	line-height: 1;
	margin: 0px;
}
.Dashboard textarea:focus,
.Perfect textarea:focus,
.number-input:focus,
.savedPostFilter:focus {
	outline: none;
}
.savedPostFilter {
	width: 100%;
	margin-bottom: 10px;
}
input[type='radio'] {
	margin-left: 10px;
	appearance: none;
	display: inline-block;
	width: 14px;
	height: 14px;
	padding: 0px;
	background-clip: content-box;
	border: 2px solid var(--primarycolor);
	background-color: transparent;
	border-radius: 50%;
}
input[type='radio']:checked {
	padding: 1px !important;
	background-color: var(--primarycolor);
}
input[type='radio']:disabled {
	border: 2px solid #848484;
}
input[type='radio']:checked:disabled {
	background-color: #848484;
}
input[type='radio']:hover {
	box-shadow: 0 0 3pt 2pt cornflowerblue;
}
.Dashboard input[type='checkbox'] {
	margin-left: 10px;
}
.below {
	display: flex;
	justify-content: center;
	margin-top: 6px;
}
.above {
	display: flex;
	justify-content: center;
	margin: 6px;
}
.above .newButton {
	width: 200px;
}
.above2 {
	margin-bottom: 6px;
}
.form-buttons {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-content: center;
	justify-content: center;
	align-items: center;
}
.form-buttons .newButton,
.form-buttons .downloadVideoBtn,
.relativeBtn {
	display: flex;
	align-items: center;
	justify-content: center;
	align-content: center;
	flex-wrap: nowrap;
	flex-direction: row;
}
.main {
	display: flex;
	justify-content: center;
	margin-bottom: 10px;
}
.post,
.grid,
.tier,
.bracket,
.scores,
.matchup,
.matchupimage,
.highlightimage,
.lineupimage,
.comparison,
.jerseyformat,
.chart {
	background-size: cover;
	display: grid;
	grid-template-rows: none;
	height: var(--postheight);
	width: var(--postwidth);
	min-width: var(--postwidth);
	position: relative;
}

.post#postwithimage {
	width: 100%;
}

.postcontainer,
.grid,
.tier,
.bracket,
.scores,
.matchup,
.matchupimage,
.highlightimage,
.lineupimage,
.comparison,
.jerseyformat,
.chart {
	overflow: hidden;
}
.comparison {
	display: grid;
}
.boxname {
	position: relative;
	font-family: var(--namefont);
	font-size: var(--namesize);
	align-self: center;
	color: var(--namecolor);
	-webkit-text-stroke: calc(var(--nameoutlinesize) * var(--sizemultiplier)) var(--nameoutlinecolor);
	font-weight: 550;
	z-index: 99;
	width: inherit;
	line-height: 1.1;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin: 0px;
	letter-spacing: calc(var(--namespacing) * var(--sizemultiplier));
	margin-top: calc(var(--namemargin) * var(--sizemultiplier));
	margin-left: calc(var(--namemargin2) * var(--sizemultiplier));
	text-shadow: calc(var(--nameshadow) * var(--sizemultiplier)) calc(var(--nameshadow) * var(--sizemultiplier)) var(--nameshadowcolor);
	order: -1;
	text-transform: var(--nametransform);
}
.post .boxname {
	display: flex;
	min-width: 0;
	align-items: center;
	width: stretch;
}
.post .boxname > span {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: stretch;
}
.twocolumns .boxname {
	margin-left: calc((var(--namemargin2) - 4px) * var(--sizemultiplier));
}
.boxstat {
	font-family: var(--statfont);
	font-size: var(--statsize);
	align-self: center;
	color: var(--statcolor);
	-webkit-text-stroke: calc(var(--statoutlinesize) * var(--sizemultiplier)) var(--statoutlinecolor);
	font-weight: 550;
	letter-spacing: calc(var(--statspacing) * var(--sizemultiplier));
	text-align: right;
	margin-right: calc((var(--statmargin2) * -1 * var(--sizemultiplier)) + 5px);
	white-space: nowrap;
	margin-top: calc(var(--statmargin) * var(--sizemultiplier));
	text-shadow: calc(var(--statshadow) * var(--sizemultiplier)) calc(var(--statshadow) * var(--sizemultiplier)) var(--statshadowcolor);
	z-index: 1;
	position: relative;
	order: 1;
	text-transform: var(--stattransform);
}
.post .playerimage,
#postwithimage:not(.grid) .playerimage {
	display: flex;
	align-self: flex-end;
	z-index: 100;
	position: relative;
	order: var(--playerorder);
	max-height: calc(var(--postheight) * 0.1875);
	margin-bottom: calc(var(--playermargin) * -1);
	clip-path: inset(0px 0px var(--playermargin) 0px);
	border-top-right-radius: calc(calc(var(--borderradius) - 5px) * var(--sizemultiplier));
	border-bottom-right-radius: calc(calc(var(--borderradius) - 5px) * var(--sizemultiplier));
}

.post .playerimage.equalWidth,
#postwithimage:not(.grid) .playerimage.equalWidth {
	margin-bottom: 0;
	clip-path: unset;
	border-radius: unset;
	height: 100%;
	object-fit: cover;
	aspect-ratio: var(--playeraspectratio);
	z-index: 99;
}

.post .boximage {
	aspect-ratio: 1;
}

.player-image-min-width {
	min-width: var(--playerimagewidth);
}

.newButton {
	border-radius: 6px;
	font-size: 11px;
	margin-top: 5px;
	padding: 5px;
	text-transform: uppercase;
	width: 47%;
	margin: 10px;
	background-color: var(--primarycolor);
	color: white;
	font-weight: bold;
	border: 2px solid var(--primarycolor);
}
.newButtonSecondary {
	border-radius: 6px;
	font-size: 11px;
	margin-top: 5px;
	padding: 5px;
	text-transform: uppercase;
	width: 47%;
	margin: 10px;
	background-color: white;
	color: var(--primarycolor);
	font-weight: bold;
	border: 2px solid var(--primarycolor);
}
.edit .newButton,
.edit .newButtonSecondary {
	width: 100%;
	margin: 0px;
	margin-bottom: 5px;
}
.blueButton {
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 4px;
	font-size: 14px;
	padding: 5px;
	width: 88%;
	background-color: #3691e7;
	color: white;
	font-weight: bold;
	border: 2px solid #3691e7;
	margin: 1%;
}
.blueButton2 {
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 4px;
	font-size: 14px;
	padding: 3.5px;
	text-transform: uppercase;
	width: 8%;
	background-color: #3691e7;
	color: white;
	font-weight: bold;
	border: 2px solid #3691e7;
	margin: 1%;
}
button:hover,
.newButton:hover {
	outline: none;
	box-shadow: 0 0 3pt 2pt cornflowerblue;
}
button:focus {
	outline: none;
	box-shadow: 0 0 3pt 2pt cornflowerblue;
}
.hide {
	display: none !important;
}
.box {
	display: flex;
	position: relative;
	width: var(--postwidth);
}
.post .box {
	border-radius: calc(calc(var(--borderradius) - 5px) * var(--sizemultiplier));
}
.boxwrap {
	display: flex;
	position: relative;
}
.post .boxwrap {
	padding: calc(var(--boxmargintop) * var(--sizemultiplier)) calc(var(--boxmarginright) * var(--sizemultiplier)) calc(var(--boxmarginbottom) * var(--sizemultiplier)) calc(var(--boxmarginleft) * var(--sizemultiplier));
}
.boximagecontainer {
	font-size: var(--logosize);
	order: var(--logoorder);
	z-index: 1;
	position: relative;
	margin-right: calc(10px * var(--sizemultiplier));
}
.boximagecontainer.withBackground {
	box-shadow: inset 0px 0px calc(var(--logodropshadow) * var(--sizemultiplier)) calc(var(--logodropshadow) * var(--sizemultiplier) / 2) black;
}
.boximagecontainer:not(.postyimg) {
	width: 100%;
	height: 100%;
}
.boximage {
	background-position: calc(50% + calc(var(--logohorizontalmargin) * var(--sizemultiplier))) calc(50% + calc(var(--logoverticalmargin) * var(--sizemultiplier)));
	background-size: cover;
	background-repeat: no-repeat;
	margin-left: auto;
	opacity: var(--logoopacity);
	filter: drop-shadow(calc(var(--logoshadow) * var(--sizemultiplier)) calc(var(--logoshadow) * var(--sizemultiplier)) calc(var(--logoshadow) * var(--sizemultiplier)) black);
}
.boximagecontainer2 {
	position: absolute;
	right: calc(var(--duplicatelogoposition) * var(--sizemultiplier));
	width: calc(var(--duplicatelogosize) * var(--sizemultiplier));
	max-width: 100%;
	z-index: 0;
}
.boximagecontainer2 .boximage {
	opacity: var(--duplicatelogoopacity);
}
.boximagecontainer2 .boximagesplit {
	width: calc(var(--duplicatelogosize) * var(--sizemultiplier)) !important;
}
.boximagecontainer2 .boximage {
	min-width: unset;
	width: calc(var(--duplicatelogosize) * var(--sizemultiplier)) !important;
	max-width: 100% !important;
}
.boximagecontainer2 .cliptopleft + .clipbottomright,
.boximagecontainer2 .cliptop + .clipbottom,
.boximagecontainer2 .cliptopright + .clipbottomleft,
.boximagecontainer2 .clipright + .clipleft,
.boximagecontainer2 .clipbottomright + .cliptopleft,
.boximagecontainer2 .clipbottom + .cliptop,
.boximagecontainer2 .clipbottomleft + .cliptopright,
.boximagecontainer2 .clipleft + .clipright {
	margin-left: calc(-1 * var(--duplicatelogosize) * var(--sizemultiplier)) !important;
}
.boxborder {
	background: var(--bordercolor);
	bottom: calc(var(--bordersize) * var(--sizemultiplier) * -1);
	content: '';
	display: block;
	height: calc(var(--bordersize) * var(--sizemultiplier));
	left: 0px;
	position: absolute;
	width: 100%;
	z-index: 100;
}
.hidden {
	display: none;
	visibility: hidden;
}

.rc-slider {
	margin-bottom: 10px;
}

.rc-slider-rail {
	background-color: lightgray !important;
}

.rc-slider-track {
	background-color: var(--primarycolor) !important;
}

.rc-slider-handle {
	border-color: var(--primarycolor) !important;
}

.rc-slider-disabled {
	background-color: transparent !important;
}

.rc-slider-disabled .rc-slider-track {
	background-color: gray !important;
}

.rc-slider-disabled .rc-slider-handle {
	border-color: gray !important;
}

.edit {
	text-align: center;
	position: relative;
	display: flex;
	flex-flow: wrap;
}

.editInfo {
	width: 100%;
	text-decoration: underline;
}

.editInfo p {
	margin: 0;
	display: inline-block;
}

.edit h4 {
	margin: 0;
	border-bottom: 1px solid gray;
	margin-bottom: 8px;
	font-size: 16px;
	width: 100%;
}

.title {
	font-family: var(--headerfont);
	text-align: var(--headeralignment);
	color: var(--headerfontcolor);
	-webkit-text-stroke: calc(var(--headeroutlinesize) * var(--sizemultiplier)) var(--headeroutlinecolor);
	font-size: calc(calc(calc(var(--postheight) / 800) * var(--headersize)) * 3);
	letter-spacing: calc(var(--headerspacing) * var(--sizemultiplier));
	width: 100%;
	line-height: 1;
	font-weight: normal;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	text-transform: var(--headertransform);
	margin: 0;
}

.title-wrapper {
	display: flex;
	width: calc(100% - calc(var(--titlemarginleft) * var(--sizemultiplier)) - calc(var(--titlemarginright) * var(--sizemultiplier)));
	background-color: var(--titlebackgroundcolor);
	margin: calc((var(--titlemargintop) * var(--sizemultiplier)) - (0.00375 * var(--postheight))) calc(var(--titlemarginright) * var(--sizemultiplier)) calc(var(--titlemarginbottom) * var(--sizemultiplier)) calc(var(--titlemarginleft) * var(--sizemultiplier));
	padding: calc(var(--titlepaddingtop) * var(--sizemultiplier)) calc(var(--titlepaddingright) * var(--sizemultiplier)) calc(var(--titlepaddingbottom) * var(--sizemultiplier)) calc(var(--titlepaddingleft) * var(--sizemultiplier));
}

.subtitle {
	font-family: var(--subtitlefont);
	text-align: var(--subtitlealignment);
	color: var(--subtitlefontcolor);
	-webkit-text-stroke: calc(var(--subtitleoutlinesize) * var(--sizemultiplier)) var(--subtitleoutlinecolor);
	font-size: calc(calc(calc(var(--postheight) / 800) * var(--subtitlefontsize)) * 3);
	letter-spacing: calc(var(--subtitlespacing) * var(--sizemultiplier));
	width: 100%;
	line-height: 1;
	font-weight: normal;
	text-transform: var(--subtitletransform);
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	margin: 0;
}

.subttitle-wrapper {
	display: flex;
	width: calc(100% - calc(var(--subtitlemarginleft) * var(--sizemultiplier)) - calc(var(--subtitlemarginright) * var(--sizemultiplier)));
	background-color: var(--subtitlebackground);
	margin: calc(var(--subtitlemargintop) * var(--sizemultiplier)) calc(var(--subtitlemarginright) * var(--sizemultiplier)) calc(var(--subtitlemarginbottom) * var(--sizemultiplier)) calc(var(--subtitlemarginleft) * var(--sizemultiplier));
	padding: calc(var(--subtitlepaddingtop) * var(--sizemultiplier)) calc(var(--subtitlepaddingright) * var(--sizemultiplier)) calc(var(--subtitlepaddingbottom) * var(--sizemultiplier)) calc(var(--subtitlepaddingleft) * var(--sizemultiplier));
}

.thirdTitle {
	font-family: var(--thirdtitlefont);
	text-align: var(--thirdtitlealignment);
	color: var(--thirdtitlefontcolor);
	-webkit-text-stroke: calc(var(--thirdtitleoutlinesize) * var(--sizemultiplier)) var(--thirdtitleoutlinecolor);
	font-size: calc(calc(calc(var(--postheight) / 800) * var(--thirdtitlefontsize)) * 3);
	letter-spacing: calc(var(--thirdtitlespacing) * var(--sizemultiplier));
	width: 100%;
	line-height: 1;
	font-weight: normal;
	text-transform: var(--thirdtitletransform);
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	margin: 0;
}

.third-title-wrapper {
	display: flex;
	width: calc(100% - calc(var(--thirdtitlemarginleft) * var(--sizemultiplier)) - calc(var(--thirdtitlemarginright) * var(--sizemultiplier)));
	background-color: var(--thirdtitlebackground);
	margin: calc(var(--thirdtitlemargintop) * var(--sizemultiplier)) calc(var(--thirdtitlemarginright) * var(--sizemultiplier)) calc(var(--thirdtitlemarginbottom) * var(--sizemultiplier)) calc(var(--thirdtitlemarginleft) * var(--sizemultiplier));
	padding: calc(var(--thirdtitlepaddingtop) * var(--sizemultiplier)) calc(var(--thirdtitlepaddingright) * var(--sizemultiplier)) calc(var(--thirdtitlepaddingbottom) * var(--sizemultiplier)) calc(var(--thirdtitlepaddingleft) * var(--sizemultiplier));
}

.titlebox {
	border-bottom: calc(var(--headerbordersize) * var(--sizemultiplier)) solid var(--headerbordercolor);
	position: relative;
	background: var(--actualheadercolor);
	z-index: 1;
}

.twocolumns {
	grid-template-columns: repeat(2, 50%);
	grid-auto-flow: column;
}

.twocolumns .titlebox, .twocolumns .graphic-footer  {
	grid-column-start: 1;
	grid-column-end: 3;
}

.twocolumns .box {
	width: calc(var(--postwidth) / 2);
}

.threecolumns {
	grid-template-columns: repeat(3, 33.333%);
	grid-auto-flow: column;
}

.threecolumns .titlebox, .threecolumns .graphic-footer {
	grid-column-start: 1;
	grid-column-end: 4;
}

.threecolumns .box {
	width: calc(var(--postwidth) / 3);
}

.fourcolumns {
	grid-template-columns: repeat(4, 25%);
	grid-auto-flow: column;
}

.fourcolumns .titlebox, .fourcolumns .graphic-footer {
	grid-column-start: 1;
	grid-column-end: 5;
}

.fourcolumns .box {
	width: calc(var(--postwidth) / 4);
}

.settings {
	margin-bottom: 6px;
	text-align: center;
	display: inline-flex;
	margin-left: 30%;
}

.settings p {
	margin: 0;
	margin-left: 6px;
}

.settings.true::before {
	content: '\25bc';
	font-size: 12px;
}

.settings.false::before {
	content: '\25bc';
	transform: rotate(270deg);
	font-size: 12px;
}

.editTeams .newButton {
	width: 100%;
	margin: 0px;
	margin-bottom: 7px;
	box-sizing: border-box;
}

.watermark1,
.watermark2 {
	position: absolute;
	color: var(--watermarkcolor);
	text-align: center;
	font-family: var(--watermarkfont);
	font-size: calc(var(--watermarksize) * var(--sizemultiplier));
	font-weight: 700;
	line-height: 0.8;
	width: calc(var(--watermarkwidth) * var(--sizemultiplier));
	word-break: break-all;
	white-space: pre-wrap;
}

.watermark1 {
	top: calc(5px * var(--sizemultiplier));
	left: calc(5px * var(--sizemultiplier));
}

.watermark2 {
	top: calc(5px * var(--sizemultiplier));
	right: calc(5px * var(--sizemultiplier));
}

.settingsInput {
	height: 27px;
	width: 100% !important;
	padding: 3px 6px;
	margin-bottom: 4px !important;
	border: 1px solid #cccccc !important;
	border-radius: 4px !important;
}

.post .boxname .fiveless {
	flex-direction: column;
	line-height: var(--lineheight);
	align-self: flex-start;
	font-weight: bold;
	margin-top: 2px;
}

.post .fiveless span:nth-child(1) {
	display: block;
	font-size: 0.6em;
	margin-bottom: -0.15em;
	font-weight: normal;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.post .boxstat .fiveless {
	position: absolute;
	bottom: calc((var(--statmargin) - 8px) * var(--sizemultiplier));
	text-align: left;
	z-index: 2;
	left: calc(var(--teamimagewidth) + calc(var(--statmargin2) * var(--sizemultiplier)) + calc(var(--borderradius) / 5 * var(--sizemultiplier)) + calc(10px * var(--sizemultiplier)));
}

.post .hasRank .boxstat .fiveless {
	left: calc(var(--teamimagewidth) + calc(var(--statmargin2) * var(--sizemultiplier)) + calc(var(--borderradius) / 5 * var(--sizemultiplier)) + calc(10px * var(--sizemultiplier)) + calc(var(--rankwidth) * var(--sizemultiplier)));
}

.post .flexstart {
	align-self: flex-start;
	margin-right: calc(var(--postwidth) * -0.05);
}

.flexstart.boxname {
	margin-left: calc(calc(var(--namemargin2) * var(--sizemultiplier)) + calc(var(--borderradius) / 5));
}

.post .nomin {
	min-width: 0px !important;
	margin: 0px !important;
	position: initial;
}

.grid {
	grid-auto-flow: row;
}

.grid .titlebox {
	grid-column-start: 1;
	border: none;
}

.grid .box {
	flex-direction: column;
	border-width: calc(var(--bordersize) * var(--sizemultiplier));
	border-style: solid;
	border-color: var(--bordercolor);
	border-radius: calc(var(--borderradius) * var(--sizemultiplier));
	margin: var(--gridmargin);
	width: calc(100% - (var(--gridmargin) * 2 * var(--sizemultiplier)));
	overflow: hidden;
}

.grid .boxname {
	position: absolute;
	top: 0px;
	text-align: center;
	background: var(--namebackground);
	margin: 0px !important;
	border: calc(1px * var(--sizemultiplier)) solid var(--namebackground);
	padding: 0px calc(5px * var(--sizemultiplier)) 0px calc(5px * var(--sizemultiplier));
	padding-top: var(--namemargin);
	line-height: 1;
	width: 100%;
}

.grid .boximage {
	margin-left: 0px;
	margin-right: 0px;
	position: absolute;
	height: stretch;
	min-width: stretch;
	background-repeat: no-repeat;
}

.post .boxstat {
	display: flex;
	min-width: calc(var(--boxstatminwidth) + (calc(var(--poststatpadding) * var(--sizemultiplier))));
	justify-content: flex-end;
	align-items: center;
	z-index: 3;
}

.grid .boxstat {
	position: absolute;
	bottom: 0px;
	text-align: center;
	background: var(--statbackground);
	margin: 0px;
	border: calc(1px * var(--sizemultiplier)) solid var(--statbackground);
	padding: 0px calc(5px * var(--sizemultiplier)) 0px calc(5px * var(--sizemultiplier));
	padding-bottom: var(--statmargin);
	line-height: 1;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	z-index: 3;
	display: flex;
	justify-content: center;
	min-width: unset;
}

.grid .playerimage {
	height: 100%;
}

.onegrid .titlebox, .onegrid .graphic-footer {
	grid-column-end: 2;
}

.onegrid .boxname {
	font-size: calc(var(--namesize) * 2 * var(--sizemultiplier));
}

.onegrid .boxstat {
	font-size: calc(var(--statsize) * 2 * var(--sizemultiplier));
}

.onegrid .playerImageContainer {
	bottom: calc(var(--statsize) * 2 * var(--sizemultiplier));
}

.twogrid .titlebox, .twogrid .graphic-footer {
	grid-column-end: 2;
}

.twogrid .boxname {
	font-size: calc(var(--namesize) * 2 * var(--sizemultiplier));
}

.twogrid .boxstat {
	font-size: calc(var(--statsize) * 2 * var(--sizemultiplier));
}

.twogrid .playerImageContainer {
	bottom: calc(var(--statsize) * 2 * var(--sizemultiplier));
}

.fourgrid .titlebox, .fourgrid .graphic-footer  {
	grid-column-end: 3;
}

.fourgrid .boxname {
	font-size: calc(var(--namesize) * 2 * var(--sizemultiplier));
}

.fourgrid .boxstat {
	font-size: calc(var(--statsize) * 2 * var(--sizemultiplier));
}

.fourgrid .playerImageContainer {
	bottom: calc(var(--statsize) * 2 * var(--sizemultiplier));
}

.sixgrid .titlebox, .sixgrid .graphic-footer  {
	grid-column-end: 3;
}

.sixgrid .boxname {
	font-size: calc(var(--namesize) * 2 * var(--sizemultiplier));
}

.sixgrid .boxstat {
	font-size: calc(var(--statsize) * 2 * var(--sizemultiplier));
}

.sixgrid .playerImageContainer {
	bottom: calc(var(--statsize) * 2 * var(--sizemultiplier));
}

.ninegrid .titlebox, .ninegrid .graphic-footer  {
	grid-column-end: 4;
}

.ninegrid .boxname {
	font-size: calc(var(--namesize) * 1.5 * var(--sizemultiplier));
}

.ninegrid .boxstat {
	font-size: calc(var(--statsize) * 1.5 * var(--sizemultiplier));
}

.ninegrid .playerImageContainer {
	bottom: calc(var(--statsize) * 1.5 * var(--sizemultiplier));
}

.twelvegrid .titlebox, .twelvegrid .graphic-footer  {
	grid-column-end: 4;
}

.twelvegrid .boxname {
	font-size: calc(var(--namesize) * 1.5 * var(--sizemultiplier));
}

.twelvegrid .boxstat {
	font-size: calc(var(--statsize) * 1.5 * var(--sizemultiplier));
}

.twelvegrid .playerImageContainer {
	bottom: calc(var(--statsize) * 1.5 * var(--sizemultiplier));
}

.sixteengrid .titlebox, .sixteengrid .graphic-footer  {
	grid-column-end: 5;
}

.sixteengrid .boxname {
	font-size: calc(var(--namesize) * var(--sizemultiplier));
}

.sixteengrid .boxstat {
	font-size: calc(var(--statsize) * var(--sizemultiplier));
}

.sixteengrid .playerImageContainer {
	bottom: calc(var(--statsize) * var(--sizemultiplier));
}

.twentygrid .titlebox, .twentygrid .graphic-footer  {
	grid-column-end: 5;
}

.twentygrid .boxname {
	font-size: calc(var(--namesize) * var(--sizemultiplier));
}

.twentygrid .boxstat {
	font-size: calc(var(--statsize) * var(--sizemultiplier));
}

.twentygrid .playerImageContainer {
	bottom: calc(var(--statsize) * var(--sizemultiplier));
}

.twentyfivegrid .titlebox, .twentyfivegrid .graphic-footer  {
	grid-column-end: 6;
}

.twentyfivegrid .boxname {
	font-size: calc(var(--namesize) * 0.8 * var(--sizemultiplier));
}

.twentyfivegrid .boxstat {
	font-size: calc(var(--statsize) * 0.8 * var(--sizemultiplier));
}

.twentyfivegrid .playerImageContainer {
	bottom: calc(var(--statsize) * 0.8 * var(--sizemultiplier));
}

.thirtygrid .titlebox, .thirtygrid .graphic-footer  {
	grid-column-end: 6;
}

.thirtygrid .boxname {
	font-size: calc(var(--namesize) * 0.8 * var(--sizemultiplier));
}

.thirtygrid .boxstat {
	font-size: calc(var(--statsize) * 0.8 * var(--sizemultiplier));
}

.thirtygrid .playerImageContainer {
	bottom: calc(var(--statsize) * 0.8 * var(--sizemultiplier));
}

.thirtysixgrid .titlebox, .thirtysixgrid .graphic-footer  {
	grid-column-end: 7;
}

.thirtysixgrid .boxname {
	font-size: calc(var(--namesize) * 0.65 * var(--sizemultiplier));
}

.thirtysixgrid .boxstat {
	font-size: calc(var(--statsize) * 0.65 * var(--sizemultiplier));
}

.thirtysixgrid .playerImageContainer {
	bottom: calc(var(--statsize) * 0.65 * var(--sizemultiplier));
}

.fortytwogrid .titlebox, .fortytwogrid .graphic-footer  {
	grid-column-end: 7;
}

.fortytwogrid .boxname {
	font-size: calc(var(--namesize) * 0.65 * var(--sizemultiplier));
}

.fortytwogrid .boxstat {
	font-size: calc(var(--statsize) * 0.65 * var(--sizemultiplier));
}

.fortytwogrid .playerImageContainer {
	bottom: calc(var(--statsize) * 0.65 * var(--sizemultiplier));
}

.fortyninegrid .titlebox, .fortyninegrid .graphic-footer  {
	grid-column-end: 8;
}

.fortyninegrid .boxname {
	font-size: calc(var(--namesize) * 0.5 * var(--sizemultiplier));
}

.fortyninegrid .boxstat {
	font-size: calc(var(--statsize) * 0.5 * var(--sizemultiplier));
}

.fortyninegrid .playerImageContainer {
	bottom: calc(var(--statsize) * 0.5 * var(--sizemultiplier));
}

.fiftysixgrid .titlebox, .fiftysixgrid .graphic-footer  {
	grid-column-end: 8;
}

.fiftysixgrid .boxname {
	font-size: calc(var(--namesize) * 0.5 * var(--sizemultiplier));
}

.fiftysixgrid .boxstat {
	font-size: calc(var(--statsize) * 0.5 * var(--sizemultiplier));
}

.fiftysixgrid .playerImageContainer {
	bottom: calc(var(--statsize) * 0.5 * var(--sizemultiplier));
}

.sixtyfourgrid .titlebox, .sixtyfourgrid .graphic-footer  {
	grid-column-end: 9;
}

.sixtyfourgrid .boxname {
	font-size: calc(var(--namesize) * 0.5 * var(--sizemultiplier));
}

.sixtyfourgrid .boxstat {
	font-size: calc(var(--statsize) * 0.5 * var(--sizemultiplier));
}

.sixtyfourgrid .playerImageContainer {
	bottom: calc(var(--statsize) * 0.5 * var(--sizemultiplier));
}

.seventytwogrid .titlebox, .seventytwogrid .graphic-footer  {
	grid-column-end: 9;
}

.seventytwogrid .boxname {
	font-size: calc(var(--namesize) * 0.5 * var(--sizemultiplier));
}

.seventytwogrid .boxstat {
	font-size: calc(var(--statsize) * 0.5 * var(--sizemultiplier));
}

.seventytwogrid .playerImageContainer {
	bottom: calc(var(--statsize) * 0.5 * var(--sizemultiplier));
}

.eightyonegrid .titlebox, .eightyonegrid .graphic-footer  {
	grid-column-end: 10;
}

.eightyonegrid .boxname {
	font-size: calc(var(--namesize) * 0.5 * var(--sizemultiplier));
}

.eightyonegrid .boxstat {
	font-size: calc(var(--statsize) * 0.5 * var(--sizemultiplier));
}

.eightyonegrid .playerImageContainer {
	bottom: calc(var(--statsize) * 0.5 * var(--sizemultiplier));
}

.ninetygrid .titlebox, .ninetygrid .graphic-footer  {
	grid-column-end: 10;
}

.ninetygrid .boxname {
	font-size: calc(var(--namesize) * 0.5 * var(--sizemultiplier));
}

.ninetygrid .boxstat {
	font-size: calc(var(--statsize) * 0.5 * var(--sizemultiplier));
}

.ninetygrid .playerImageContainer {
	bottom: calc(var(--statsize) * 0.5 * var(--sizemultiplier));
}

.hundredgrid .titlebox, .hundredgrid .graphic-footer  {
	grid-column-end: 11;
}

.hundredgrid .boxname {
	font-size: calc(var(--namesize) * 0.5 * var(--sizemultiplier));
}

.hundredgrid .boxstat {
	font-size: calc(var(--statsize) * 0.5 * var(--sizemultiplier));
}

.hundredgrid .playerImageContainer {
	bottom: calc(var(--statsize) * 0.5 * var(--sizemultiplier));
}

.movelogo {
	position: absolute;
	left: calc(var(--logoposition) * var(--sizemultiplier));
}

.errorMessage {
	font-size: 12px;
	letter-spacing: 0.05rem;
	padding-left: 0.25rem;
}

.login-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	background: linear-gradient(45deg, var(--primarycolor), rgb(111, 0, 255));
	height: calc(100dvh - 49px);
	overflow: auto;
}

@media (max-height: 600px) {
	.login-wrapper {
		align-items: flex-start;
		padding: 10px 0px;
	}

	.login {
		margin: auto;
	}
}

body.dark .login-wrapper {
	background:linear-gradient(45deg, rgba(0, 0, 0, 1), rgba(111, 0, 255, 1));
}

.login {
	width: clamp(310px, 50vw, 600px);
	padding: 20px;
	border-radius: 28px;
	background: white;
}

body.dark .login {
	background: #000000;
}

.login .btn-primary {
	background: linear-gradient(90deg, var(--primarycolor) 25%, rgb(111, 0, 255));
    border: none !important;
    border-radius: 36px;
}

body.dark .login .btn-primary {
	background: linear-gradient(90deg, #006661, rgba(111, 0, 255, 1));
}

.login .btn-primary:hover {
	border: none !important;
}

.floating-label {
	position: relative;
	display: flex;
	flex-direction: column;
}

.floating-label:focus-within label {
	transform: translate(0, 12px) scale(0.8);
	color: #0a53e4;
	z-index: 5;
  }
  
  .floating-label .filled {
	transform: translate(0, 12px) scale(0.8);
  }

  body.dark .floating-label .filled {
	color: white !important;
  }

  body.dark .floating-label:focus-within label {
	color: white;
  }
  
  .floating-label label {
	position: absolute;
	pointer-events: none;
	transform: translate(0, 23px) scale(1);
	transform-origin: top left;
	transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
	color: #6f81a5;
	font-size: 16px;
	line-height: 1;
	left: 16px;
  }

  .floating-label input {
	height: 64px;
	border-radius: 6px;
	padding: 24px 16px 4px 16px;
	font-size: 16px;
	line-height: 1;
	color: black;
	outline: none;
	box-shadow: none;
	transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  }

  body.dark .floating-label input {
	color: white;
  }

  .input-group-append .input-group-text {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
  }

  .input-group.reverse input {
	border-top-left-radius: 6px !important;
	border-bottom-left-radius: 6px !important;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
  }

.login h3 {
	text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
}

a {
	color: var(--primarycolor) !important;
}

.btn-primary {
	background-color: var(--primarycolor) !important;
	font-weight: bold !important;
	border: 2px solid var(--primarycolor) !important;
}

.btn-primary:hover {
	border: 2px solid black !important;
}

.links {
	display: flex;
	flex-direction: row;
}

.sign-up {
	margin-top: 25px !important;
	margin-bottom: 7px !important;
	width: 100%;
	text-align: center;
}

.sso {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
}

.forgot {
    margin-bottom: 0px;
    font-size: 14px;
    width: 50%;
}

.have-code {
    margin-bottom: 0px;
    font-size: 14px;
    width: 100%;
}

.have-code span {
	color: var(--primarycolor);
	cursor: pointer;
	text-decoration: underline;
}

.close-code {
	position: absolute;
	top: 3px;
	right: 0;
	padding: 0px 5px 5px 5px;
	cursor: pointer;
    color: #c9c9c9;
}

.close-code:hover {
	color: #b1b1b1;
}

.PaymentPage {
	height: calc(100vh - 49px);
	width: 100%;
	background-color: white !important;
	overflow: auto;
	padding: 25px 20px 50px 20px;
}

.radiobtns label,
.radiopay label {
	width: auto;
	display: inline-flex;
	align-items: center;
	white-space: nowrap;
}

.radiobtns label input,
.radiopay label input {
	margin-right: 4px;
}

.radiopay {
	margin-bottom: 6px;
}

.margin10 {
	margin-top: 10px;
}

.abbreviations {
	display: flex;
	flex-direction: column;
}

.abbreviations li {
	list-style: none;
	margin-left: -30px;
}

.abbreviations h5 {
	text-align: center;
	margin-top: 6px;
}

.nflabbrs,
.nbaabbrs,
.mlbabbrs,
.nhlabbrs,
.ncaaabbrs,
.f1abbrs,
.soccerabbrs {
	width: 90%;
	margin: 2.5% 5%;
	box-shadow: 0px 0px 7px 0px #a7a7a7 !important;
	height: fit-content;
}

#abbrselect {
	width: 90%;
	margin: 0 5%;
	height: 28px;
	font-size: 13px;
	font-weight: 700;
	border-color: hsl(0, 0%, 80%);
	border-radius: 4px;
	padding: 0px 5px;
}

i.fa.fa-search {
	align-self: center;
	margin-left: 2px;
	margin-right: 5px;
}

.abbreviationPage h1,
.abbreviationPage h6,
.helpPage h1,
.helpPage h6 {
	text-align: center;
	margin-bottom: 0;
}

.abbreviationPage p {
	padding: 5px 20px 15px 20px;
	margin-bottom: 0px;
	text-align: center;
}

.helpPage {
	padding: 20px;
}

.helpPage h5,
.helpPage h6 {
	margin-top: 10px;
}

input.form-control.border-0.bg-light {
	border-radius: 30px;
}

.card {
	border-radius: 25px !important;
	overflow: hidden;
}

.card-container {
	padding: 0px clamp(0px, 10vw, 250px);
}

.card-header {
	background: linear-gradient(-9deg, var(--primarycolor), rgb(111, 0, 255));
	border: none !important;
}

.card-header h4 {
	text-align: center;
	color: white;
}

.abbrfilter.mb-4 {
	margin-top: 10px;
	margin-bottom: 10px !important;
}

.addmore {
	color: white;
	position: absolute;
	top: 15%;
	text-align: center;
	width: 100%;
	font-size: calc(20px * var(--sizemultiplier));
	z-index: 11;
}

div.addmore ~ .addmore {
	display: none;
}

.fa-instagram {
	margin-top: -1px;
	float: right;
	margin-right: 5px;
	color: #262626;
}

.fa-ellipsis-h {
	margin-left: auto;
	margin-right: 6px;
}

.fa-heart.fas {
	color: #ed4956;
}

.homeHero {
	height: calc(100dvh - 49px);
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 10%;
	max-height: 1000px;
}

.HomeBackground {
	background: linear-gradient(to bottom, #00a19930 0%, white 7%, white 93%, #00a19930 100%);
}

.Homeh2 {
	font-size: calc(1.375rem + 1.5vw);
	color: var(--primarycolor);
	font-weight: 700;
	text-align: center;
}

.homeHero h1 {
	margin-top: 25px;
	font-size: calc(2.375rem + 1.5vw);
	line-height: normal;
	text-align: left;
	background: -webkit-linear-gradient(125deg, var(--primarycolor), rgb(111, 0, 255));
    background-clip: text;
   	-webkit-text-fill-color: transparent;
	text-align: center;
	font-weight: 700;
}

.homeHero h2:not(.subtitle) {
	padding: 10px 5%;
	font-size: clamp(12px, 2vw, 24px);
	line-height: clamp(24px, 2vw, 48px);
	color: #999999;
	font-weight: normal;
}

.homeHero a {
	display: flex;
    justify-content: center;
	text-decoration: none;
}

.homeHero .newButton {
	width: fit-content;
	padding: 5px 15px;
	font-size: 16px;
	align-self: center;
	background: linear-gradient(90deg, var(--primarycolor), rgb(111, 0, 255));
    border: none !important;
    border-radius: 36px;
	text-transform: none;
    letter-spacing: 0.75px;
}

.heroImage {
	width: 33.3%;
	border: 1px solid white;
}

.heroImages {
	padding: 5px;
	background: white;
}

.fa-check-circle {
	color: var(--primarycolor);
	margin-right: 5px;
}

.fa-circle-xmark {
	color: red;
	margin-right: 5px;
}

.password-reqs ul {
	padding: 0;
}

.password-reqs li {
	list-style: none;
}

.stepsTitle {
	margin-bottom: 25px;
}

.stepsp {
	text-align: center;
}

.homepay {
	padding: 25px;
}

.card-body {
	min-height: 240px;
}

.homepay .newButton {
	font-size: 15px;
}

.homeline {
	margin: 25px auto;
	width: 85%;
}

.stepsimg {
	width: 90%;
	margin: 5%;
}

.modal {
	display: block !important;
}

.ReactModal__Overlay {
	z-index: 1001;
	background-color: #000000ba !important;
}

.ReactModal__Content {
	position: none !important;
	background: none !important;
	inset: none !important;
	border: none !important;
}

.affiliate-signup input.form-control {
	height: 27px;
}

.referral-modal .input-group-text:hover {
	background-color: darkgray !important;
}

.referral-modal input {
	color: hsl(0, 0%, 20%);
	font-size: 11px;
	font-weight: bold;
}

.fa-question-circle {
	margin-left: 5px;
	font-size: 16px;
}

.savedPosts {
	width: 100%;
	height: fit-content;
}

.savedPosts .newButtonSecondary {
	width: calc(100% - 24px);
	margin: 0px;
}

.savedPosts .newButtonSecondary.Full {
	width: 100%;
}

.savedPosts .newButton {
	width: 100%;
	margin: 0px;
}

.savedPosts h4 {
	margin: 0;
	border-bottom: 1px solid gray;
	margin-bottom: 10px;
	font-size: 18px;
	width: 100%;
	text-align: center;
}

.deleteButton {
	border-radius: 50%;
	font-size: 10px;
	background-color: transparent;
	border: 2px solid transparent;
	font-weight: bold;
	width: 24px;
}

.labels {
	width: 100%;
	text-align: center;
}

.Perfect .labels {
	display: flex;
	justify-content: space-around;
}

.fields {
	text-align: center;
	position: relative;
	display: flex;
	justify-content: center;
	width: 100%;
}

.fields textarea,
.number-input {
	width: 100%;
	resize: none;
	white-space: pre;
	overflow: auto;
	padding: 5px;
	border: 5px solid transparent;
	border-right: 22px solid transparent;
}

.number-input {
	border-right: 5px solid transparent;
}

.fields textarea::placeholder,
.number-input::placeholder {
	white-space: normal;
}

.toggle-container {
	display: flex;
	flex-direction: row;
	flex-flow: nowrap;
	justify-content: center;
	margin-bottom: 10px;
}

.toggleLabel {
	width: fit-content !important;
	margin: 0px 6px;
}

.pricing-card-subtitle {
	margin-top: 8px !important;
}

.colorbox {
	height: 27px;
	padding: 5px;
	background: #fff;
	border-radius: 4px;
	box-shadow: rgb(0 0 0 / 25%) 0px 1px 4px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	cursor: pointer;
	width: 100%;
	margin-bottom: 8px;
	font-size: 12px;
}

.colorboxinner {
	width: 22px;
	height: 22px;
	border: 1px solid gray;
	border-radius: 2px;
}

.colorbox span {
	margin-left: 5px;
}

.rightHeader {
	position: absolute;
	right: 0;
	display: flex;
	align-items: center;
	height: 100%;
}

.rightHeader > span {
	display: flex;
	align-items: center;
}

.leftHeader {
	position: absolute;
	left: 15px;
	display: flex;
	align-items: center;
	height: 100%;
}

.leftHeader > span {
	display: flex;
	align-items: center;
}

.close-modal {
	padding: 3px 10px;
	cursor: pointer;
	font-weight: 700;
}

.help .modal-content,
.profile .modal-content {
	max-height: 95vh;
}

.help .modal-dialog h6,
.help .modal-dialog a {
	font-weight: bold;
}

.help .modal-dialog {
	max-width: 80vw;
}

.quick-settings .modal-dialog {
	max-width: 700px;
}

.profile .modal-dialog {
	max-width: 60vw;
}

@media (max-width: 600px) {
	.help .modal-dialog,
	.quick-settings .modal-dialog,
	.profile .modal-dialog {
		max-width: 100vw;
	}
}

.quick-settings .modal-body {
	padding: 3rem;
}

@media (max-width: 415px) {
	.headerButton {
		margin-right: 8px;
	}

	.Header h1 {
		font-size: 24px;
	}

	.login-wrapper {
		height: calc(100dvh - 37px);
	}

	.homeHero {
		height: calc(100dvh - 37px);
	}
}

@media (max-width: 380px) {
	.headerButton {
		margin-right: 6px;
	}

	.Header h1 {
		font-size: 20px;
	}
}

.help .modal-body,
.profile .modal-body {
	max-height: 80vh;
	overflow: auto;
}

.secondary {
	background-color: white !important;
	color: var(--primarycolor) !important;
	border: 2px solid var(--primarycolor) !important;
}

.secondary:hover {
	outline: none;
	border: 2px solid black !important;
}

.profile .modal-body,
.profile .form-control,
.profile .btn,
.profile .input-group-text {
	font-size: 13px;
}

.form-check {
	margin-bottom: 15px;
}

.boximagesplit {
	display: flex;
	width: 100%;
	height: 100%;
}

.cliptopleft {
	clip-path: polygon(100% 0, 0 100%, 0 0);
}

.cliptopright {
	clip-path: polygon(0 0, 100% 0, 100% 100%);
}

.clipbottomright {
	clip-path: polygon(0 100%, 100% 0, 100% 100%);
}

.clipbottomleft {
	clip-path: polygon(0 100%, 0 0, 100% 100%);
}

.cliptop {
	clip-path: inset(0 0 50% 0);
}

.clipright {
	clip-path: inset(0 0 0 50%);
}

.clipbottom {
	clip-path: inset(50% 0 0 0);
}

.clipleft {
	clip-path: inset(0 50% 0 0);
}

.halfright {
	order: -1;
}

.grid .boximage.halfleft,
.tier .boximage.halfleft,
.bracket .boximage.halfleft {
	width: 50%;
	min-width: 50%;
}

.grid .boximage.halfright,
.tier .boximage.halfright,
.bracket .boximage.halfright {
	width: 50%;
	min-width: 50%;
	right: 0;
}

.tier .titlebox {
	overflow: hidden;
}

.tier .box {
	flex-direction: column;
	overflow: hidden;
	width: 100%;
	height: 100%;
	border: calc(var(--bordersize) * var(--sizemultiplier) / 2) solid var(--bordercolor) !important;
}

.tier .playerimage {
	position: absolute;
	top: 0;
	bottom: calc(var(--statsize) * var(--sizemultiplier) - 2px);
	align-self: center;
	height: stretch;
	object-fit: cover;
	z-index: 2;
}

.tier .boxname {
	position: absolute;
	font-size: calc(var(--namesize) * var(--sizemultiplier));
	text-align: center;
	background: var(--namebackground);
	margin: 0px !important;
	padding:
		calc(var(--namemargin) * var(--sizemultiplier))
		calc(5px * var(--sizemultiplier))
		calc(var(--namemargin) * var(--sizemultiplier))
		calc(5px * var(--sizemultiplier));
	max-width: 100%;
}

.tier .boximage {
	position: absolute;
	width: stretch;
	height: stretch;
	margin: 0;
	background-repeat: no-repeat;
}

.tier .boxstat {
	position: absolute;
	font-size: calc(var(--statsize) * var(--sizemultiplier));
	bottom: 0px;
	text-align: center;
	background: var(--statbackground);
	padding:
		calc(var(--statmargin) * var(--sizemultiplier))
		calc(5px * var(--sizemultiplier))
		calc(var(--statmargin) * var(--sizemultiplier))
		calc(5px * var(--sizemultiplier));
	line-height: 1;
	width: 100%;
	max-width: 100%;
	margin: 0px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	z-index: 3;
	min-width: unset;
}

.postType, .template-library {
	width: 100%;
	margin-bottom: 5px;
}

.center {
	width: 100%;
	text-align: center;
}

.settingsline {
	margin-top: 5px;
	margin-bottom: 5px;
	width: 85%;
}

.tierrow {
	display: flex;
	flex-direction: row;
	min-height: 0;
}

.news {
	display: flex;
	flex-direction: row;
	height: var(--postheightnews);
	width: var(--postwidthnews);
	min-width: var(--postwidthnews);
	overflow: hidden;
	background-color: black;
	position: relative;
}

.newsRight,
.postycontainer {
	z-index: 1;
}

.postycontainer {
	height: 100%;
}

.news.topbottom,
.news.bottomtop {
	flex-direction: column;
}

.newsLeft,
.newsRight {
	width: 100%;
	display: flex;
	color: white;
	height: 100%;
	position: relative;
}

.newsLeft {
	justify-content: center;
}

.newsRight {
	flex-direction: column;
	justify-content: space-between;
}

.leftright .newsRight {
	box-shadow: calc(-5px * var(--sizemultiplier)) 0px calc(var(--imageshadowsize) * var(--sizemultiplier)) calc(calc(var(--imageshadowsize) - 20px) * var(--sizemultiplier)) var(--imageshadowcolor);
}

.topbottom .newsRight {
	box-shadow: 0px calc(-5px * var(--sizemultiplier)) calc(var(--imageshadowsize) * var(--sizemultiplier)) calc(calc(var(--imageshadowsize) - 20px) * var(--sizemultiplier)) var(--imageshadowcolor);
}

.rightleft .newsRight {
	box-shadow: calc(5px * var(--sizemultiplier)) 0px calc(var(--imageshadowsize) * var(--sizemultiplier)) calc(calc(var(--imageshadowsize) - 20px) * var(--sizemultiplier)) var(--imageshadowcolor);
}

.bottomtop .newsRight {
	box-shadow: 0px calc(5px * var(--sizemultiplier)) calc(var(--imageshadowsize) * var(--sizemultiplier)) calc(calc(var(--imageshadowsize) - 20px) * var(--sizemultiplier)) var(--imageshadowcolor);
}

.leftright .newsImage,
.rightleft .newsImage {
	object-fit: cover;
	height: var(--imagezoomnews);
	margin-left: calc(var(--imagehorizontalnews) * var(--sizemultiplier));
	margin-top: calc(var(--imageverticalnews) * var(--sizemultiplier));
}

.topbottom .newsImage,
.bottomtop .newsImage {
	object-fit: cover;
	width: var(--imagezoomnews);
	height: fit-content;
	min-height: calc(100% + 13.5px);
	margin-left: calc(var(--imagehorizontalnews) * var(--sizemultiplier));
	margin-top: calc(var(--imageverticalnews) * var(--sizemultiplier));
}

.candrag {
	cursor: grab;
}

.bottomtop .newsImage {
	margin-top: calc((var(--imageverticalnews) * var(--sizemultiplier)) - 10px);
}

.newsTeam {
	align-self: center;
	opacity: var(--logoopacitynews);
	background-position: calc(50% + calc(var(--logohorizontalmarginnews) * var(--sizemultiplier))) calc(50% + calc(var(--logoverticalmarginnews) * var(--sizemultiplier)));
	background-size: cover;
	background-repeat: no-repeat;
}

.leftright .newsTeam {
	width: calc(var(--logosizenews) * var(--panesplitnewsright) / 50 * var(--sizemultiplier));
	height: calc(var(--logosizenews) * var(--panesplitnewsright) / 50 * var(--sizemultiplier));
	filter: drop-shadow(
		calc(-0.1 * var(--logosizenews) * var(--panesplitnewsright) / 50 * var(--sizemultiplier)) calc(0.08 * var(--logosizenews) * var(--panesplitnewsright) / 50 * var(--sizemultiplier))
			calc(0.06 * var(--logosizenews) * var(--panesplitnewsright) / 50 * var(--sizemultiplier)) #1f1f1f
	);
	margin-top: calc(-0.2 * var(--logosizenews) * var(--panesplitnewsright) / 50 * var(--sizemultiplier));
	margin-left: calc(-0.1 * var(--logosizenews) * var(--panesplitnewsright) / 50 * var(--sizemultiplier));
}

.leftright .highlightImage .newsTeam {
	width: calc(0.6 * var(--logosizenews) * var(--panesplithighlightright) / 50 * var(--sizemultiplier));
	height: calc(0.6 * var(--logosizenews) * var(--panesplithighlightright) / 50 * var(--sizemultiplier));
	filter: drop-shadow(
		calc(-0.05 * var(--logosizenews) * var(--panesplithighlightright) / 50 * var(--sizemultiplier)) calc(0.04 * var(--logosizenews) * var(--panesplithighlightright) / 50 * var(--sizemultiplier))
			calc(0.03 * var(--logosizenews) * var(--panesplithighlightright) / 50 * var(--sizemultiplier)) #1f1f1f
	);
	margin: 0;
}

.leftright .lineupImage .newsTeam {
	width: calc(0.6 * var(--logosizenews) * var(--panesplitlineupright) / 50 * var(--sizemultiplier));
	height: calc(0.6 * var(--logosizenews) * var(--panesplitlineupright) / 50 * var(--sizemultiplier));
	filter: drop-shadow(
		calc(-0.05 * var(--logosizenews) * var(--panesplitlineupright) / 50 * var(--sizemultiplier)) calc(0.04 * var(--logosizenews) * var(--panesplitlineupright) / 50 * var(--sizemultiplier))
			calc(0.03 * var(--logosizenews) * var(--panesplitlineupright) / 50 * var(--sizemultiplier)) #1f1f1f
	);
	margin: 0;
}

.rightleft .newsTeam {
	width: calc(var(--logosizenews) * var(--panesplitnewsleft) / 50 * var(--sizemultiplier));
	height: calc(var(--logosizenews) * var(--panesplitnewsleft) / 50 * var(--sizemultiplier));
	filter: drop-shadow(
		calc(-0.1 * var(--logosizenews) * var(--panesplitnewsleft) / 50 * var(--sizemultiplier)) calc(0.08 * var(--logosizenews) * var(--panesplitnewsleft) / 50 * var(--sizemultiplier))
			calc(0.06 * var(--logosizenews) * var(--panesplitnewsleft) / 50 * var(--sizemultiplier)) #1f1f1f
	);
	margin-top: calc(-0.2 * var(--logosizenews) * var(--panesplitnewsleft) / 50 * var(--sizemultiplier));
	margin-right: calc(-0.1 * var(--logosizenews) * var(--panesplitnewsleft) / 50 * var(--sizemultiplier));
}

.rightleft .highlightImage .newsTeam {
	width: calc(0.75 * var(--logosizenews) * var(--panesplithighlightleft) / 50 * var(--sizemultiplier));
	height: calc(0.75 * var(--logosizenews) * var(--panesplithighlightleft) / 50 * var(--sizemultiplier));
	filter: drop-shadow(
		calc(-0.05 * var(--logosizenews) * var(--panesplithighlightleft) / 50 * var(--sizemultiplier)) calc(0.04 * var(--logosizenews) * var(--panesplithighlightleft) / 50 * var(--sizemultiplier))
			calc(0.03 * var(--logosizenews) * var(--panesplithighlightleft) / 50 * var(--sizemultiplier)) #1f1f1f
	);
	margin: 0;
}

.rightleft .lineupImage .newsTeam {
	width: calc(0.75 * var(--logosizenews) * var(--panesplitlineupleft) / 50 * var(--sizemultiplier));
	height: calc(0.75 * var(--logosizenews) * var(--panesplitlineupleft) / 50 * var(--sizemultiplier));
	filter: drop-shadow(
		calc(-0.05 * var(--logosizenews) * var(--panesplitlineupleft) / 50 * var(--sizemultiplier)) calc(0.04 * var(--logosizenews) * var(--panesplitlineupleft) / 50 * var(--sizemultiplier))
			calc(0.03 * var(--logosizenews) * var(--panesplitlineupleft) / 50 * var(--sizemultiplier)) #1f1f1f
	);
	margin: 0;
}

/* fix topbottom too iwht right vars */
.highlightImage .newsTeam,
.lineupImage .newsTeam {
	background-size: contain;
    background-repeat: no-repeat;
	z-index: 1;
}

.topbottom .newsTeam {
	position: absolute;
	width: calc(0.5 * var(--logosizenews) * var(--panesplitnewsright) / 50 * var(--sizemultiplier));
	height: calc(0.5 * var(--logosizenews) * var(--panesplitnewsright) / 50 * var(--sizemultiplier));
	filter: drop-shadow(
		calc(-0.05 * var(--logosizenews) * var(--panesplitnewsright) / 50 * var(--sizemultiplier)) calc(0.04 * var(--logosizenews) * var(--panesplitnewsright) / 50 * var(--sizemultiplier))
			calc(0.03 * var(--logosizenews) * var(--panesplitnewsright) / 50 * var(--sizemultiplier)) #1f1f1f
	);
	top: calc(-0.2 * var(--logosizenews) * var(--panesplitnewsright) / 50 * var(--sizemultiplier));
}

.bottomtop .newsTeam {
	position: absolute;
	width: calc(0.5 * var(--logosizenews) * var(--panesplitnewsleft) / 50 * var(--sizemultiplier));
	height: calc(0.5 * var(--logosizenews) * var(--panesplitnewsleft) / 50 * var(--sizemultiplier));
	filter: drop-shadow(
		calc(-0.05 * var(--logosizenews) * var(--panesplitnewsleft) / 50 * var(--sizemultiplier)) calc(0.04 * var(--logosizenews) * var(--panesplitnewsleft) / 50 * var(--sizemultiplier))
			calc(0.03 * var(--logosizenews) * var(--panesplitnewsleft) / 50 * var(--sizemultiplier)) #1f1f1f
	);
	top: calc(-0.1 * var(--logosizenews) * var(--panesplitnewsleft) / 50 * var(--sizemultiplier));
}

.newsTitle {
	display: flex;
	background-color: var(--headercolornews);
	color: var(--headerfontcolornews);
	font-family: var(--headerfontnews);
	font-size: calc(var(--headersizenews) * 2.5 * var(--panesplitnewsright) / 50 * var(--sizemultiplier));
	font-weight: bold;
	padding: calc(14px * var(--sizemultiplier)) calc(14px * var(--sizemultiplier)) calc(14px * var(--sizemultiplier)) calc(34px * var(--sizemultiplier));
	margin-left: calc(-16px * var(--sizemultiplier));
	margin-right: calc(-16px * var(--sizemultiplier));
	box-shadow: calc(-10px * var(--sizemultiplier)) calc(10px * var(--sizemultiplier)) calc(20px * var(--sizemultiplier)) calc(6px * var(--sizemultiplier)) black;
	line-height: 1;
	letter-spacing: var(--headerspacingnews);
	white-space: pre-wrap;
	z-index: 1;
}

.newsTitle span {
	transform: translateY(var(--lineheightnews));
}

.rightleft .newsTitle,
.bottomtop .newsTitle {
	font-size: calc(var(--headersizenews) * 2.5 * var(--panesplitnewsleft) / 50 * var(--sizemultiplier));
}

.topbottom .newsTitle,
.bottomtop .newsTitle {
	padding: calc(14px * var(--sizemultiplier));
}

.newsText {
	text-align: var(--subtitlealignmentnews);
	color: var(--namecolornews);
	padding: calc(var(--subtitlemarginnews) * var(--sizemultiplier)) calc(calc(var(--subtitlemarginnews) - 5px) * var(--sizemultiplier)) calc(calc(var(--subtitlemarginnews) - 5px) * var(--sizemultiplier)) calc(calc(var(--subtitlemarginnews) + 5px) * var(--sizemultiplier));
	font-size: calc(var(--subtitlesizenews) * 1.5 * var(--panesplitnewsright) / 50 * var(--sizemultiplier));
	font-family: var(--subtitlefontnews);
	white-space: pre-wrap;
	font-weight: bold;
	line-height: var(--lineheighttextnews);
	letter-spacing: var(--subtitlespacingnews);
	z-index: 1;
}

.rightleft .newsText,
.bottomtop .newsText {
	font-size: calc(var(--subtitlesizenews) * 1.5 * var(--panesplitnewsleft) / 50 * var(--sizemultiplier));
}

.diagram {
	height: calc(150px * var(--sizemultiplier));
}

.topbottom .diagram,
.bottomtop .diagram {
	height: 0px;
}

.playdiagram {
	height: calc(150px * var(--sizemultiplier));
	opacity: 0.2;
	filter: invert(1);
	position: absolute;
	bottom: 0;
}

.rightleft .playdiagram {
	right: 0;
}

.topbottom .playdiagram,
.bottomtop .playdiagram {
	display: none;
}

.textTogether {
	z-index: 10;
	margin-bottom: calc(var(--headermarginnews) * var(--sizemultiplier));
}

.topbottom .textTogether,
.bottomtop .textTogether {
	text-align: center;
	position: absolute;
	width: 100%;
	bottom: calc(var(--headermarginnews) * var(--sizemultiplier));
}

.file-box form {
	width: 100%;
	margin: auto;
}
.file-box {
	width: 100%;
	display: flex;
	z-index: 0;
}
.leftright .file-input,
.rightleft .file-input {
	padding: calc(var(--postheight) * 0.16) calc(var(--postwidth) * 0.03) calc(var(--postheight) * 0.16) calc(var(--postwidth) * 0.03);
}
.topbottom .file-input,
.bottomtop .file-input {
	padding: calc(var(--postwidth) * 0.03) calc(var(--postheight) * 0.16) calc(var(--postwidth) * 0.03) calc(var(--postheight) * 0.16);
}
input[type='file']::file-selector-button {
	border-radius: 6px;
	font-size: calc(11px * var(--sizemultiplier));
	margin-top: calc(5px * var(--sizemultiplier));
	padding: calc(5px * var(--sizemultiplier));
	text-transform: uppercase;
	width: 47%;
	margin: calc(10px * var(--sizemultiplier));
	background-color: var(--primarycolor);
	color: white;
	font-weight: bold;
	border: 2px solid var(--primarycolor);
}
input[type='file']::-webkit-file-upload-button:hover {
	border: 2px solid #ffffff;
}
input[type='file']::file-selector-button:hover {
	background-color: #ffffff;
	color: var(--primarycolor);
}
input[type='file']:hover {
	cursor: pointer;
}
.upload-button {
	width: 100%;
	margin: 5px;
	font-size: calc(12px * var(--sizemultiplier));
}
.upload-button:hover {
	border: 2px solid #000000;
}

.bracket {
	grid-template-rows: 10% 1fr;
}

.bracket .titlebox {
	border-bottom: 0px !important;
}

.bracket .box {
	flex-direction: column;
	height: 90%;
	width: 90%;
	border-width: calc(var(--bordersize) * var(--sizemultiplier));
	border-style: solid;
	border-color: var(--bordercolor);
	border-radius: calc(10px * var(--sizemultiplier));
	z-index: 10;
}

.bracket .boxname {
	position: absolute;
	text-align: center;
	background: var(--namebackground);
	margin: 0px !important;
	padding: 0px calc(5px * var(--sizemultiplier)) 0px calc(5px * var(--sizemultiplier));
	padding-top: calc(var(--namemargin) * var(--sizemultiplier));
	font-size: calc(var(--namesize) * 0.5 * var(--sizemultiplier));
	width: 100%;
	line-height: normal;
}

.bracket .boximage {
	position: absolute;
	width: stretch;
	height: stretch;
	margin: 0;
}

.bracket .boxstat {
	position: absolute;
	bottom: 0px;
	text-align: center;
	background: var(--statbackground);
	padding: 0px calc(5px * var(--sizemultiplier)) 0px calc(5px * var(--sizemultiplier));
	padding-bottom: calc(var(--statmargin) * var(--sizemultiplier));
	line-height: 1;
	margin: 0px;
	font-size: calc(var(--statsize) * 0.5 * var(--sizemultiplier));
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	letter-spacing: calc(var(--statspacing) * 0.5);
	z-index: 3;
	line-height: normal;
}

.bracket8 .boxname {
	font-size: calc(var(--namesize) * var(--sizemultiplier));
}

.bracket32 .round32 .boxname {
	font-size: calc(var(--namesize) * 0.4 * var(--sizemultiplier));
}

.bracket8 .boxstat {
	font-size: calc(var(--statsize) * var(--sizemultiplier));
}

.bracket4 .boxname {
	font-size: calc(var(--namesize) * var(--sizemultiplier));
}

.bracket4 .boxstat {
	font-size: calc(var(--statsize) * var(--sizemultiplier));
}

.bracket .playerImageContainer {
	position: absolute;
	bottom: calc(((var(--statsize) * 0.5) - 2px) * var(--sizemultiplier));
	align-self: center;
	height: stretch;
	max-width: 100%;
	width: 100%;
	object-fit: cover;
	z-index: 2;
	overflow: hidden;
	display: flex;
	justify-content: center;
}

.bracket .playerimage {
	height: 100%;
}

.bracket .boximagecontainer {
	border-radius: calc(9px * var(--sizemultiplier));
	overflow: hidden;
}

.bracket64 .round64 .playerimage {
	bottom: 0 !important;
	max-height: 145%;
	height: 145%;
}

.bracket .roundspan {
	display: flex;
	justify-content: center;
	align-items: center;
}

.bracket64 {
	grid-template-columns: repeat(12, 1fr);
}

.bracket64 .titlebox {
	grid-column-start: 1;
	grid-column-end: 13;
}

.bracket32 .titlebox {
	grid-column-start: 1;
	grid-column-end: 11;
}

.bracket16 .titlebox {
	grid-column-start: 1;
	grid-column-end: 9;
}

.bracket12 .titlebox {
	grid-column-start: 1;
	grid-column-end: 9;
}

.bracket8 .titlebox {
	grid-column-start: 1;
	grid-column-end: 7;
}

.bracket4 .titlebox {
	grid-column-start: 1;
	grid-column-end: 5;
}


.bracket4 .box {
	max-height: calc(var(--postheight) * 0.375);
	margin: 0px calc(var(--postwidth) * 0.00625);
}

.bracket8 .box {
	max-height: calc(var(--postheight) * 0.2025);
	margin: 0px calc(var(--postwidth) * 0.00625);
}

.bracket16 .box {
	max-height: calc(var(--postheight) * 0.2);
	margin: 0px calc(var(--postwidth) * 0.00625);
}

.bracket32 .box {
	max-height: calc(var(--postheight) * 0.1);
	width: calc(var(--postwidth) * 0.1);
	margin: 0px calc(var(--postwidth) * 0.00625);
}

.round2.leftside .box {
	height: calc(var(--postheight) * 0.125);
	max-height: calc(var(--postheight) * 0.125);
}

.round2.rightside .box {
	height: calc(var(--postheight) * 0.125);
	max-height: calc(var(--postheight) * 0.125);
}

.bracket .middle {
	position: absolute;
	width: calc(var(--postwidth) * 0.25);
	height: calc(var(--postheight) * 0.25);
	left: 37.5%;
	top: 24%;
}

.bracket .middle .box {
	width: calc(var(--postwidth) * 0.1875);
	max-height: calc(var(--postheight) * 0.1875);
	height: calc(var(--postheight) * 0.1875);
}

.bracket64 .boxname {
	font-size: calc(var(--namesize) * 0.3 * var(--sizemultiplier));
}

.bracket64 .boxstat {
	font-size: calc(var(--statsize) * 0.3 * var(--sizemultiplier));
}

.bracket64 .playerImageContainer {
	bottom: calc(((var(--statsize) * 0.3) - 2px) * var(--sizemultiplier));
}

.bracket64 .round64 .boxstat {
	font-size: calc(var(--statsize) * 0.3 * var(--sizemultiplier));
}

.bracket64 .box {
	width: calc(var(--postwidth) * 0.0645);
	max-height: calc(var(--postheight) * 0.1);
	margin: 0px calc(5px * var(--sizemultiplier));
	border-radius: 5px;
}


.SUBTITLE {
	font-family: var(--headerfont);
	font-size: var(--namesize);
	letter-spacing: var(--headerspacing);
	margin: 0 !important;
	width: 100%;
	line-height: 1;
	height: inherit;
	position: absolute;
	bottom: 0;
	right: 0;
	display: flex;
	align-content: center;
	justify-content: center;
	text-align: center;
	align-items: center !important;
	padding-top: calc(4px * var(--sizemultiplier));
}

.SUBTITLE span {
	color: var(--headerfontcolor) !important;
}

.scores {
	grid-auto-flow: row;
	grid-template-columns: repeat(4, 25%);
	grid-auto-rows: min-content;
}

.scores .titlebox {
	grid-column-start: 1;
	grid-column-end: 5;
	border: none;
}

.scores .graphic-footer  {
	grid-column-start: 1;
	grid-column-end: 5;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
}

.scores .box {
	flex-direction: row;
	border: calc(var(--bordersize) * var(--sizemultiplier)) solid var(--bordercolor);
	width: auto;
	overflow: hidden;
}

.rankodd {
	position: absolute;
	color: var(--rankfontcolor);
	top: calc((var(--scoremargin) + (var(--rankmargin) / 2)) * var(--sizemultiplier));
	right: calc((var(--scoremargin) + var(--rankmargin2)) * var(--sizemultiplier));
	font-family: var(--rankfont);
	font-size: calc(var(--ranksize) * var(--sizemultiplier));
	line-height: 1;
	margin: 0;
	z-index: 100;
	font-weight: 600;
	letter-spacing: var(--rankspacing);
}

.rankeven {
	position: absolute;
	color: var(--rankfontcolor);
	top: calc((var(--scoremargin) + (var(--rankmargin) / 2)) * var(--sizemultiplier));
	left: calc((var(--scoremargin) + var(--rankmargin2)) * var(--sizemultiplier));
	font-family: var(--rankfont);
	font-size: calc(var(--ranksize) * var(--sizemultiplier));
	line-height: 1;
	margin: 0;
	z-index: 100;
	font-weight: 600;
	letter-spacing: var(--rankspacing);
}

.ranktop {
	position: absolute;
	color: var(--rankfontcolor);
	top: calc((var(--scoremargin) + (var(--rankmargin) / 2)) * var(--sizemultiplier));
	left: calc((var(--scoremargin) + var(--rankmargin2)) * var(--sizemultiplier));
	font-family: var(--rankfont);
	font-size: calc(var(--ranksize) * var(--sizemultiplier));
	line-height: 1;
	margin: 0;
	z-index: 100;
	font-weight: 600;
	letter-spacing: var(--rankspacing);
}

.rankbottom {
	position: absolute;
	color: var(--rankfontcolor);
	bottom: calc((var(--scoremargin) + (var(--rankmargin) / 2)) * var(--sizemultiplier));
	left: calc((var(--scoremargin) + var(--rankmargin2)) * var(--sizemultiplier));
	font-family: var(--rankfont);
	font-size: calc(var(--ranksize) * var(--sizemultiplier));
	line-height: 1;
	margin: 0;
	z-index: 100;
	font-weight: 600;
	letter-spacing: var(--rankspacing);
}

.score.box.even {
	margin: calc(var(--scoremargin) * var(--sizemultiplier)) 0px calc(var(--scoremargin) * var(--sizemultiplier)) calc(var(--scoremargin) * var(--sizemultiplier));
	border-radius: calc(var(--borderradius) * var(--sizemultiplier)) 0px 0px calc(var(--borderradius) * var(--sizemultiplier));
	flex-direction: row-reverse;
	border-right: calc(calc(var(--bordersize) / 2) * var(--sizemultiplier)) solid var(--bordercolor);
}

.score.box.odd {
	margin: calc(var(--scoremargin) * var(--sizemultiplier)) calc(var(--scoremargin) * var(--sizemultiplier)) calc(var(--scoremargin) * var(--sizemultiplier)) 0px;
	border-radius: 0px calc(var(--borderradius) * var(--sizemultiplier)) calc(var(--borderradius) * var(--sizemultiplier)) 0px;
	border-left: calc(calc(var(--bordersize) / 2) * var(--sizemultiplier)) solid var(--bordercolor);
}

.scoresame:nth-child(even) .box {
	margin: calc(var(--scoremargin) * var(--sizemultiplier)) 0px calc(var(--scoremargin) * var(--sizemultiplier)) calc(var(--scoremargin) * var(--sizemultiplier));
	border-radius: calc(var(--borderradius) * var(--sizemultiplier)) 0px 0px calc(var(--borderradius) * var(--sizemultiplier));
	border-right: calc(calc(var(--bordersize) / 2) * var(--sizemultiplier)) solid var(--bordercolor);
}

.scoresame:nth-child(odd) .box {
	margin: calc(var(--scoremargin) * var(--sizemultiplier)) calc(var(--scoremargin) * var(--sizemultiplier)) calc(var(--scoremargin) * var(--sizemultiplier)) 0px;
	border-radius: 0px calc(var(--borderradius) * var(--sizemultiplier)) calc(var(--borderradius) * var(--sizemultiplier)) 0px;
	border-left: calc(calc(var(--bordersize) / 2) * var(--sizemultiplier)) solid var(--bordercolor);
}

.scores .boxname {
	font-size: calc(var(--namesize) * 2 * var(--sizemultiplier));
	text-align: center;
	width: 50%;
	margin-left: 0 !important;
	order: calc(var(--logoorder) * -1);
	max-width: calc(var(--postwidth) * 0.125);
}
.scores .boximagecontainer {
	height: 100%;
	width: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	max-width: calc(var(--postwidth) * 0.125);
}
.scores .boximage {
	height: calc(var(--logosize) * 1.5 * var(--sizemultiplier));
	width: calc(var(--logosize) * 1.5 * var(--sizemultiplier));
	margin: 0;
	max-height: 100%;
}
.scores .boxstat {
	position: absolute;
	font-size: calc(var(--statsize) * var(--sizemultiplier));
	text-align: center;
	line-height: 1;
	width: 202%;
	bottom: calc((var(--statsize) * -1 * var(--sizemultiplier)) + calc(21px * var(--sizemultiplier)) - calc(var(--statmargin) * var(--sizemultiplier)));
	left: 0;
	white-space: pre-wrap;
	margin: 0;
	z-index: 2;
}

.below-login {
	display: flex;
	align-items: center;
	margin-bottom: 20px;
	font-size: 14px;
}

.or {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	font-size: 14px;
	width: 100%;
	margin: 15px 0px;
	position: relative;
}

.or span {
	background: white;
	padding: 0px 10px;
	z-index: 1;
}

body.dark .or span {
	background: black;
}

.or hr {
	position: absolute;
	width: 100%;
}

.rememberform {
	margin-bottom: 0px !important;
	width: 50%;
}

.input-group.reverse {
	flex-direction: row-reverse;
}

.full-width {
	width: 100%;
	top: 0;
	position: absolute;
}

.promo-form {
	flex-flow: nowrap !important;
	margin-bottom: 15px;
}

.promo-div {
	display: flex;
	flex-direction: row;
}

.promo-form button {
	width: 100px;
	align-self: center;
	font-size: 15px !important;
	padding: 0.1rem 0rem !important;
}

.promo-label {
	align-self: center;
	width: 100% !important;
	font-weight: normal !important;
	margin-right: 2px;
	font-size: 15px !important;
}

.promo-input {
	align-self: center;
	margin-right: 10px;
	font-size: 15px !important;
	height: 2em !important;
	padding: 0rem 0.5rem !important;
}

.appliedCodes {
	display: flex;
	flex-flow: wrap;
	justify-content: center;
	margin-bottom: 15px;
}

.appliedCodes p {
	margin: 0;
}

.appliedCodes p i {
	margin-right: 6px;
}

.appliedCodes span {
	margin-left: 6px;
	align-self: center;
}

.block {
	display: block;
	display: block;
	width: 100%;
	text-align: center;
	margin-bottom: 8px !important;
}

.free-trial {
	margin-top: 16px;
}

.modal .file-input {
	padding: 20%;
	width: 100%;
	color: var(--primarycolor);
}
.modal input[type='file']::-webkit-file-upload-button:hover {
	color: white;
	border: 2px solid #000000;
}
.modal input[type='file']::file-selector-button:hover {
	background-color: var(--primarycolor);
}
.modal .upload-button {
	width: 96%;
}
.modal .uploadInfo {
	color: black;
}

.userLogo1 {
	position: absolute;
	top: 0px;
	left: 0px;
	height: 100%;
	width: var(--watermarkwidth);
	object-fit: cover;
}

.userLogo2 {
	position: absolute;
	top: 0px;
	right: 0px;
	height: 100%;
	width: var(--watermarkwidth);
	object-fit: cover;
}

.gutter {
	z-index: 100;
	cursor: col-resize;
}

.topbottom .gutter,
.bottomtop .gutter {
	width: 100% !important;
}

.leftright .gutter,
.rightleft .gutter {
	height: 100% !important;
}

#loadingIcon {
	height: calc(70px * var(--sizemultiplier));
	left: 47%;
	position: absolute;
	top: 47%;
	width: calc(70px * var(--sizemultiplier));
	z-index: 99;
}

#loadingIcon2 {
	height: calc(70px * var(--sizemultiplier));
}

.wrap {
	white-space: pre-wrap;
}

.textfit {
	display: flex;
	max-height: inherit;
	align-items: center;
}

.BLANK .textfit {
	margin-right: 20px;
}

.textfit div {
	width: inherit;
	max-height: inherit;
	vertical-align: middle;
	margin-top: 0;
}

#auto1select {
	height: 28px;
	width: 100%;
	font-size: 13px;
	font-weight: 700;
	margin-bottom: 10px;
	border-color: hsl(0, 0%, 80%);
	border-radius: 4px;
	padding: 0px 5px;
}

.rendContainer .newButton {
	width: 100%;
	margin: 0px 0px 2px 0px;
}

.savedPosts .newButton,
.savedPosts .newButtonSecondary {
	padding: 3px;
	font-size: 10px;
	margin-bottom: 2px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.edit .newButton,
.edit .newButtonSecondary {
	padding: 3px;
	font-size: 10px;
	margin-bottom: 4px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.savedpostdiv {
	display: flex;
	align-items: center;
	margin-bottom: 4px;
}

.savedpostdiv .deleteButton {
	align-items: center;
    border-radius: 6px;
    display: flex;
    height: 25px;
    justify-content: center;
}

.rich-editor .floatleft > .newButton {
	width: auto;
}

.settings-label {
	margin: 0;
	font-size: 12px !important;
	font-weight: normal !important;
	text-align: left !important;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	flex: 1;
}

.position-label {
	margin: 0px 3px 0px 6px;
	width: fit-content !important;
	font-size: 12px !important;
	font-weight: normal !important;
	text-align: left !important;
}

.autosettings {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.autosettingsspan,
.autosettings div.autoflex {
	display: flex;
	align-items: center;
	width: 100%;
	margin: 3px 0px;
}

.autosettingsspan input[type='checkbox'] {
	margin-left: 0px;
	margin-right: 6px;
	min-width: 20px;
}

.autosettings textarea {
	width: 50% !important;
	height: 16px;
	font-size: 12px;
}

#paginate {
	display: flex;
	align-items: center;
	margin-bottom: 3px;
}

.autosettings select,
#paginate select {
	width: 50%;
	height: 16px;
	font-size: 12px;
	background: #f3f3f3;
	border: none;
	border-radius: 6px;
	font-weight: bold;
	line-height: 1;
	margin: 2px;
}

.relative {
	position: relative;
}

.formRelative > span {
	display: contents;
	width: 100%;
}

.relativeButtonTop {
	position: absolute;
	right: 2px;
	top: 2px;
	padding: 3px;
	cursor: pointer;
	color: var(--primarycolor);
	background: #f3f3f3;
	border-top-right-radius: 6px;
}

.relativeButton {
	position: absolute;
	right: 2px;
	bottom: 2px;
	padding: 3px;
	cursor: pointer;
	color: var(--primarycolor);
	background: #f3f3f3;
	border-bottom-right-radius: 6px;
}

.relativeButton2 {
	position: absolute;
	right: 2px;
	bottom: 30px;
	padding: 3px;
	cursor: pointer;
	color: var(--primarycolor);
	background: #f3f3f3;
}

.relativeButton3 {
	position: absolute;
	right: 2px;
	bottom: 62px;
	padding: 3px;
	cursor: pointer;
	color: var(--primarycolor);
	background: #f3f3f3;
}

.jerseyBox .team {
	display: flex;
	position: relative;
	justify-content: center;
	height: 100%;
}

.jerseyBox .Jerseynum {
	color: white;
	font-family: 'jersey', sans-serif;
	font-size: calc(80px * var(--sizemultiplier));
	position: absolute;
	bottom: calc(32px * var(--sizemultiplier));
	line-height: 1;
	margin-bottom: 0;
	margin-left: 2%;
}
.jerseyBox .Jerseylogo {
	filter: drop-shadow(0px 0px calc(2px * var(--sizemultiplier)) #000);
	height: calc(32px * var(--sizemultiplier));
    position: absolute;
    right: calc(51px * var(--sizemultiplier));
    top: calc(89px * var(--sizemultiplier));
}
.jerseyBox .wordmark {
	height: calc(10px * var(--sizemultiplier));
	position: absolute;
	top: calc(115px * var(--sizemultiplier));
	padding-left: calc(4px * var(--sizemultiplier));
}
.jerseyBox .WSH .wordmark {
	height: calc(13px * var(--sizemultiplier));
}
.jerseyBox .Pos {
	color: white;
	font-size: calc(36px * var(--sizemultiplier));
	left: calc(19px * var(--sizemultiplier));
	letter-spacing: calc(-3px * var(--sizemultiplier));
	position: absolute;
	top: calc(24x * var(--sizemultiplier));
}
.jerseyBox .Player {
	background: black;
	bottom: calc(-2px * var(--sizemultiplier));
	margin-bottom: 0;
	color: white;
	font-size: calc(19px * var(--sizemultiplier));
	font-weight: 700;
	letter-spacing: calc(-0.5px * var(--sizemultiplier));
	padding: calc(4px * var(--sizemultiplier));;
	position: absolute;
	text-align: center;
	text-transform: uppercase;
	width: 100%;
}

.PIT .jersey,
.PIT .helmet,
.PIT2 .helmet,
.PIT3 .jersey,
.ARI3 .jersey,
.ATL .jersey,
.ATL3 .jersey,
.ATL .helmet,
.ATL2 .helmet,
.ATL3 .helmet,
.BAL .helmet,
.BAL2 .helmet,
.BAL3 .helmet,
.BAL3 .jersey,
.CAR .jersey,
.CIN .jersey,
.JAX3 .jersey,
.JAX .helmet,
.JAX2 .helmet,
.JAX3 .helmet,
.NO .jersey,
.NYJ3 .jersey,
.LV .jersey,
.WSH3 .jersey,
.WSH3 .helmet,
.PHI3 .jersey {
	filter: drop-shadow(0px 0px 4px white);
}

.CHI .jersey,
.CHI .helmet,
.CHI2 .helmet,
.CHI3 .helmet,
.CLE .jersey,
.CLE3 .jersey,
.DAL .jersey,
.DEN .helmet,
.DEN2 .helmet,
.DEN3 .helmet,
.DEN3 .jersey,
.GB .jersey,
.HOU .helmet,
.HOU2 .helmet,
.HOU3 .helmet,
.HOU .jersey,
.LAC3 .jersey,
.LAR3 .helmet,
.LAR3 .jersey,
.NE .jersey,
.NYG .helmet,
.NYG2 .helmet,
.NYG3 .helmet,
.NYG .jersey,
.SEA .helmet,
.SEA2 .helmet,
.SEA3 .helmet,
.SEA .jersey,
.TEN .helmet,
.TEN2 .helmet,
.TEN3 .helmet {
	filter: drop-shadow(0px 0px 2px white);
}

.NYG2 .Jerseylogo {
	filter: none;
}

.Perfect button {
	margin: 4px;
}

.blend-in {
	background: transparent;
	color: var(--tiercolor);
	font-family: var(--tierfont);
	font-size: calc(var(--tiersize) * var(--sizemultiplier));
	border: none;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	appearance: none;
	padding: 0;
}

.row10,
.row11,
.row12,
.row13,
.row14,
.row15,
.row16,
.row17,
.row18,
.row19,
.row20,
.row21,
.row22,
.row23,
.row24,
.row25,
.row26,
.row27,
.row28,
.row29,
.row30 {
	display: none;
}

.extrainfoouter {
	display: flex;
	justify-content: center;
	position: absolute;
	right: 0;
	bottom: 0;
	z-index: 101;
}

.extrainfo {
	background: var(--pointsbackground);
	border: none;
	border-radius: calc(5px * var(--sizemultiplier));
	color: var(--pointsfontcolor);
	font-family: var(--pointsfont);
	letter-spacing: var(--pointsspacing);
	line-height: 0.8;
	-webkit-text-stroke: calc(var(--pointsoutlinesize) * var(--sizemultiplier)) var(--pointsoutlinecolor);
	margin: calc(var(--pointsmargin) * var(--sizemultiplier));
	min-width: calc(var(--postheight) * 0.08);
	padding: calc(5px * var(--sizemultiplier));
	text-align: center;
	text-transform: var(--pointstransform);
	width: fit-content;
}

.jerseySpan {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.textglow {
	text-shadow: calc(var(--rankoutlinesize) * -1) calc(var(--rankoutlinesize) * -1) var(--rankoutlinesize) var(--rankoutlinecolor), var(--rankoutlinesize) calc(var(--rankoutlinesize) * -1) var(--rankoutlinesize) var(--rankoutlinecolor),
		calc(var(--rankoutlinesize) * -1) var(--rankoutlinesize) var(--rankoutlinesize) var(--rankoutlinecolor), var(--rankoutlinesize) var(--rankoutlinesize) var(--rankoutlinesize) var(--rankoutlinecolor);
}

.textglow2 {
	text-shadow:
		calc(var(--pointsoutlinesize) * -1) calc(var(--pointsoutlinesize) * -1) var(--pointsoutlinesize) var(--pointsoutlinecolor),
		var(--pointsoutlinesize) calc(var(--pointsoutlinesize) * -1) var(--pointsoutlinesize) var(--pointsoutlinecolor),
		calc(var(--pointsoutlinesize) * -1) var(--pointsoutlinesize) var(--pointsoutlinesize) var(--pointsoutlinecolor),
		var(--pointsoutlinesize) var(--pointsoutlinesize) var(--pointsoutlinesize) var(--pointsoutlinecolor);
}

.standingsborder {
	width: 100%;
	top: 71.5%;
	background: white;
	height: calc(5px * var(--sizemultiplier));
	position: absolute;
	z-index: 999;
}

.savedPosts .rc-slider {
	margin-top: 5px;
	margin-bottom: 5px;
}

#postwithimage:not(.grid) {
	display: grid;
	min-width: unset;
	background-image: var(--postbackground);
	background-size: cover;
	grid-template-columns: 100%;
	height: 100%;
}
#postwithimage.grid {
	min-width: unset;
	width: 100%;
	height: 100%;
}
#postwithimage:not(.grid) .box {
	width: 100%;
}
.twocolumns#postwithimage {
	display: grid;
	height: 100%;
	grid-template-columns: repeat(2, 50%);
}
.threecolumns#postwithimage {
	display: grid;
	height: 100%;
	grid-template-columns: repeat(3, 33.333%);
}
.fourcolumns#postwithimage {
	display: grid;
	height: 100%;
	grid-template-columns: repeat(4, 25%);
}
.notnews {
	display: flex;
	flex-direction: row;
	height: var(--postheight);
	width: var(--postwidth);
	min-width: var(--postwidth);
	overflow: visible;
	background-color: black;
	position: relative;
}

.notnews.topbottom,
.notnews.bottomtop {
	flex-direction: column;
}

.notnews .newsLeft {
	position: unset;
}

.notnews .newsRight {
	background: var(--headercolor);
}

.alttext {
	color: white;
	font-size: calc(var(--statsize) * var(--sizemultiplier));
	display: flex;
	align-content: center;
	height: 100%;
	align-items: center;
	flex-wrap: nowrap;
	justify-content: center;
}

.calendarButton {
	border-radius: 50%;
	font-size: 12px;
	background-color: transparent;
	border: 2px solid transparent;
	font-weight: bold;
	width: 24px;
}

.react-datepicker__input-container input {
	background: #f3f3f3;
	border: none;
	border-radius: 6px;
	font-size: 12px;
	font-weight: bold;
	line-height: 1;
	margin: 2px;
	text-align: center;
	width: 100%;
}

.video-modal {
	overflow: scroll !important;
}

.video-modal .modal-dialog {
	width: calc(var(--postwidth) / 2 + 100px);
	max-width: calc(var(--postwidth) / 2 + 100px);
	height: calc(var(--postheight) / 2 + 100px);
	max-height: calc(var(--postheight) / 2 + 100px);
}

.video-modal .modal-body {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

@keyframes blink {
	0%,
	100% {
		opacity: 0;
	} /* more concise! */
	50% {
		opacity: 1;
	}
}

#recording-indicator {
	height: 10px;
	width: 10px;
	background-color: red;
	margin-left: 5px;
	border-radius: 50%;
	display: inline-block;
	animation: blink 2s infinite;
}

.video-status {
	margin-bottom: 15px;
	display: flex;
	align-items: center;
}

#video-status-text {
	display: flex;
	align-items: center;
}

#video-status-text a {
	margin-left: 5px;
}

.animated-ellipsis:after {
	overflow: hidden;
	display: inline-block;
	vertical-align: bottom;
	-webkit-animation: ellipsis steps(4, end) 2000ms infinite;
	animation: ellipsis steps(4, end) 2000ms infinite;
	content: '\2026'; /* ascii code for the ellipsis character */
	width: 0px;
}

@keyframes ellipsis {
	to {
		width: 1.25em;
	}
}

@-webkit-keyframes ellipsis {
	to {
		width: 1.25em;
	}
}

.savedPostsLists {
	width: 100%;
	display: flex;
	flex-direction: column;
	position: relative;
}

.savedPostPages {
	text-align: center;
	margin-right: 5px;
	color: var(--primarycolor);
	width: 100%;
	overflow-x: auto;
	padding-bottom: 8px;
}

.savedPostPages > span {
	padding: 3px;
	margin: 0px 3px;
}

.page-selected {
	color: #00534e;
	text-decoration: underline;
}

.page-selected,
.page-not-selected {
	cursor: pointer;
}

.page-not-selected:hover {
	color: black;
}

.pages {
	text-align: center;
	margin-right: 5px;
	color: var(--primarycolor);
	width: 100%;
	overflow-x: auto;
	margin-bottom: 5px;
	font-size: 16px;
}

.pages > span {
	padding: 3px;
	margin: 0px 3px;
}

.postFilterResults,
.numResults {
	text-align: center;
	font-size: 12px;
	margin-bottom: 5px;
	color: #262626;
}

.numResults {
	margin-top: -4px;
}

.savedPosts > .newButtonSecondary.fullWidth {
	width: 100%;
	margin-bottom: 8px !important;
}

.newButton.allPostsBtn {
	width: 100%;
	margin: 0;
    margin-bottom: 10px;
}

.rich-editor {
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-wrap: nowrap;
}

.rich-editor.in-sidebar {
	flex-direction: column-reverse;
	align-items: flex-start;
}

.rich-editor.in-sidebar .floatleft {
	flex-direction: column;
}

.rich-editor.in-sidebar .floatright {
	margin-left: unset;
	border-left: none;
	padding-left: unset;
	border-bottom: 1px solid #353536;
	padding-bottom: 10px;
}

.floatleft {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
	padding-right: 10px;
}

.floatright {
	margin: 15px 0px 15px auto;
	display: flex;
	flex-wrap: wrap;
    justify-content: flex-end;
	border-left: 1px solid #353536;
	padding-left: 10px;
	gap: 10px;
}

.editor-dropdown > div {
	width: 150px;
	margin-bottom: 0px;
}

.popover-content .editor-dropdown > div {
	width: 100%;
	margin-bottom: 10px;
}

.editor-input > input {
	text-align: center;
	width: 30px;
	background-color: hsl(0, 0%, 100%);
	border-color: hsl(0, 0%, 80%);
	border-style: solid;
	border-width: 1px;
	font-size: 14px;
	outline: 0 !important;
	height: 27px;
	appearance: none;
	line-height: 1;
	border-radius: 0;
	padding: 0;
}

.editor-input > input[type='number'] {
	width: 75%;
	border-radius: 5px;
}

.editor-input.center {
	display: flex;
	justify-content: center;
	margin-bottom: 6px;
	line-height: 1;
}

.subtract-one,
.add-one {
	padding: 4px 8px;
	text-align: center;
	width: 30px;
	background-color: hsl(0, 0%, 100%);
	border-color: hsl(0, 0%, 80%);
	border-style: solid;
	border-width: 1px;
	font-size: 14px;
	outline: 0 !important;
	height: 27px;
	user-select: none;
	display: flex;
	align-items: center;
	justify-content: center;
}

.add-one.disabled,
.subtract-one.disabled,
.editor-button.disabled {
	background-color: #f2f2f2 !important;
}

input:disabled {
	background-color: #f2f2f2 !important;
}

body.dark .add-one.disabled,
body.dark .subtract-one.disabled,
body.dark .editor-button.disabled {
	background-color: gray !important;
}

body.dark input:disabled {
	background-color: gray !important;
}

.subtract-one {
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
	margin-right: -1px;
}

.add-one {
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
	margin-left: -1px;
}

.editor-button {
	position: relative;
	border: 1px solid hsl(0, 0%, 80%);
	border-radius: 4px;
	height: 27px;
	width: 27px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

button.editor-button {
	background: white;
}

button.editor-button:hover {
	border: none !important;
}

.editor-button:hover {
	background-color: hsl(0, 0%, 80%);
}

.colorbox-editor {
	display: flex;
	flex-direction: column;
	width: 20px;
	align-items: center;
}

.colorbox-letter {
	line-height: 1;
	font-weight: bold;
	pointer-events: none;
}

.rainbow-bar {
	display: block;
	pointer-events: none;
	border-radius: 10px;
	width: 20px;
	height: 5px;
	background: -webkit-linear-gradient(left, red, orange, yellow, green, blue);
	background: -moz-linear-gradient(left, red, orange, yellow, green, blue);
	background: -o-linear-gradient(left, red, orange, yellow, green, blue);
	background: -ms-linear-gradient(left, red, orange, yellow, green, blue);
	background: linear-gradient(to right, red, orange, yellow, green, blue);
	background-repeat: repeat-x;
}

.fa-arrows-alt {
	font-size: 1.2em;
	margin-top: 2px;
}

.popover-content {
	z-index: 1000;
	background: white;
	width: 250px;
	margin-top: 10px;
	border-radius: 10px;
	box-shadow: 0px 0px 6px 0px black;
	padding: 40px;
}

.popover2 {
	width: 300px;
}

.popover2 * h5 {
	margin-top: 6px;
}

.popover-content.color-picker {
	width: 305px;
}

.popover-content.color-picker3 {
	width: 375px;
}


.popover-content > h5,
.popover-content * h5 {
	font-size: 13px;
	text-align: center;
	font-weight: bold;
}

.editor-button-text {
	font-family: 'roboto condensed';
	padding: 5px 8px;
	text-align: center;
	width: fit-content;
	background-color: hsl(0, 0%, 100%);
	border: 1px solid hsl(0, 0%, 80%);
	box-shadow: 1px 1px 5px hsl(0, 0%, 80%);
	font-size: 13px;
	border-radius: 4px;
	color: #2f2f2f;
}

.editor-image {
	width: 23px;
}

.editor-button:hover:not(.disabled),
.add-one:hover:not(.disabled),
.subtract-one:hover:not(.disabled) {
	background-color: hsl(0, 0%, 80%);
}

.editor-shadow {
	text-shadow: 1px 1px 5px #606060;
}

.rainbow-box {
	border: double 7px transparent;
	background-image: linear-gradient(LightSteelBlue, LightSteelBlue), conic-gradient(#ff0000, #ffff00, #00ff00, #00ffff, #0000ff, #ff00ff, #ff0000);
	background-origin: border-box;
	background-clip: content-box, border-box;
	width: 14px;
	height: 14px;
}

.gradient-box {
	width: 14px;
	height: 14px;
	background: linear-gradient(to right, red, blue);
}

.no-click {
	pointer-events: none;
}

.fit-image {
	height: inherit;
	margin-right: 6px;
}

.editor-button-text.editor-button-logo {
	display: flex;
	flex-direction: row;
}

.center-span {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.popover-content .newButton {
	width: 100%;
	margin: -5px 0px 0px 0px;
}

.box-editor {
	width: 14px;
	height: 14px;
	border: 1px solid gray;
}

.border-box {
	width: 14px;
	height: 14px;
	border: 1px dashed gray;
}

.chevron {
	border-radius: 0px !important;
	border-top-right-radius: 6px !important;
	border-bottom-right-radius: 6px !important;
	font-size: 11px;
	height: 27px;
	background-color: var(--primarycolor);
	color: white;
	font-weight: bold;
	border: 2px solid var(--primarycolor);
}

.downloadVideoBtn > .newButton,
.relativeBtn > .newButton {
	margin: 0px;
	border-top-right-radius: 0px;
	border-bottom-right-radius: 0px;
	width: 100%;
	height: 27px;
}

.downloadImageBtn,
.downloadVideoBtn {
	margin: 0px 5px;
	width: auto;
}

.rich-editor .newButton {
	height: 27px;
	padding: 0px 6px;
}

.chevron:hover,
.chevron:focus {
	border-top-left-radius: 0px !important;
	border-bottom-left-radius: 0px !important;
}

.downloadVideoBtn > .newButton:hover,
.relativeBtn > .newButton:hover {
	border-top-right-radius: 0px !important;
	border-bottom-right-radius: 0px !important;
}

.leftToggle {
	width: 50% !important;
	border-top-right-radius: 0px;
	border-bottom-right-radius: 0px;
}

.leftToggle:hover,
.leftToggle:focus {
	border-top-right-radius: 0px !important;
	border-bottom-right-radius: 0px !important;
}

.rightToggle {
	width: 50% !important;
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
	border-left: none !important;
}

.rightToggle:hover,
.rightToggle:focus {
	border-top-left-radius: 0px !important;
	border-bottom-left-radius: 0px !important;
}

.leftToggle,
.rightToggle {
	background-color: white;
	color: var(--primarycolor);
	border: 2px solid var(--primarycolor);
}

.active-toggle {
	background-color: #c8fffc;
	color: var(--primarycolor);
	border: 2px solid var(--primarycolor);
}

.gradient-box::after,
.border-box::after,
.watermark-editor::after,
.rainbow-box::after,
.colorbox-editor::after,
.text-outline::after {
	content: '';
	position: absolute;
	top: 0px;
	left: 0px;
	width: 25px;
	height: 25px;
}

.editor-button > .text-outline {
	-webkit-text-stroke: 1px lightskyblue;
	font-family: 'robotobold';
	color: white;
	font-size: 22px;
}

.modal-fit {
	overflow: auto !important;
}

.modal-fit .modal-dialog {
	max-width: none;
	width: fit-content;
}

.popover3 {
	width: 300px;
	right: -12px;
	position: absolute;
}

.boxborderright {
	border-right: calc(var(--bordersize) * var(--sizemultiplier)) solid var(--bordercolor);
}

.playerimagespacer {
	order: 3;
	min-width: var(--playerimagewidth);
}

.formGroup {
	display: flex;
	flex-direction: column;
	align-items: center;
	min-width: 0;
	flex: 1;
	transition: all 1s ease;
}

.formGroup.expanded {
	flex: 2;
}

.formGroup > span {
	display: flex;
	width: 95%;
}

.uploadInfo {
	font-size: calc(14px * var(--sizemultiplier));
	color: white;
	padding: 0px 20px;
}

.file-input {
	font-size: calc(14px * var(--sizemultiplier));
	outline: 2px dashed var(--primarycolor);
	outline-offset: -10px;
	color: white;
	position: relative;
}

.topbottom .file-input::before,
.bottomtop .file-input::before {
	content: unset;
}

.file-input::before {
	position: absolute;
	width: 13%;
	height: 13%;
	top: 20%;
	left: 44%;
	display: inline-block;
	content: '';
	mask: url(./assets/cloud-arrow-up-solid.svg) no-repeat 50% 50%;
	mask-size: contain;
	background-color: white;
}

h5.withLine {
	width: 100%;
	text-align: center;
	border-bottom: 1px solid #000;
	line-height: 0.1em;
	margin: 10px 0 20px;
}

h5.withLine span {
	background: #fff;
	padding: 0 10px;
}

#image-modal-body {
	overflow: scroll;
}

@media (min-width: 800px) {
	#image-modal-body > canvas {
		width: auto !important;
		height: 75vh !important;
	}
}

@media (max-width: 800px) {
	#image-modal-body > canvas {
		width: 88vw !important;
		height: auto !important;
	}

	.topbottom .newsImage,
	.bottomtop .newsImage {
		height: var(--imagezoomnews);
		width: unset;
	}

	.radiobtns label {
		font-size: 14px;
	}

	.formGroup label {
		font-size: 14px;
		font-weight: bold;
	}

	textarea,
	.number-input {
		font-size: 13px !important;
		width: 100%;
		font-weight: normal !important;
	}

	.downloadVideoBtn {
		display: none !important;
	}

	.publishTemplateButton {
		display: none !important;
	}

	.fields:not(.perfect-fields) {
		flex-wrap: wrap;
	}

	.formGroup,
	.formGroup.expanded {
		width: 49%;
		flex: unset;
	}

	.relativeButtonTop {
		display: none;
	}

	.aboveButtons {
		flex-direction: row !important;
	}

	.main {
		flex-direction: row;
		flex-wrap: wrap;
	}

	.statmakermiddle {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.edit-box {
		height: 17px !important;
	}

	.edit-box.left {
		left: 0px !important;
	}

	.edit-box.right {
		right: 0px !important;
	}

	.edit-individually {
		margin-top: 10px !important;
		height: fit-content !important;
	}
}

.statmakermiddle {
	width: min-content;
	margin-bottom: 25px;
}

.adminsettings {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.positionCheckboxes {
	overflow-x: scroll;
}

.progressdiv {
	background: var(--namebackground);
	justify-content: flex-start;
	align-items: center;
	position: relative;
	padding: 0 5px;
	display: flex;
}

.progress-value {
	animation: load 3s infinite forwards;
	box-shadow: 0 10px 40px -10px #fff;
	border-radius: 100px;
	background: #fff;
	height: 30px;
	width: 0;
}

@keyframes load {
	0% {
		width: 0;
	}
	100% {
		width: 100%;
	}
}

.faqFilter {
	width: 100%;
	margin: 2px;
	margin-bottom: 20px;
	background: #f3f3f3;
	border: none;
	border-radius: 6px;
	font-size: 16px;
	font-weight: bold;
	line-height: 1;
	resize: none;
}

.react-autocomplete-input {
	position: absolute !important;
	top: 100% !important;
}

.news-wrong-sport {
	display: flex;
	flex-direction: column;
	height: var(--postheightnews);
	width: var(--postwidthnews);
	min-width: var(--postwidthnews);
	overflow: hidden;
	background-color: black;
	position: relative;
	justify-content: center;
	color: white;
	align-items: center;
	align-content: center;
	flex-wrap: nowrap;
	text-align: center;
	padding: 25px;
}

.pyramid .tier-content {
	justify-content: center;
}

.pyramid .box {
	max-width: var(--pyramidboxwidth);
}

.tier-content {
	width: 100%;
	height: 100%;
	display: flex;
	outline: calc(var(--bordersize) * var(--sizemultiplier) / 2) solid var(--bordercolor);
	outline-offset: calc(var(--bordersize) * var(--sizemultiplier) / -2);
}

.tiertop .tier-content,
.tierbottom .tier-content {
	height: calc(100% - (var(--tiersize) * var(--sizemultiplier) / 2) - calc(10px * var(--sizemultiplier)));
}

.tiertop {
	flex-wrap: wrap;
}

.tierbottom {
	flex-wrap: wrap-reverse;
}

.tiertop.tierrow,
.tierbottom.tierrow {
	border-bottom: unset !important;
}

.tiertop .blend-in,
.tierbottom .blend-in {
	font-size: calc(var(--tiersize) * var(--sizemultiplier) / 2);
}

.setting-radio {
	display: flex;
	justify-content: center;
	margin-bottom: 10px;
}

.setting-radio > * {
	margin: 0px 5px;
	font-size: 12px;
	display: flex;
	align-items: center;
}

.setting-radio label input {
	margin-right: 5px;
}

.team-quick-add {
	margin: 5px;
	width: 100%;
	display: flex;
	flex-direction: column;
	animation: slideIn 1s;
}

@keyframes slideIn {
	from {
		transform: translateY(-30%);
		opacity: 0;
	}
	to {
		transform: translateY(0);
		opacity: 1;
	}
}

.team-quick-add h4 {
	margin: 0;
	font-size: 18px;
	text-align: center;
	font-weight: bold;
	margin-bottom: 0px;
}

.team-quick-add-logos {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	overflow: auto;
}

.team-quick-add-span {
	display: flex;
	width: clamp(35px, 4vw, 50px);
	height: clamp(35px, 4vw, 50px);
	border-radius: 10px;
	margin: 2px;
	cursor: pointer;
	overflow: hidden;
}

.team-quick-add-logo {
	width: 100%;
	height: 100%;
	background-size: cover;
}

.custom-team .team-quick-add-logo {
	background-size: contain;
	background-repeat: no-repeat;
	background-position-y: center;
}

.horizontal-select {
	display: flex;
	align-items: center;
	margin-bottom: 8px;
}

.horizontal-select > .css-2b097c-container {
	margin-bottom: 0px;
}

.conference-filter-label {
	margin-right: 10px;
	font-size: 12px !important;
}

#post.style-2 {
	position: relative;
}

.style-2 .newsRight {
	flex-direction: column-reverse;
	justify-content: center;
}

.style-2 .newsRight:not(.highlightImage):not(.lineupImage) {
	box-shadow: none !important;
}

.style-2 .newsRight .newsTeam {
	width: calc(var(--logosizenews) * var(--panesplitnewsright) / 100 * var(--sizemultiplier));
	height: calc(var(--logosizenews) * var(--panesplitnewsright) / 100 * var(--sizemultiplier));
	margin: unset;
	filter: none;
	margin-top: calc(20px * var(--sizemultiplier));
}

.style-2 .rightleft .newsRight .newsTeam,
.style-2 .bottomtop .newsRight .newsTeam {
	width: calc(var(--logosizenews) * var(--panesplitnewsleft) / 100 * var(--sizemultiplier));
	height: calc(var(--logosizenews) * var(--panesplitnewsleft) / 100 * var(--sizemultiplier));
}

.style-2 .textTogether {
	display: flex;
	flex-direction: column-reverse;
	text-align: center;
}

.style-2 .topbottom .textTogether {
	bottom: 8%;
}

.style-2 .newsTitle {
	justify-content: center;
	background: none;
	box-shadow: none;
	font-size: calc(var(--headersizenews) * 1.5 * var(--panesplitnewsright) / 50 * var(--sizemultiplier));
}

.style-2 .rightleft .newsTitle,
.style-2 .bottomtop .newsTitle {
	font-size: calc(var(--headersizenews) * 1.5 * var(--panesplitnewsleft) / 50 * var(--sizemultiplier));
}

.news-frame {
	width: var(--postwidthnews);
	height: var(--postheightnews);
	padding: calc(15px * var(--sizemultiplier));
	position: absolute;
	top: 0;
	pointer-events: none;
	z-index: 4;
}

.fa-quote-left,
.fa-quote-right {
	color: white;
	font-size: calc(60px * var(--sizemultiplier)) !important;
}

.frame-top-side {
	display: flex;
	width: 100%;
	position: absolute;
	left: calc(10px * var(--sizemultiplier));
	top: calc(10px * var(--sizemultiplier));
}

.frame-bottom-side {
	display: flex;
	width: 100%;
	position: absolute;
	left: calc(10px * var(--sizemultiplier));
	bottom: calc(10px * var(--sizemultiplier));
	align-items: flex-end;
}

.frame-top {
	display: inline-flex;
	width: 89%;
	margin-left: calc(10px * var(--sizemultiplier));
	height: calc(4px * var(--sizemultiplier));
	background-color: white;
}

.frame-bottom {
	display: inline-flex;
	width: 89%;
	margin-right: calc(10px * var(--sizemultiplier));
	height: calc(4px * var(--sizemultiplier));
	background-color: white;
}

.frame-left {
	position: absolute;
	display: inline-flex;
	width: calc(4px * var(--sizemultiplier));
	height: 89%;
	background-color: white;
	left: calc(10px * var(--sizemultiplier));
	bottom: calc(10px * var(--sizemultiplier));
}

.frame-right {
	position: absolute;
	display: inline-flex;
	width: calc(4px * var(--sizemultiplier));
	height: 89%;
	background-color: white;
	right: calc(10px * var(--sizemultiplier));
	top: calc(10px * var(--sizemultiplier));
}

.style-3 .newsRight {
	position: relative;
	justify-content: flex-start;
}

.style-3 .newsTeam {
	filter: none;
	z-index: 10;
}

.style-3 .newsText, 
.style-5 .newsText {
	padding: calc(var(--subtitlemarginnews) * var(--sizemultiplier)) calc(calc(var(--subtitlemarginnews) * 2) * var(--sizemultiplier)) calc(calc(var(--subtitlemarginnews) - 5px) * var(--sizemultiplier)) calc(calc(var(--subtitlemarginnews) * 2) * var(--sizemultiplier));
}

.style-3 .leftright .newsTitle::after,
.style-3 .rightleft .newsTitle::after {
	content: '';
	display: block;
	background: var(--headerfontcolornews);
	height: calc(5px * var(--sizemultiplier));
	bottom: 0px;
	width: calc(15px * var(--sizemultiplier));
}

.style-3 .newsTitle {
	box-shadow: calc(12px * var(--sizemultiplier)) calc(60px * var(--sizemultiplier)) calc(100px * var(--sizemultiplier)) calc(0px * var(--sizemultiplier)) #000000ba;
	flex-direction: column;
}

.news-lines {
	height: 100%;
	width: 100%;
	position: absolute;
	bottom: 0;
	overflow: hidden;
	pointer-events: none;
}

.news-line-1 {
	display: block;
	height: calc(10px * var(--sizemultiplier));
	background: white;
	transform: rotate(151deg);
	position: absolute;
	width: 200%;
	opacity: 0.08;
	bottom: calc(127px * var(--sizemultiplier));
	left: -60%;
	border: calc(1px * var(--sizemultiplier)) solid #000000ba;
}

.news-line-2 {
	display: block;
	height: calc(40px * var(--sizemultiplier));
	background: white;
	transform: rotate(151deg);
	position: absolute;
	width: 200%;
	opacity: 0.08;
	bottom: calc(70px * var(--sizemultiplier));
	left: 16%;
	border: calc(1px * var(--sizemultiplier)) solid #000000ba;
}

.news-line-3 {
	display: block;
	height: calc(500px * var(--sizemultiplier));
	background: linear-gradient(to right, black 50%, transparent 100%);
	transform: rotate(142deg);
	position: absolute;
	width: 200%;
	opacity: 0.06;
	top: calc(-63px * var(--sizemultiplier));
	left: -63%;
}

.news-line-4 {
	display: block;
	height: calc(500px * var(--sizemultiplier));
	background: linear-gradient(to right, black 75%, transparent 100%);
	transform: rotate(142deg);
	position: absolute;
	width: 200%;
	opacity: 0.04;
	top: calc(-245px * var(--sizemultiplier));
	left: -63%;
}

.news-line-5 {
	display: block;
	height: calc(20px * var(--sizemultiplier));
	background: linear-gradient(to right, white 75%, transparent 100%);
	transform: rotate(151deg);
	position: absolute;
	width: 200%;
	opacity: 0.1;
	bottom: calc(275px * var(--sizemultiplier));
	left: calc(160px * var(--sizemultiplier));
}

.style-4 .newsRight {
	box-shadow: none !important;
	justify-content: center;
	align-items: center;
}

.style-4 .newsTeam {
	filter: none;
	margin: unset;
	background-size: contain;
	background-repeat: no-repeat;
	width: calc(var(--logosizenews) * var(--panesplitnewsright) / 75 * var(--sizemultiplier));
	height: calc(var(--logosizenews) * var(--panesplitnewsright) / 75 * var(--sizemultiplier));
}

.style-4 .rightleft .newsTeam,
.style-4 .bottomtop .newsTeam {
	width: calc(var(--logosizenews) * var(--panesplitnewsleft) / 75 * var(--sizemultiplier));
	height: calc(var(--logosizenews) * var(--panesplitnewsleft) / 75 * var(--sizemultiplier));
}

.style-4 .leftright .newsTeam {
	margin-right: -20%;
}

.style-4 .leftright .textTogether {
	text-align: right;
	align-self: flex-end;
	margin-right: 20px;
}

.style-4 .rightleft .newsTeam {
	margin-left: -20%;
}

.style-4 .rightleft .textTogether {
	align-self: flex-start;
	margin-left: 20px;
}

.style-4 .newsTitle {
	background: none;
	box-shadow: none;
	justify-content: center;
}

.style-4 .rightleft .newsTitle {
	justify-content: flex-start;
}

.style-4 .leftright .newsTitle {
	justify-content: flex-end;
}

.style-4 .topbottom .newsImage,
.style-4 .bottomtop .newsImage {
	min-height: var(--postheightnews);
}

.style-4 .topbottom .textTogether,
.style-4 .bottomtop .textTogether {
	position: relative;
	bottom: unset;
}

.style-4 .topbottom .newsTeam,
.style-4 .bottomtop .newsTeam {
	position: relative;
	top: unset;
}

.postcontainer {
	display: flex;
	justify-content: center;
	box-shadow: 0px 0px 10px 0px black;
}

.edit-box {
	position: absolute;
	display: flex;
	height: 15px;
	width: 15px;
	font-size: 13px;
	padding: 2px;
	align-items: center;
	color: black;
	background-color: white;
	z-index: 100;
	padding: 10px;
	border-radius: 10px;
	justify-content: center;
}

.bottomPanel {
	width: 100%;
	margin-top: auto !important;
}

.bottomPanel .newButton {
	margin-top: 10px !important;
}

.grid .edit-box.left,
.tier .edit-box.left,
.bracket .edit-box.leftside,
.bracket .edit-box.middle {
	height: 15px;
	width: 15px;
	left: 4px;
	top: 4px;
}

.bracket .edit-box.rightside {
	height: 15px;
	width: 15px;
	right: 4px;
	top: 4px;
}

.score .edit-box.even {
	height: 15px;
	width: 15px;
	right: 4px;
	top: 4px;
}

.score .edit-box.odd {
	height: 15px;
	width: 15px;
	left: 4px;
	top: 4px;
}

.edit-gradients {
	width: 100%;
	margin-bottom: 6px;
}

.edit-spacer {
	display: block;
	height: 16px;
	width: 100%;
}

.edit-spacer2 {
	display: block;
	height: 5px;
	width: 100%;
}

.edit-individually {
	display: flex;
	flex-direction: column;
	padding: 15px;
	align-items: center;
	width: 100%;
}

.edit-individually h5 {
	width: 100%;
	justify-content: center;
	display: flex;
	text-align: center;
	font-size: 14px;
	margin-top: 6px;
}

.edit-individually label {
	font-size: 13px;
	font-weight: normal;
	user-select: none;
}

.edit-individually .newButton {
	width: 100%;
	margin: 0;
}

.edit-individually .setting-radio {
	width: 100%;
	margin-bottom: 6px;
}

.edit-team-header {
	text-transform: capitalize;
	border-bottom: 1px solid gray;
	margin-bottom: 8px;
	font-size: 18px !important;
}

.edit-team-header div {
	height: 21px;
	width: 21px;
	background-position: center center;
	background-size: cover;
	margin-left: 5px;
}

.form-error {
	color: red;
	font-size: 12px;
}

.newButton:disabled,
button[disabled] {
	background-color: gray !important;
	border-color: gray !important;
	color: white;
}

.team-quick-add-span.custom-team {
	width: clamp(59px, 4vw, 74px);
}

.team-quick-add-span .deleteButton {
	color: white;
}

.team-quick-add-span:hover {
	box-shadow: 0 0 3pt 2pt cornflowerblue;
}

.team-quick-add-span .deleteButton:hover {
	border: 2px solid transparent !important;
	border-radius: 10px !important;
	border-top-left-radius: 0px !important;
	border-bottom-left-radius: 0px !important;
	box-shadow: 0 0 3pt 2pt cornflowerblue;
}

.add-team-modal .modal-body {
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow: auto;
}

.add-team-modal .modal-body h5 {
	width: 100%;
	justify-content: center;
	display: flex;
	text-align: center;
	font-size: 14px;
	margin-top: 6px;
}

.add-team-modal .chrome-picker {
	margin: auto;
}

.add-team-modal textarea {
	resize: none;
}

.add-team-modal .newButton {
	width: 100%;
}

.add-team-modal form {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.example-span {
	display: flex;
	margin-bottom: 6px;
	flex-direction: column;
}

.example-span .newButtonSecondary {
	width: 100%;
}

.team-quick-add-logo:hover {
	border-radius: 10px;
	box-shadow: 0 0 3pt 2pt cornflowerblue;
}

.modal-content,
.modal-dialog {
	max-height: calc(100dvh - 56px);
}

.Toastify__toast-container {
	z-index: 1000000 !important;
}

.add-team-modal .file-input {
	padding: 13px;
}

.form-group.session-expired {
	width: 100%;
}

.uploadcare--widget__button_type_open {
	border-radius: 6px;
	font-size: 11px;
	margin-top: 5px;
	padding: 5px;
	text-transform: uppercase;
	margin: 0px;
	background-color: var(--primarycolor);
	color: white;
	font-weight: bold;
	border: 2px solid var(--primarycolor);
}

.uploadcare--widget__button_type_open:hover,
.uploadcare--widget__button_type_open:focus {
	border-radius: 6px;
	font-size: 11px;
	margin-top: 5px;
	padding: 5px;
	text-transform: uppercase;
	margin: 0px;
	background-color: var(--primarycolor);
	color: white;
	font-weight: bold;
	border: 2px solid var(--primarycolor);
}

.grid .boxstat > span {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: stretch;
}

.paypal-success-page,
.stripe-success-page {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 80vh;
	width: 70%;
	margin: 5% 15%;
	text-align: center;
}

.paypal-success-page .fa-check-circle,
.stripe-success-page .fa-check-circle {
	font-size: 40px;
	margin-bottom: 20px;
}

.paypal-success-page h1,
.stripe-success-page h1 {
	margin-bottom: 20px;
}

.radiopay.center > label {
	margin: 0px 5px;
}

.requested-change {
	text-align: center;
	width: 100%;
	border-radius: 4px;
	font-size: 13px;
	margin-top: 8px;
	padding: 8px;
	background-color: white;
	color: var(--primarycolor);
	font-weight: bold;
	border: 2px solid var(--primarycolor);
	margin-bottom: 8px;
}

.grid .playerImageContainer {
	position: absolute;
	top: 0;
	align-self: center;
	height: stretch;
	max-width: 100%;
	width: 100%;
	object-fit: cover;
	border-bottom-left-radius: calc(var(--borderradius) * var(--sizemultiplier));
	border-bottom-right-radius: calc(var(--borderradius) * var(--sizemultiplier));
	z-index: 2;
	overflow: hidden;
	display: flex;
	justify-content: center;
}

.bracket .box.candragfalse {
	cursor: pointer;
}

.react-draggable {
	display: flex;
	justify-content: center;
	flex-shrink: 1 !important;
}

.splitrow {
	display: grid;
	grid-template-rows: 50% 50%;
}

.tierleft .blend-in {
	border: calc(var(--bordersize) * var(--sizemultiplier) / 2) solid var(--bordercolor);
	border-left: none;
}

.matchupBoxes {
	display: flex;
	position: relative;
	width: 100%;
	overflow: visible;
	min-height: 0;
}

.matchupBoxes .boxwrap {
	width: 50%;
}

.leftright .matchup,
.rightleft .matchup {
	width: 100%;
}

.leftright .matchupBoxes,
.rightleft .matchupBoxes {
	flex-direction: column;
}

.leftright .matchupBoxes .boxwrap,
.rightleft .matchupBoxes .boxwrap {
	width: 100%;
	height: 50%;
}

.matchup .box {
	flex-direction: row;
	border: calc(var(--bordersize) * var(--sizemultiplier)) solid var(--bordercolor);
	width: 100%;
}

.leftright .matchup .box,
.rightleft .matchup .box {
	flex-direction: column;
}

.matchup .box.odd {
	flex-flow: row-reverse;
}

.leftright .matchup .box.odd,
.rightleft .matchup .box.odd {
	flex-flow: column-reverse;
}

.matchup .boxname {
	font-size: calc(var(--namesize) * 2 * var(--sizemultiplier));
	text-align: center;
	width: 50%;
	margin-left: 0 !important;
	order: calc(var(--logoorder) * -1);
	line-height: normal;
}
.matchup .boximagecontainer {
	height: 100%;
	width: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.leftright .matchup .boxname,
.rightleft .matchup .boxname {
	width: 100%;
	height: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.topbottom .matchup .even .boxname,
.bottomtop .matchup .even .boxname {
	margin-right: var(--namemargin2) !important;
}
.topbottom .matchup .odd .boxname,
.bottomtop .matchup .odd .boxname {
	margin-left: var(--namemargin2) !important;
}
.leftright .matchup .even .boxname,
.rightleft .matchup .even .boxname {
	margin-top: 0;
	margin-bottom: var(--namemargin) !important;
}
.leftright .matchup .odd .boxname,
.rightleft .matchup .odd .boxname {
	margin-bottom: 0;
	margin-top: var(--namemargin) !important;
}
.leftright .matchup .boximagecontainer,
.rightleft .matchup .boximagecontainer {
	width: 100%;
	height: 50%;
}
.matchup .boximage {
	height: calc(var(--logosize) * 1.5 * var(--sizemultiplier));
	width: calc(var(--logosize) * 1.5 * var(--sizemultiplier));
	margin: 0;
	max-height: 100%;
}

.GamePoll {
	margin: 20px;
}

.GamePoll .main {
	display: flex;
	justify-content: center;
	margin: 0;
	flex-wrap: wrap;
}

.GamePoll .newButton {
	display: flex;
	margin: 0 auto;
	justify-content: center;
}

.pollMatchup {
	display: flex;
	height: 1280px;
	width: 720px;
	flex-direction: column;
}

.matchupteam {
	width: 100%;
	height: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	align-content: center;
}

.matchupimage {
	background-size: contain;
	width: 90%;
	height: 90%;
	min-width: 0;
	background-repeat: no-repeat;
	background-position: center;
}

div.matchupteam:nth-of-type(1) {
	border-bottom: 5px solid white;
}

div.matchupteam:nth-of-type(2) {
	border-top: 5px solid white;
}

.legalPage {
	margin: 20px;
}

.legalPage h1 {
	text-align: center;
	font-size: 21px;
}

.legalPage h2 {
	font-size: 19px;
}

.legalPage h3 {
	font-size: 17px;
}

.AdminPage {
	margin: 10px;
}

.AdminPage .main {
	display: flex;
	justify-content: center;
	margin: 0;
	flex-wrap: wrap;
}

.AdminPage .newButton {
	display: flex;
	margin: 0 auto;
	justify-content: center;
	margin-bottom: 10px;
}

.Crons,
.Users {
	min-width: 800px;
}

.cron-modal select {
	width: 100%;
	height: 35px;
	font-size: 12px;
	background: white;
	border: 1px solid gray;
	border-radius: 6px;
	font-weight: bold;
	line-height: 1;
	padding: 6px;
}

.cron-modal input,
.cron-modal textarea {
	height: 35px;
	font-size: 12px;
	background: white;
	border: 1px solid gray;
	border-radius: 6px;
	font-weight: bold;
	line-height: 1;
	padding: 6px;
}

.modal-body {
	overflow: auto;
}

@media (max-width: 800px) {
	.modal-body {
		padding-bottom: 40px !important;
	}
}

.cron-modal .form-group {
	margin-bottom: 5px;
}

.cron-modal .form-group label {
	margin-bottom: 2px;
	font-weight: bold;
}

.many-labels {
	display: flex;
	margin-top: 2px;
	margin-right: 22px;
	font-weight: bold;
}

.many-labels span {
	width: 25%;
	text-align: center;
}

.many-inputs {
	display: flex;
	align-items: center;
	margin-bottom: 0 !important;
}

.many-inputs textarea {
	margin: 2%;
	width: 96%;
}

.many-inputs textarea.form-control {
	resize: none;
}

.pending-images {
	display: flex;
}

.pending-images span {
	width: 23%;
	margin: 1%;
	border: 1px solid gray;
	border-radius: 6px;
	text-align: center;
	background-color: #e9e7e7;
}

.addImage,
.removeImage {
	background-color: var(--primarycolor);
	color: white;
	border: 1px solid var(--primarycolor);
	border-radius: 360px;
	width: 20px;
	height: 20px;
	padding: 0px 4px 3px 4px;
	display: flex;
	justify-content: center;
	align-items: center;
	align-self: center;
}

.removeImage {
	background-color: red;
	border: 1px solid red;
	font-size: 12px;
	font-weight: bold;
	padding: 0px 4px 1px 4px;
}

.addImage:hover,
.removeImage:hover {
	border: 1px solid black !important;
	border-radius: 360px !important;
}

.imagesTD {
	display: flex;
	flex-direction: column;
}

.imagesTD span {
	white-space: nowrap;
}

.AdminPage .deleteButton {
	font-size: 15px;
}

.AdminPage .editButton {
	font-size: 15px;
	margin-right: 10px;
}

.AdminPage .duplicateButton {
	font-size: 15px;
	margin-right: 10px;
}

.same-line {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.same-line label {
	white-space: nowrap;
	margin: 0 10px 0 0;
}

.AdminPage .table {
	white-space: break-spaces;
	border: 2px solid gray;
	border-radius: 15px;
	border-collapse: separate;
	font-size: 12px;
	overflow: hidden;
	padding: 10px;
}

.table-head {
	display: grid;
	grid-template-columns: repeat(10, 1fr);
	font-weight: bold;
	font-family: 'roboto bold';
	font-size: 15px;
}

.table-head > span {
	text-align: center;
	border: none;
	padding: 5px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.table-body {
	display: flex;
	flex-direction: column;
}

.expandable-row > .first-row,
.table-row {
	display: grid;
	grid-template-columns: repeat(10, 1fr);
}

.expandable-row > .expanded-row {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	text-align: center;
}

body.dark .expandable-row > .first-row > span,
body.dark .expandable-row > .expanded-row > span {
	border: 1px solid #242424;
}

.expandable-row > .first-row > span,
.table-row > span {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	border: 1px solid #f1f1f1;
	font-family: 'roboto condensed';
	font-size: 14px;
}

.expandable-row > .expanded-row > span {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding: 10px;
	border: 1px solid #f1f1f1;
	font-family: 'roboto condensed';
	font-size: 14px;
}

.first-row {
	padding: 0 !important;
}

body.dark .first-row,
body.dark .expanded-row {
	background: black;
	color: white;
}

.AdminLinks > * {
	display: flex;
	justify-content: center;
}

.Users .table-head,
.Users .table-row {
	grid-template-columns: 1fr 1fr 1fr 70px 70px 130px 225px 115px 75px 75px 75px 75px 75px;
}

.Stats .table-head {
	position: sticky;
	top: -10px;
	width: fit-content;
}

.Users .table-row > span {
	justify-content: flex-start;
	text-overflow: ellipsis;
	overflow: hidden;
	text-align: left;
	display: block;
	white-space: nowrap;
}

.Stats .table {
	height: calc(100vh - 190px) !important;
}

.Stats .table-row {
	width: fit-content;
}

.stat-category,
.stat-subcategory {
	display: flex;
	width: 100%;
}

.sport-btn,
.sub-btn {
	padding: 2px 0px;
	margin: 0px 10px;
	cursor: pointer;
}

.sport-btn:hover,
.sub-btn:hover {
	color: var(--primarycolor);
}

.sport-btn.selected,
.sub-btn.selected {
	border-bottom: 2px solid var(--primarycolor);
}

.stat-filters {
	display: flex;
	width: 100%;
	height: 45px;
	padding: 0px 5px;
	align-items: center;
}

.stat-filters .css-2b097c-container {
	width: 100px;
	margin-bottom: 0;
}

.tickets .stat-filters .css-2b097c-container {
	width: 200px;
}

.tickets .stat-filters .css-2b097c-container + .css-2b097c-container {
	margin-left: 6px;
}

.sub-label {
	font-size: 11px;
}

.fields label {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	width: 100%;
}

.headerButton {
	font-size: 1.3em;
	margin-right: 15px;
	padding: 5px;
}

.Dashboard .Header .headerButton {
	color: white;
}

.headerButton:hover {
	color: darkgray;
}

.tickets .table-head,
.tickets .table-body,
.tickets .table-row {
	grid-template-columns: repeat(7, 1fr);
}

.floatingText {
	position: absolute;
	width: var(--postwidth);
	height: calc(1px * var(--sizemultiplier));
	top: 0;
	left: 0;
}

.floatingText .blend-in {
	min-width: calc(150px * var(--sizemultiplier));
}

.blend-in::placeholder {
	color: lightgray;
}

.floatingText .moveable {
	border: calc(1px * var(--sizemultiplier)) dashed white;
}

.floatingTextSpan {
	display: flex;
	flex-direction: column;
	width: fit-content !important;
}

.floatingEditor {
	position: absolute;
	background-color: white;
	border-radius: calc(5px * var(--sizemultiplier));
	z-index: 1000;
}

.floating-rich-editor {
	display: flex;
	overflow: visible;
	padding: calc(8px * var(--sizemultiplier));
	box-shadow: 0px 0px calc(5px * var(--sizemultiplier)) black;
	border-radius: calc(5px * var(--sizemultiplier));
}

.floating-rich-editor > * {
	margin-right: calc(4px * var(--sizemultiplier));
}

.floating-rich-editor .editor-input {
	display: flex;
}

.floatingTextInnerSpan {
	display: flex;
	align-items: center;
	position: relative;
}

.float-handle {
	position: absolute;
	cursor: grab;
	left: calc(-22px * var(--sizemultiplier));
	font-size: calc(1.5em * var(--sizemultiplier));
	text-shadow: 0px 0px calc(3px * var(--sizemultiplier)) black;
}

.fa-grip-vertical {
	color: white;
}

#post {
	position: relative;
}

.statmakermiddle {
	position: relative;
}

.hide-options > .floatleft > *:not(.dont-hide),
.hide-options > .floatright > *:not(.dont-hide) {
	display: none;
}

.combinedRanks {
	display: flex;
	z-index: 1;
	position: relative;
	font-size: calc(var(--statsize) * var(--sizemultiplier));
	font-weight: bold;
	font-family: 'robotobold';
	line-height: normal;
	margin-top: calc(2px * var(--sizemultiplier));
}

.combinedRank {
	background: white;
	color: black;
	width: calc(54px * var(--sizemultiplier));
	display: flex;
	margin: calc(4px * var(--sizemultiplier));
	align-items: center;
	border-radius: calc(5px * var(--sizemultiplier));
	justify-content: center;
}

.opponent {
	background: white;
	width: calc(65px * var(--sizemultiplier));
	display: flex;
	margin: calc(4px * var(--sizemultiplier)) 0px calc(4px * var(--sizemultiplier)) calc(4px * var(--sizemultiplier));
	align-items: center;
	justify-content: center;
	position: relative;
	border-top-left-radius: calc(5px * var(--sizemultiplier));
	border-bottom-left-radius: calc(5px * var(--sizemultiplier));
}

.oppAt {
	position: absolute;
	font-size: calc(14px * var(--sizemultiplier));
	left: 0;
	color: white;
	margin-top: -2px;
}

.oppRank {
	letter-spacing: calc(-0.5px * var(--sizemultiplier));
	background: white;
	color: black;
	font-size: calc(calc(var(--statsize) - 3px) * var(--sizemultiplier));
	width: calc(54px * var(--sizemultiplier));
	display: flex;
	margin: calc(4px * var(--sizemultiplier)) calc(4px * var(--sizemultiplier)) calc(4px * var(--sizemultiplier)) 0px;
	align-items: center;
	border-top-right-radius: calc(5px * var(--sizemultiplier));
	border-bottom-right-radius: calc(5px * var(--sizemultiplier));
	justify-content: center;
}

.oppImage {
	background-position: center center;
	background-size: cover;
	height: 100%;
	width: 70%;
}

.ordinal {
	font-variant-numeric: ordinal;
}

.subheaderbar {
	height: calc(36px * var(--sizemultiplier));
	padding-top: calc(2px * var(--sizemultiplier));
	font-size: calc(15px * var(--sizemultiplier));
	color: white;
	display: flex;
	align-items: center;
	outline: calc(2px * var(--sizemultiplier)) solid var(--bordercolor);
	outline-offset: 0px;
	z-index: 2;
	position: relative;
}

.subheaderbar > span {
	text-align: center;
	line-height: 0.9;
	word-break: break-word;
	font-family: 'robotobold';
	font-weight: bold;
}

.subheaderbar > span:nth-of-type(1) {
	width: 12.5%;
}

.subheaderbar > span:nth-of-type(2) {
	width: 53%;
}

.subheaderbar > span:nth-of-type(3) {
	width: 8%;
}

.subheaderbar > span:nth-of-type(4) {
	width: 8%;
}

.subheaderbar > span:nth-of-type(5) {
	width: 8%;
}

.subheaderbar > span:nth-of-type(6) {
	width: 8%;
}

.subheaderbar > span:nth-of-type(7) {
	width: 10%;
}

.insta-previews {
	display: flex;
	overflow: auto;
	margin-bottom: 7px;
}

.insta-preview {
	width: 100px;
	margin: 7px;
	border-radius: 5px;
	user-select: none;
}

.multi-line-name {
	white-space: pre !important;
	line-height: var(--lineheight);
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	height: 100%;
}

.multi-line-span {
	white-space: nowrap;
	/* overflow: hidden;
	text-overflow: ellipsis;
	width: stretch; */
}

.multi-line-stat {
	white-space: pre;
	line-height: var(--statlineheight);
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: center;
	height: 100%;
}

.filterTypes {
	display: flex;
	overflow: auto;
}

.filterTypeButton {
	display: flex;
	min-width: 120px;
	margin: 0px 5px 15px 0px;
}

.innerFilterTypeButton {
	width: 100% !important;
}

.removeSearch {
	position: absolute;
	top: 3.5px;
	right: 1px;
	color: #cccccc;
}

.removeSearch:hover {
	color: #999999;
}

.texture-box {
	width: 14px;
	height: 14px;
	background-image: linear-gradient(135deg, #444cf7 25%, transparent 25%), linear-gradient(225deg, #444cf7 25%, transparent 25%), linear-gradient(45deg, #444cf7 25%, transparent 25%), linear-gradient(315deg, #444cf7 25%, #e5e5f7 25%);
	background-size: 7px 7px;
	background-repeat: repeat;
}

.boxtexture {
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 2;
	opacity: var(--textureopacity);
}

.post .boxtexture {
	border-radius: calc(calc(var(--borderradius) - 5px) * var(--sizemultiplier));
}

.rhombus {
	background-image: linear-gradient(135deg, #000000 25%, transparent 25%), linear-gradient(225deg, #000000 25%, transparent 25%), linear-gradient(45deg, #000000 25%, transparent 25%), linear-gradient(315deg, #000000 25%, transparent 25%);
	background-size: var(--calcTextureSize) var(--calcTextureSize);
	background-repeat: repeat;
}

.wavy {
	background-image: repeating-radial-gradient(circle at 0 0, transparent 0, #e5e5f7 var(--calcTextureSize)), repeating-linear-gradient(#444cf755, #000000);
}

.polka {
	background-image: radial-gradient(#000000 var(--calcTextureSize), transparent var(--calcTextureSize)), radial-gradient(#000000 var(--calcTextureSize), transparent var(--calcTextureSize));
	background-size: calc(var(--calcTextureSize) * 4) calc(var(--calcTextureSize) * 4);
	background-position: 0 0, calc(var(--calcTextureSize) * 2) calc(var(--calcTextureSize) * 2);
}

.square {
	background-image: linear-gradient(45deg, #000000 25%, transparent 25%, transparent 75%, #000000 75%, #000000), linear-gradient(45deg, #000000 25%, transparent 25%, transparent 75%, #000000 75%, #000000);
	background-position: 0 0, calc(var(--calcTextureSize) / 2) calc(var(--calcTextureSize) / 2);
	background-size: var(--calcTextureSize) var(--calcTextureSize);
}

.triangle {
	background-image: linear-gradient(45deg, #000000 50%, transparent 50%);
	background-size: var(--calcTextureSize) var(--calcTextureSize);
}

.isometric {
	background-image: linear-gradient(30deg, #000000 12%, transparent 12.5%, transparent 87%, #000000 87.5%, #000000), linear-gradient(150deg, #000000 12%, transparent 12.5%, transparent 87%, #000000 87.5%, #000000),
		linear-gradient(30deg, #000000 12%, transparent 12.5%, transparent 87%, #000000 87.5%, #000000), linear-gradient(150deg, #000000 12%, transparent 12.5%, transparent 87%, #000000 87.5%, #000000),
		linear-gradient(60deg, #00000077 25%, transparent 25.5%, transparent 75%, #00000077 75%, #00000077), linear-gradient(60deg, #00000077 25%, transparent 25.5%, transparent 75%, #00000077 75%, #00000077);
	background-size: var(--calcTextureSize) calc(var(--calcTextureSize) * 2);
	background-position: 0 0, 0 0, calc(var(--calcTextureSize) / 2) var(--calcTextureSize), calc(var(--calcTextureSize) / 2) var(--calcTextureSize), 0 0, calc(var(--calcTextureSize) / 2) var(--calcTextureSize);
}

.grass {
	background-image: url(./assets/grass.png);
	background-size: calc(var(--calcTextureSize) * 160);
}

.grainy {
	background-image: url(./assets/grainy.png);
	background-size: calc(var(--calcTextureSize) * 160);
	opacity: calc(var(--textureopacity) + 10%);
}


.splotchy {
	background-image: url(./assets/splotchy.png);
	background-size: calc(var(--calcTextureSize) * 160);
}

@media (max-width: 800px) {
	.wavy,
	.polka {
		display: none;
	}
}

.tierBtn {
	width: calc(100% - 20px);
}

.boxrank {
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: calc(var(--rankwidth) * var(--sizemultiplier));
	width: calc(var(--rankwidth) * var(--sizemultiplier));
	letter-spacing: var(--rankspacing);
	border-top-left-radius: calc(calc(var(--borderradius) - 5px) * var(--sizemultiplier));
	border-bottom-left-radius: calc(calc(var(--borderradius) - 5px) * var(--sizemultiplier));
	z-index: 1;
}

.boxrank.rankOutside {
	border-radius: calc(calc(var(--rankborderradius) - 5px) * var(--sizemultiplier));
	margin-right: calc(var(--rankmarginreal) * var(--sizemultiplier));
	margin-top: calc(var(--rankoutsidemargin) / -2 * var(--sizemultiplier));
	margin-bottom: calc(var(--rankoutsidemargin) / -2 * var(--sizemultiplier));
	position: 'relative';
	overflow: 'hidden';
}

.boxrank > span {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-family: var(--rankfont);
	font-size: calc(var(--ranksize) * var(--sizemultiplier));
	line-height: 1;
	margin-top: calc(var(--rankmargin) * var(--sizemultiplier));
	margin-left: calc(var(--rankmargin2) * var(--sizemultiplier));
	-webkit-text-stroke: calc(var(--boxrankoutlinesize) * var(--sizemultiplier)) var(--rankoutlinecolor);
	color: var(--rankfontcolor);
	padding: calc(5px * var(--sizemultiplier));
}

.post .boxwrap.withOutline .box {
	outline: calc(calc(var(--bordersize) * var(--sizemultiplier)) - 2px) solid var(--bordercolor);
}
.boxwrap.withOutline .boxrank {
	outline: calc(calc(var(--bordersize) * var(--sizemultiplier)) - 2px) solid var(--bordercolor);
}

.floatright {
	margin-left: auto;
}

.floatright .newButton {
	font-size: 16px;
}

.aboveButtons {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.aboveButtons .newButton {
	width: 30px;
	height: 30px;
	display: flex;
	font-size: 1em;
	margin: 10px 4px;
}

.darkToggleSpan {
	margin-right: 10px;
}

.darkToggle {
	opacity: 0;
	position: absolute;
}

.darkToggleLabel {
	background-color: #111;
	width: 50px;
	height: 26px;
	border-radius: 50px;
	position: relative;
	padding: 5px;
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.fa-moon {
	color: #f1c40f;
}

.fa-sun {
	color: #f39c12;
}

.darkToggleLabel .ball {
	background-color: #fff;
	width: 22px;
	height: 22px;
	position: absolute;
	left: 2px;
	top: 2px;
	border-radius: 50%;
	transition: transform 0.2s linear;
}

.darkToggle:checked + .darkToggleLabel .ball {
	transform: translateX(24px);
}

body.dark,
body.dark .Header,
body.dark .Dashboard,
body.dark .Statmaker,
body.dark .floatingEditor,
body.dark .popover-content,
body.dark .modal-content,
body.dark .editor-button-text,
body.dark button.editor-button,
body.dark .heroImages {
	background-color: black;
	color: white;
}
body.dark .popover-content {
	box-shadow: 0px 0px 6px 0px lightgray;
}
body.dark .postcontainer {
	box-shadow: 0px 0px 10px 0px lightgray;
}
body.dark hr.homeline {
	border-top: 1px solid rgb(255 255 255 / 40%);
}

body.dark .ReactModal__Overlay {
	background-color: #6b6b6bba !important;
}

body.dark .subtract-one,
body.dark .add-one,
body.dark .editor-input > input {
	background-color: #2b2b2b;
	border-color: #2b2b2b;
	color: white;
}

body.dark .darkToggleLabel,
body.dark textarea,
body.dark .number-input,
body.dark .settingsInput,
body.dark .savedPostFilter,
body.dark .newButtonSecondary,
body.dark .form-control,
body.dark .input-group-text,
body.dark .bg-light {
	background-color: #2b2b2b !important;
	color: white;
}

body.dark .form-control::placeholder {
	color: #6c757d;
}

body.dark .card-body,
body.dark .card-header,
body.dark .card ul {
	color: white !important;
}

body.dark .text-muted {
	color: #6c757d !important;
}

body.dark .relativeButtonTop,
body.dark .relativeButton,
body.dark .relativeButton2,
body.dark .relativeButton3,
body.dark .card {
	background-color: #2b2b2b;
}

body.dark button.editor-button:hover {
	background-color: darkgray;
}

body.dark .postFilterResults,
body.dark .deleteButton,
body.dark .calendarButton,
body.dark .table,
body.dark .fa-instagram,
body.dark .postFilterResults,
body.dark .numResults,
body.dark .uploadInfo {
	color: white;
}

body.dark .withLine {
	border-color: white;
}

body.dark .withLine span {
	background: black;
}

body.dark .page-selected {
	color: #00fff0;
}

body.dark .page-not-selected:hover {
	color: white;
}

body.dark .heroImage,
body.dark .table-row > span {
	border-color: #212121;
	background-color: #000000;
	color: white;
}

body.dark .HomeBackground {
	background: linear-gradient(to bottom, #00a19930 0%, black 7%, black 93%, #00a19930 100%);
}

.add-team {
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid gray;
}

.add-team:hover {
	background: lightgray;
}

.doubleicon {
	position: relative;
}

.secondicon {
	position: absolute;
	color: red;
	font-weight: 100;
	opacity: 0.7;
	font-size: 1.7em;
	top: 0px;
}

.modal .heroImage {
	margin: 0.15%;
	width: 33%;
}

.modal .heroImage:hover {
	transform: scale(1.5);
	box-shadow: 0 0 3pt 2pt cornflowerblue;
}

.backgroundModal .heroImage:hover {
	transform: scale(1.2);
}

.overrideButtons {
	display: flex;
	overflow: auto;
	padding-bottom: 8px;
}

.overrideButtons .newButton {
	min-width: fit-content;
}

.modal-body .edit-individually {
	min-height: unset;
	margin-top: 0px;
}

.boxPreview {
	display: flex;
	justify-content: center;
	margin-top: 13px;
}

@media (max-width: 575px) {
	.boxPreview .box {
		height: 8.1vw !important;
		width: 90vw !important;
	}

	.boxPreview .box .boximage {
		height: 8.1vw !important;
	}
}

.flexRow {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 8px;
}

.flexRow h5 {
	margin-bottom: 0px;
	margin-right: 10px;
	font-weight: bold;
}

.lock-after {
	display: flex;
	position: relative;
}

.lock-after .flexRow {
	display: none;
}

.blackBackground {
	background: white;
	padding: 3px
}

body.dark .blackBackground {
	background: black;
}

.blackBackground2 {
	background: white;
	padding: 6px 7px 6px 10px;
}

body.dark .blackBackground2 {
	background: black;
}

.lock-after:hover .flexRow {
	display: flex;
	animation: slidetwo 0.5s;
	position: absolute;
	top: 100%;
	width: 100%;
	z-index: 4;
	border-radius: 0px 4px 4px 0px;
	cursor: pointer;
	background: white;
}

body.dark .lock-after:hover .flexRow {
	background: black;
}

.lock-after .newButton {
	width: fit-content;
    margin: 0;
}

@keyframes slidetwo {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.flexToggles {
	display: flex;
	align-items: center;
}

.moreReasons {
    margin: 0 auto;
    width: fit-content;
}

.grid:has(.graphic-footer) .jerseyBox .Jerseynum {
	bottom: calc(29px * var(--sizemultiplier));
}

.graphic-footer {
	display: flex;
	align-items: center;
	height: calc(var(--footerheight) * var(--sizemultiplier));
	margin-top: auto;
	background-color: var(--footerbackground);
	color: var(--footerfontcolor);
	font-size: calc(var(--footersize) * var(--sizemultiplier));
	font-family: var(--footerfont);
}

.grid .graphic-footer {
	grid-column-start: 1;
}

.footer-text {
	text-align: var(--footeralignment);
	margin-top: calc(var(--footermargin) * var(--sizemultiplier));
	letter-spacing: calc(var(--footerspacing) * var(--sizemultiplier));
	-webkit-text-stroke: calc(var(--footeroutlinesize) * var(--sizemultiplier)) var(--footeroutlinecolor);
	padding: calc(var(--footerpadding) * var(--sizemultiplier));
}

.footer-text.textfit {
	height: 100%;
	width: 100%;
	max-width: 100%;
}

.post .graphic-footer {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
}

.helmet, .jersey, .stripes {
	position: absolute;
	width: 85%;
}

.tierBox {
	background-color: var(--tierbackground);
}

.note {
	font-size: 11px;
    line-height: 1.1;
    display: block;
	padding: 5px;
}
.no-shadow {
	text-shadow: none !important;
}

.picker {
	width: auto;
	background: #f0f0f4;
	border-radius: 9px;
  }

  body.dark .picker,
  body.dark .picker input {
	background: #2d2d2d;
	color: white;
  }
  
  .picker .react-colorful {
	width: auto;
  }
  
  .picker__swatches {
	display: flex;
	padding: 4px;
	flex-wrap: wrap;
  }
  
  .picker__swatch {
	width: 24px;
	height: 24px;
	margin: 4px;
	border: none;
	padding: 0;
	border-radius: 4px;
	cursor: pointer;
	outline: none;
	box-shadow: 0px 0px 5px 0px black;
  }

  body.dark .picker__swatch {
	box-shadow: 0px 0px 5px 0px lightgray;
  }

  body.dark .react-autocomplete-input,
  body.dark .react-autocomplete-input > li {
	background-color: #2d2d2d;
  }

  .picker input {
	height: 27px;
	width: stretch;
	padding: 3px 6px;
	margin: 6px;
	border: 1px solid #cccccc !important;
	border-radius: 4px !important;
}

.react-colorful__pointer {
    box-shadow: 0px 0px 4px 3px rgba(0,0,0,.2);
}

.editing .boxname:hover, .editing .boxstat:hover {
	background-color: #d4d4d430;
	cursor: pointer;
}

.editing2 .boxname:hover, .editing2 .boxstat:hover {
	background-color: #4d4d4d;
	cursor: pointer;
}

.popover-content textarea {
	resize: none;
}

.highlighter {
	width: 300px;
    padding: 30px 25px;
}

.newHighlight, .existingHighlight {
	position: relative;
	display: flex;
	gap: 6px;
	align-items: center;
	margin: 5px 0px;
  }

  .newHighlight textarea {
	flex: 1;
	height: 33px;
	margin-bottom: 0px !important;
  }

  .existingHighlight .highlightText {
	flex: 1;
	height: 33px;
	display: flex;
	align-items: center;
  }
  
  .swatch {
	width: 28px;
	height: 28px;
	border-radius: 8px;
	border: 1px solid hsl(0, 0%, 80%);
}

.newHighlight .swatch {
	z-index: 2;
	cursor: pointer;
}

.newHighlight .swatch:hover {
	filter: brightness(85%);
}

.colorPopover {
	position: absolute;
    top: calc(100% + -16px);
    right: 5%;
    border-radius: 9px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    z-index: 1;
    padding: 19px;
}

.topBottomHighlights {
	display: flex;
    gap: 5px;
    margin-top: 20px;
    position: relative;
}

.topBottomHighlights .flexRow h5 {
	margin-right: 0;
}

.topBottomHighlights .flexRow span {
	margin-right: 2px;
}
.HigherLower select {
	width: 400px;
}

.higherLower {
	display: flex;
	height: 1280px;
	width: 720px;
	flex-direction: column;
	position: relative;
	background: black;
	color: white;
}

.higherFooter {
	position: absolute;
	bottom: 25px;
	width: 100%;
	text-align: center;
}

.ad {
	position: absolute;
	bottom: 5px;
	right: 5px;
}

.pollBorder {
	position: absolute;
	display: flex;
	flex-direction: column;
	width: 460px;
	height: 275px;
	top: calc(50% - 157px);
	left: 130px;
	border-radius: 35px;
	background-color: white;
	box-shadow: 0px 0px 20px 5px white;
}

.bottomhalf {
	width: 100%;
	height: 50%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	align-content: center;
	padding: 185px 120px 85px 120px;
}

.playerimagediv {
	position: absolute;
	top: 250px;
}

.playerimagediv .playerimage {
	width: 350px;
}

.highertitle {
	font-family: 'edo';
    font-size: 50px;
    top: 0;
    text-align: center;
    background: black;
    padding: 5px 100px;
	line-height: 1.1;
	padding-top: 70px;
}

.highersubtitle {
    top: 150px;
    background: black;
    padding: 0px 100px;
    width: 100%;
    font-size: 29px;
    text-align: center;
	z-index: 0;
	font-family: 'robotobold';
}

.higherteam {
	width: 100%;
	height: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	align-content: center;
}

.higherimage {
	background-size: contain;
	width: 100%;
	height: 100%;
	min-width: 0;
	background-repeat: no-repeat;
	background-position: center;
}

.HigherLower {
	display: flex;
    flex-direction: column;
	align-items: center;
	margin: 20px;
	gap: 10px;
}

.HigherLower textarea {
	resize: none;
}

.HigherLower .newButton {
	width: 200px;
}

.chartTitle {
    font-size: 20px;
	font-family: 'roboto condensed';
}

.propQuestion {
	color: black;
	align-self: center;
	font-family: 'robotobold';
	font-size: 24px;
    line-height: 1;
    font-weight: bold;
	padding-top: 10px;
}

.postTypeGrid {
	display: flex;
    flex-wrap: wrap;
	justify-content: center;
}

.postTypeGrid > span {
    margin: 1.5%;
    border: 2px solid white;
    border-radius: 6px;
    min-width: 60px;
    width: 47%;
	max-width: 150px;
}

.postTypeGrid > span.selected {
	box-shadow: 0 0 3pt 2pt cornflowerblue;
}

.postTypeGrid > span:hover {
	box-shadow: 0 0 3pt 2pt cornflowerblue;
}

.postTypeGrid > span > img {
	width: 100%;
}

.textBoxes {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	flex: 1;
	width: stretch;
	z-index: 3;
}

.topbottom .textBoxes, .bottomtop .textBoxes {
	flex-direction: row;
}

.textBox {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: var(--namecolornews);
	font-size: calc(var(--subtitlesizenews) * 1.5 * var(--panesplitnewsright) / 50 * var(--sizemultiplier));
	font-family: var(--subtitlefontnews);
	font-weight: bold;
	line-height: 1;
	letter-spacing: var(--subtitlespacingnews);
}

.lineupImage .textBox {
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
}

.textBoxLeft {
	position: relative;
	font-family: var(--namefont);
	font-size: calc(var(--namesize) * var(--sizemultiplier));
	align-self: center;
	color: var(--namecolor);
	-webkit-text-stroke: calc(var(--nameoutlinesize) * var(--sizemultiplier)) var(--nameoutlinecolor);
	font-weight: 550;
	z-index: 99;
	width: inherit;
	line-height: 1.1;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin: 0px;
	letter-spacing: calc(var(--namespacing) * var(--sizemultiplier));
	margin-top: calc(var(--namemargin) * var(--sizemultiplier));
	margin-left: calc(var(--namemargin2) * var(--sizemultiplier));
	text-shadow: calc(var(--nameshadow) * var(--sizemultiplier)) calc(var(--nameshadow) * var(--sizemultiplier)) var(--nameshadowcolor);
	order: -1;
	text-transform: var(--nametransform);
}

.textBoxRight {
	font-family: var(--statfont);
	font-size: calc(var(--statsize) * var(--sizemultiplier));
	align-self: center;
	color: var(--statcolor);
	-webkit-text-stroke: calc(var(--statoutlinesize) * var(--sizemultiplier)) var(--statoutlinecolor);
	font-weight: 550;
	letter-spacing: calc(var(--statspacing) * var(--sizemultiplier));
	text-align: right;
	margin-right: calc((var(--statmargin2) * -1 * var(--sizemultiplier)) + 5px);
	white-space: nowrap;
	margin-top: calc(var(--statmargin) * var(--sizemultiplier));
	text-shadow: calc(var(--statshadow) * var(--sizemultiplier)) calc(var(--statshadow) * var(--sizemultiplier)) var(--statshadowcolor);
	z-index: 1;
	position: relative;
	order: 1;
	text-transform: var(--stattransform);
}

.textBoxTop {
	font-size: 200%;
	margin-bottom: calc(calc(var(--subtitlemarginnews) - 20px) * var(--sizemultiplier));
}

.highlightTitle,
.lineupTitle {
	color: var(--imagetitlecolor);
	font-family: var(--headerfontnews);
	font-size: calc(var(--headersizenews) * 2.5 * var(--panesplitnewsright) / 50 * var(--sizemultiplier));
	font-weight: bold;
	text-align: center;
	padding: calc(14px * var(--sizemultiplier)) calc(14px * var(--sizemultiplier)) calc(14px * var(--sizemultiplier)) calc(34px * var(--sizemultiplier));
	line-height: 1;
	letter-spacing: var(--headerspacingnews);
	z-index: 3;
	padding: calc(var(--headermarginnews) * var(--sizemultiplier));
}

.topbottom .highlightTitle,
.topbottom .lineupTitle,
.topbottom .noTitle {
	margin-top: calc(calc(0.5 * var(--logosizenews) * var(--panesplitnewsright) / 50 * var(--sizemultiplier)) + calc(-0.2 * var(--logosizenews) * var(--panesplitnewsright) / 50 * var(--sizemultiplier)));
}

.bottomtop .highlightTitle,
.bottomtop .lineupTitle,
.bottomtop .noTitle {
	margin-top: calc(calc(0.5 * var(--logosizenews) * var(--panesplitnewsleft) / 50 * var(--sizemultiplier)) + calc(-0.1 * var(--logosizenews) * var(--panesplitnewsleft) / 50 * var(--sizemultiplier)));
}

.style-3 .leftright .textBox,
.style-3 .rightleft .textBox {
	border-bottom: 1px solid white;
	flex: 1;
	width: 50%;
}

.style-3 .leftright .lineupImage .textBox,
.style-3 .rightleft .lineupImage .textBox {
	width: 75%;
}

.style-3 .topbottom .textBox,
.style-3 .bottomtop .textBox {
	border-right: 1px solid white;
	width: stretch;
	height: 50%;
}

.style-3 span.textBox:last-of-type {
	border-bottom: none;
	border-right: none;
}

.matchup .status {
	position: absolute;
	font-size: calc(var(--statsize) * var(--sizemultiplier));
	text-align: center;
	line-height: 1;
	left: 50%;
	transform: translateX(-50%);
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	top: calc(var(--statmargin) * var(--sizemultiplier));
	white-space: pre-wrap;
	margin: 0;
	z-index: 100;

	font-family: var(--statfont);
	align-self: center;
	color: var(--statcolor);
	-webkit-text-stroke: calc(var(--statoutlinesize) * var(--sizemultiplier)) var(--statoutlinecolor);
	font-weight: 550;
	text-transform: var(--stattransform);
}

.matchup .status span {
	background: var(--statbackground);
	padding: calc(10px * var(--sizemultiplier));
	border-radius: calc(10px * var(--sizemultiplier));
	white-space: pre-wrap;
	letter-spacing: calc(var(--statspacing) * var(--sizemultiplier));
	text-shadow: calc(var(--statshadow) * var(--sizemultiplier)) calc(var(--statshadow) * var(--sizemultiplier)) var(--statshadowcolor);
}

.matchup-style-2 .postycontainer, .matchup-style-3 .postycontainer {
	box-shadow: none !important;
	background: transparent !important;
}

.matchup {
	overflow: visible;
}

.matchup-style-2 .matchup, .matchup-style-3 .matchup {
	background: transparent !important;
}

.matchup-style-2 .topbottom .matchup, .matchup-style-3 .topbottom .matchup {
	padding: 0 calc(100px * var(--sizemultiplier)) calc(40px * var(--sizemultiplier)) calc(100px * var(--sizemultiplier));
}
.matchup-style-2 .bottomtop .matchup, .matchup-style-3 .bottomtop .matchup {
	padding: calc(40px * var(--sizemultiplier)) calc(100px * var(--sizemultiplier)) 0 calc(100px * var(--sizemultiplier));
}
.matchup-style-2 .leftright .matchup, .matchup-style-3 .leftright .matchup {
	padding: calc(100px * var(--sizemultiplier)) calc(40px * var(--sizemultiplier)) calc(100px * var(--sizemultiplier)) 0;
}
.matchup-style-2 .rightleft .matchup, .matchup-style-3 .rightleft .matchup {
	padding: calc(100px * var(--sizemultiplier)) 0 calc(100px * var(--sizemultiplier)) calc(40px * var(--sizemultiplier));
}

.matchup-style-2 .newsImage, .matchup-style-3 .newsImage {
	object-fit: cover;
    height: var(--imagezoomnews);
	min-height: var(--postheight);
	width: auto;
    margin-left: calc(var(--imagehorizontalnews) * var(--sizemultiplier));
    margin-top: calc(var(--imageverticalnews) * var(--sizemultiplier));
}

.matchup-style-2 .matchupBoxes {
	box-shadow: 0px 0px calc(calc(var(--imageshadowsize) + 150px) * var(--sizemultiplier))  calc(calc(var(--imageshadowsize) + 30px) * var(--sizemultiplier)) var(--imageshadowcolor);
}

.matchup-style-2 .matchup .status {
	top: unset;
	height: auto;
	font-size: calc(var(--statsize) * 2 * var(--sizemultiplier));
}

.matchup-style-2 .topbottom .matchup .status {
	bottom: calc(100% + calc(var(--statmargin) * var(--sizemultiplier)));
	right: calc((var(--statmargin2) * -1 * var(--sizemultiplier)) + 5px);
}
.matchup-style-2 .bottomtop .matchup .status {
	top: calc(100% + calc(var(--statmargin) * var(--sizemultiplier)));
	right: calc((var(--statmargin2) * -1 * var(--sizemultiplier)) + 5px);
}
.matchup-style-2 .leftright .matchup .status {
	top:  calc(0% + calc(var(--statmargin) * var(--sizemultiplier)));
	right: calc(100% + calc(var(--statmargin2) * var(--sizemultiplier)));
}
.matchup-style-2 .rightleft .matchup .status {
	top:  calc(0% + calc(var(--statmargin) * var(--sizemultiplier)));
	left: calc(100% + calc(var(--statmargin2) * var(--sizemultiplier)));
}

.matchup-style-2 .leftright .matchup .status span,
.matchup-style-2 .rightleft .matchup .status span {
	writing-mode: vertical-rl;
	text-orientation: upright;
}

.matchup-style-2 .matchup .status span {
	background-color: transparent;
}

.matchup-style-3 .matchup .boxwrap .boxname {
    background: #00000030;
    display: flex;
    height: 100%;
    align-items: center;
    margin: 0 !important;
    justify-content: center;
}

.matchup .matchupBoxes,
.matchup .boxwrap {
	border-radius: calc(calc(var(--borderradius) - 5px) * var(--sizemultiplier));
}

.topbottom .matchup .box.even,
.bottomtop .matchup .box.even {
	border-radius: calc(calc(var(--borderradius) - 5px) * var(--sizemultiplier)) 0px 0px calc(calc(var(--borderradius) - 5px) * var(--sizemultiplier));
}

.topbottom .matchup .box.odd,
.bottomtop .matchup .box.odd {
	border-radius: 0px calc(calc(var(--borderradius) - 5px) * var(--sizemultiplier)) calc(calc(var(--borderradius) - 5px) * var(--sizemultiplier)) 0px;
}

.matchup-style-3 .matchup .matchupBoxes,
.matchup-style-3 .matchup .boxwrap {
	border-radius: calc(calc(var(--borderradius) + 20px) * var(--sizemultiplier));
}

.matchup-style-3 .topbottom .matchup .box.even,
.matchup-style-3 .bottomtop .matchup .box.even {
	border-radius: calc(calc(var(--borderradius) + 20px) * var(--sizemultiplier)) 0px 0px calc(calc(var(--borderradius) + 20px) * var(--sizemultiplier));
}

.matchup-style-3 .topbottom .matchup .box.odd,
.matchup-style-3 .bottomtop .matchup .box.odd {
	border-radius: 0px calc(calc(var(--borderradius) + 20px) * var(--sizemultiplier)) calc(calc(var(--borderradius) + 20px) * var(--sizemultiplier)) 0px;
}

.matchup .extrainfoouter.even {
	left: 0;
	right: unset;
}

.matchup .extrainfoouter.top {
	top: 0;
	right: 0;
	bottom: unset;
}

.matchup .extrainfoouter.bottom {
	bottom: 0;
	right: 0;
}

.matchup .extrainfo {
	background: none;
	line-height: 1;
	font-size: calc(calc(var(--pointssize) * var(--sizemultiplier)) + calc(3px * var(--sizemultiplier)));
	min-width: 0;
    margin: calc(calc(var(--pointsmargin) * var(--sizemultiplier)) + calc(5px * var(--sizemultiplier)))calc(calc(var(--pointsmargin2) * var(--sizemultiplier)) + calc(5px * var(--sizemultiplier)));
    font-weight: 600;
    padding: calc(5px * var(--sizemultiplier));
    border-radius: calc(5px * var(--sizemultiplier));
    width: max-content;
	height: fit-content;
	letter-spacing: calc(calc(var(--pointsspacing) * var(--sizemultiplier)) + calc(1.2px * var(--sizemultiplier)));
	-webkit-text-stroke: unset;
}

.matchup .even .extrainfo {
    margin-left: calc(calc(var(--pointsmargin2) * var(--sizemultiplier)) + calc(5px * var(--sizemultiplier)));
}

.matchup .rankodd,
.matchup .rankeven,
.matchup .ranktop,
.matchup .rankbottom {
	padding: calc(5px * var(--sizemultiplier));
	border-radius: calc(5px * var(--sizemultiplier));
	-webkit-text-stroke: unset;
}

.matchup-style-2 .topbottom .extrainfoouter,
.matchup-style-3 .topbottom .extrainfoouter,
.matchup-style-2 .bottomtop .extrainfoouter,
.matchup-style-3 .bottomtop .extrainfoouter {
	bottom: unset;
	top: 102%;
}

.game-desc {
	position: absolute;
	bottom: 100%;
	font-family: var(--descriptionfont);
	text-align: center;
	color: var(--descriptionfontcolor);
	text-shadow: calc(var(--descriptionoutlinesize) * -1) calc(var(--descriptionoutlinesize) * -1) var(--descriptionoutlinesize) var(--descriptionoutlinecolor), var(--descriptionoutlinesize) calc(var(--descriptionoutlinesize) * -1) var(--descriptionoutlinesize) var(--descriptionoutlinecolor),
	calc(var(--descriptionoutlinesize) * -1) var(--descriptionoutlinesize) var(--descriptionoutlinesize) var(--descriptionoutlinecolor), var(--descriptionoutlinesize) var(--descriptionoutlinesize) var(--descriptionoutlinesize) var(--descriptionoutlinecolor);
	font-size: calc(var(--descriptionsize) * var(--sizemultiplier));
	letter-spacing: calc(var(--descriptionspacing) * var(--sizemultiplier));
	margin: 0;
	margin-bottom: calc(var(--descriptionmargin) * var(--sizemultiplier));
	width: 100%;
	line-height: 1;
	text-transform: var(--descriptiontransform);
}

.bottomtop .game-desc {
	bottom: unset;
	top: 100%;
	margin-top: calc(var(--descriptionmargin) * var(--sizemultiplier));
}

.matchup-style-2 .game-desc {
	bottom: unset;
	top: 100%;
	margin-top: calc(var(--descriptionmargin) * var(--sizemultiplier));
}

.matchup-style-2 .bottomtop .game-desc {
	top: unset;
	bottom: 100%;
}

.szh-menu-button {
	background-color: transparent;
	border: none;
	font-weight: bold;
	border-radius: 6px;
	margin-bottom: 2px;
	height: 25px;
	width: 24px;
}

.szh-menu-button:focus {
	border: none !important;
}

.szh-menu__item {
	font-size: 12px;
	font-weight: bold;
}

.szh-menu {
	z-index: 1000;
}

.templateImage {
	position: relative;
}

.templateImage .szh-menu-button {
	position: absolute;
	right: 0;
}

body.dark .szh-menu-button {
	color: white;
}

body.dark .szh-menu {
	background: #2B2B2B;
}

body.dark .szh-menu__item {
	color: white;
}

body.dark .szh-menu__item:hover {
	background: #505050;
}

.allow-overflow {
	overflow: visible;
}

.folderDropdown {
	display: flex;
	align-items: center;
	position: relative;
}

.folderDropdown .szh-menu-button {
	height: 27px;
	margin-bottom: 10px;
}

body.dark .css-zqenjx:hover,
body.dark .css-tj5bde-Svg:hover {
	color: white;
}

.comparisonSplit {
	display: flex;
	position: relative;
	height: 100%;
}

.comparisonLeft, .comparisonRight {
	display: flex;
	position: relative;
    flex-direction: column;
    width: 50%;
    margin: calc(var(--comparisonmargin) * var(--sizemultiplier)) calc(calc(var(--comparisonmargin) * var(--sizemultiplier)) / 4) calc(var(--comparisonmargin) * var(--sizemultiplier)) calc(var(--comparisonmargin) * var(--sizemultiplier));
    border-radius: calc(var(--comparisonradius) * var(--sizemultiplier));
    border: calc(var(--comparisonbordersize) * var(--sizemultiplier)) solid var(--comparisonbordercolor);
}

.comparisonRight {
    margin: calc(var(--comparisonmargin) * var(--sizemultiplier)) calc(var(--comparisonmargin) * var(--sizemultiplier)) calc(var(--comparisonmargin) * var(--sizemultiplier))  calc(calc(var(--comparisonmargin) * var(--sizemultiplier)) / 4);
}

.comparison .box {
	flex-direction: column;
	border: calc(var(--bordersize) * var(--sizemultiplier)) solid var(--bordercolor) !important;
	border-radius: calc(var(--borderradius) * var(--sizemultiplier));
	margin: var(--gridmargin);
	width: calc(100% - (var(--gridmargin) * 2 * var(--sizemultiplier)));
	overflow: hidden;
	height: 40%;
	min-height: 40%;
	box-shadow: inset 0px 0px calc(30px * var(--sizemultiplier)) calc(30px * var(--sizemultiplier) / 2) black;
}
.comparison .boximagecontainer {
	position: absolute;
    z-index: 0;
}
.comparison .boximage {
	margin-left: 0px;
	margin-right: 0px;
	position: absolute;
	height: stretch;
	min-width: stretch;
	background-repeat: no-repeat;
}

.leftName, .rightName {
	font-family: var(--namefont);
	font-size: calc(var(--namesize) * var(--sizemultiplier));
	text-align: center;
	color: var(--namecolor);
	-webkit-text-stroke: calc(var(--nameoutlinesize) * var(--sizemultiplier)) var(--nameoutlinecolor);
	font-weight: 550;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin: 0px;
	letter-spacing: calc(var(--namespacing) * var(--sizemultiplier));
	margin-top: calc(var(--namemargin) * var(--sizemultiplier));
	margin-left: calc(calc(var(--namemargin2) - 10px) * var(--sizemultiplier));
	text-shadow: calc(var(--nameshadow) * var(--sizemultiplier)) calc(var(--nameshadow) * var(--sizemultiplier)) var(--nameshadowcolor);
	text-transform: var(--nametransform);
	z-index: 2;
}

.comparisonStatGroup {
	display: flex;
	position: relative;
    flex-direction: column;
    flex: 1;
	justify-content: space-evenly;
	z-index: 2;
}

.comparisonStat, .statLabels {
	display: flex;
    flex-direction: column;
	justify-content: center;
	align-items: center;
	line-height: 1;
}

.comparisonStat { 
	font-family: var(--statfont);
	font-size: calc(var(--statsize) * var(--sizemultiplier));
	color: var(--statcolor);
	-webkit-text-stroke: calc(var(--statoutlinesize) * var(--sizemultiplier)) var(--statoutlinecolor);
	font-weight: 550;
	letter-spacing: calc(var(--statspacing) * var(--sizemultiplier));
	/* margin-right: calc((var(--statmargin2) * -1 * var(--sizemultiplier)) + 5px); */
	white-space: nowrap;
	/* margin-top: calc(var(--statmargin) * var(--sizemultiplier)); */
	text-shadow: calc(var(--statshadow) * var(--sizemultiplier)) calc(var(--statshadow) * var(--sizemultiplier)) var(--statshadowcolor);
	z-index: 1;
	text-transform: var(--stattransform);
}

.comparisonStatLabels {
	position: absolute;
	display: flex;
    flex-direction: column;
	height: 100%;
    left: 0;
    right: 100%;
	transform: translateX(calc(-50% - calc(calc(var(--comparisonmargin) * var(--sizemultiplier)) / 4) - calc(var(--comparisonbordersize) * var(--sizemultiplier))));
	width: fit-content;
	justify-content: space-evenly;
	z-index: 2;
}

.statLabels {
	width: 100%;
	background: var(--labelsbackground);
	border-radius: calc(6px * var(--sizemultiplier));
	padding: calc(var(--labelsmargin) * var(--sizemultiplier));
	white-space: nowrap;
	font-size: calc(var(--labelssize) * var(--sizemultiplier));
	font-family: var(--labelsfont);
	color: var(--labelsfontcolor);
	letter-spacing: var(--labelsspacing);
	text-transform: var(--labelstransform);
}

.comparison .playerImageContainer {
	position: absolute;
	top: 0;
	align-self: center;
	height: stretch;
	max-width: 100%;
	width: 100%;
	object-fit: cover;
	border-bottom-left-radius: calc(9px * var(--sizemultiplier));
	border-bottom-right-radius: calc(9px * var(--sizemultiplier));
	z-index: 2;
	overflow: hidden;
	display: flex;
	justify-content: center;
}

.comparison .playerImageContainer img {
	height: 100%;
}

.comparison .boxtexture {
	border-radius: calc(var(--comparisonradius) * var(--sizemultiplier));
}

.btn-block {
	display: block;
    width: 100%;
}

.form-group {
	margin-bottom: 1rem;
}

.input-group-append {
	display: flex;
    margin-left: -1px;
}

.btn-block+.btn-block {
    margin-top: 0.5rem;
}

.modal-header {
	justify-content: space-between;
}

.sub-label {
	margin-bottom: 0.5rem;
}

body.dark .Toastify__toast-theme--light {
	background-color: #212121;
	color: white;
}

body.dark .Toastify__close-button {
	color: white;
	opacity: 1;
}

#typeDropdown {
	margin-bottom: 10px;
}

body.dark .modal input[type='file']::file-selector-button:hover {
	color: lightgray;
}

body.dark .table-row a {
	background-color: #000000;
}

.save-url {
	width: 100%;
	margin-top: 10px;
}

#react-tiny-popover-container {
	max-height: 75dvh;
}

.react-datepicker-wrapper {
	width: 100%;
}

.react-datepicker-popper {
    z-index: 100;
}

.sidebar-menu {
	padding: 10px 0px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	position: relative;
	width: 72px;
	background: #F2F5F7;
	overflow-y: auto;
}

.sidebar-button {
	display: flex;
	flex-direction: column;
	align-items: center;
    justify-content: center;
	width: 100%;
	font-size: 11px;
	padding: 5px 15px;
	color: rgb(24, 25, 26);
	font-weight: bold;
	border: none;
	background: #F2F5F7;
}

.sidebar-button-wrapper.active > .sidebar-button {
	color: #252627;
	background: #EAEEF1;
}

.sidebar-button:hover, .sidebar-button:focus {
	color: #252627;
	box-shadow: none !important;
}

.Dashboard {
    display: flex;
    flex-direction: column;
	height: 100vh;
    max-height: 100vh;
	height: 100dvh;
    max-height: 100dvh;
}

.DashboardContent {
	display: flex;
    flex: 1;
    overflow: hidden;
	position: relative;
}

.sidebar-content {
	display: flex;
    flex-direction: column;
	width: 300px;
	height: 100%;
	background-color: #EAEEF1;
	transition: width .25s ease-in-out;
	opacity: 1;
	border-right: 1px solid rgba(255, 255, 255, 0.15);
	position: relative;
	z-index: 1000;
}

.sidebar-content.zero-width {
	width: 0;
	padding: 0;
}

.sidebar-button-wrapper {
	display: flex;
    height: fit-content;
    width: 100%;
	background-color: #EAEEF1;
}

.sidebar-actual-content {
	display: flex;
    width: 100%;
    overflow: auto;
  }

  .sidebar-actual-content.enter {
	padding: 10px;
	height: 100%;
  }

  .admin-panel {
  display: flex;
  flex-direction: column;
  }

  .loading-panel {
	display: flex;
	justify-content: center;
	align-items: center;
  }

@keyframes fullSlideOut {
	0% {
	  transform: translateY(0%);
	}
	100% {
	  transform: translateY(-30%);
	  opacity: 0;
	}
  }
  
  @keyframes fullSlideIn {
	0% {
	  transform: translateY(30%);
	  opacity: 0;
	}
	100% {
	  transform: translateY(0%);
	  opacity: 1;
	}
  }
  
  /* Apply animation classes based on active state */
  .sidebar-actual-content.exit {
	animation: fullSlideOut 0.3s forwards ease-in-out;
  }
  
  .sidebar-actual-content.enter {
	animation: fullSlideIn 0.3s forwards ease-in-out;
  }

  .sidebar-collapse {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	left: calc(100% - 1px);
	top: 50%;
	background: #EAEEF1;
	color: #252627;
	border: 1px solid rgba(255, 255, 255, 0.15);
	border-left: none;
	border-top-right-radius: 60px;
	border-bottom-right-radius: 60px;
	overflow: hidden;
	font-size: 12px;
	padding: 10px 0px;
  }

  .sidebar-collapse button {
	border: none;
	color: #252627;
	background: transparent;
  }

  .sidebar-collapse button:hover, .sidebar-collapse button:focus {
	color: #252627;
	box-shadow: none !important;
}

/* .sidebar-button-wrapper + .sidebar-button-wrapper.active > .sidebar-button {
    border-bottom-right-radius: 15px 35px;
}

.sidebar-button-wrapper.active + .sidebar-button-wrapper > .sidebar-button {
    border-top-right-radius: 15px 35px;
} */

.showExample {
	white-space: nowrap;
	width: fit-content;
}

@media (max-width: 950px) {
	.DashboardContent {
		flex-direction: column-reverse;
	}

	.PaymentPage {
		height: calc(100vh - 109px);
	}

	.sidebar-menu {
		flex-direction: row;
		align-items: center;
		width: 100%;
		height: 72px;
		padding: 0px 10px;
		border-top: 1px solid rgba(255, 255, 255, 0.15);
	}

	.sidebar-button {
		padding: 15px 5px;
	}

	.sidebar-content {
		position: absolute;
		bottom: 72px;
		width: 100%;
		height: 80%;
		transition: height .25s ease-in-out;
		border-top: 1px solid rgba(255, 255, 255, 0.15);
		border-right: none;
		box-shadow: 0px -10px 20px -10px #000000a6;
	}

	.sidebar-content.zero-width {
		width: 100%;
		height: 0;
		box-shadow: none;
	}

	.sidebar-collapse {
		left: calc(50% - 10px);
		top: unset;
		bottom: calc(100% - 11px);
		rotate: 270deg;
		height: 43px;
	}

	.sidebar-top, .sidebar-bottom {
		display: flex;
	}

	.sidebar-button-wrapper {
		width: 60px;
	}
}

.update-banner {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px 20px;
	background-color: #1B1F3B;
	color: #ffffff;
}
  
.update-banner span {
	font-size: 14px;
	font-weight: bold;
}
  
.update-banner button {
	padding: 10px 20px;
	background-color: #ffffff;
	color: #1B1F3B;
	border: 2px solid #1B1F3B;
	border-radius: 5px;
	font-size: 14px;
	font-weight: bold;
	cursor: pointer;
	transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}

  .update-banner button:hover {
	background-color: #1B1F3B;
	color: #ffffff;
  }
  
  body.dark .sidebar-menu {
	background: rgb(24, 25, 26);
}

body.dark .sidebar-button {
	color: #EAEEF1;
	background: rgb(24, 25, 26);
}

body.dark .sidebar-button-wrapper.active > .sidebar-button {
	color: white;
	background: #252627;
}

body.dark .sidebar-button:hover, body.dark .sidebar-button:focus {
	color: white;
}

body.dark .sidebar-content {
	background-color: #252627;
}

body.dark .sidebar-button-wrapper {
	background-color: #252627;
}

body.dark .sidebar-collapse {
	background: #252627;
  }

  body.dark .sidebar-collapse button {
	color: white;
  }

 body.dark .sidebar-collapse button:hover, body.dark .sidebar-collapse button:focus {
	color: white;
}

.paste-table-modal .modal-body {
	margin: 5%;
	overflow: visible;
}

.paste-table-modal select {
	height: 28px;
	width: 100%;
	font-size: 13px;
	font-weight: 700;
	border-color: hsl(0, 0%, 80%);
	border-radius: 4px;
	padding: 0px 5px;
}

.paste-table-modal input[type='text'] {
	height: 28px;
	width: 100%;
	font-size: 13px;
	font-weight: 700;
	border: 1px solid hsl(0, 0%, 80%);
	border-radius: 4px;
	padding: 0px 5px;
	margin-top: 15px;
}

.rc-tooltip {
	z-index: 1002 !important;
}

.tooltip-image {
	width: 100%;
}

.responsesizespan .rc-slider {
	margin-bottom: 0px;
}

.Header button.szh-menu-button {
	background-color: transparent;
	border: none;
    display: flex;
    gap: 4px;
    align-items: center;
    width: fit-content;
	margin: 0 15px 0px 10px;
}

.newFormat {
	display: flex;
	justify-content: center;
	padding: 10px;
	font-size: 12px;
}

.post-image-loading {
	display: flex;
    height: 97%;
    font-size: 2.5em;
    align-items: center;
}

.sidebar-actual-content .post-image-loading {
	justify-content: center;
}

.zero-state {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.candragtrue {
	cursor: grab;
}

.style-5 .newsRight {
	position: relative;
	justify-content: flex-start;
}

.style-5 .newsRight::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 10px;
    background: linear-gradient(to bottom, rgb(151, 151, 151) 0%, white 20%, white 80%, rgb(151, 151, 151) 100%);
	z-index: 10;
}

.style-5 .topbottom .newsRight::before {
    width: 100%;
    height: 10px;
    background: linear-gradient(to right, rgb(151, 151, 151) 0%, white 20%, white 80%, rgb(151, 151, 151) 100%);
    z-index: 0;
}

.style-5 .bottomtop .newsRight::before {
    width: 100%;
    height: 10px;
	top: unset;
	bottom: 0;
    background: linear-gradient(to right, rgb(151, 151, 151) 0%, white 20%, white 80%, rgb(151, 151, 151) 100%);
    z-index: 0;
}

.style-5 .topbottom .newsTitle, .style-5 .bottomtop .newsTitle {
	margin-left: unset;
}

.style-5 .topbottom .newsTitle::before, .style-5 .bottomtop .newsTitle::before {
	display: none;
}

.style-5 .rightleft .newsRight::before {
	top: 0;
	left: unset;
    right: 0;
}

.style-5 .rightleft .newsTeam {
	margin-right: unset;
}

.style-5 .rightleft .newsTitle {
	margin-left: unset;
	padding-left: calc(30px * var(--sizemultiplier));
}

.style-5 .rightleft .newsTitle::before {
	right: unset;
	left: 100%;
	transform: translateY(-50%);
}

.style-5 .newsTeam {
	max-width: 100%;
	margin-left: unset;
	background-position: calc(100% + calc(var(--logohorizontalmarginnews) * var(--sizemultiplier))) calc(50% + calc(var(--logoverticalmarginnews) * var(--sizemultiplier)));
	filter: drop-shadow(0px 4px 2px #00000095) drop-shadow(0px 8px 2px #00000090) drop-shadow(0px 12px 2px #00000085) drop-shadow(0px 16px 2px #00000080) drop-shadow(0px 20px 2px #00000075) drop-shadow(0px 24px 2px #00000070);
}

.style-5 .textTogether {
	position: absolute;
    top: 50%;
	width: 100%;
}

.style-5 .topbottom .textTogether, .style-5 .bottomtop .textTogether {
	top: unset;
}

.style-1 .topbottom .newsTitle, .style-1 .bottomtop .newsTitle {
	justify-content: center;
}

.style-5 .newsTitle {
	flex-direction: column;
   position: relative;
   margin-left: calc(4px * var(--sizemultiplier));
   margin-right: calc(-4px * var(--sizemultiplier));
   padding-left: calc(7px * var(--sizemultiplier));
   box-shadow: none;
   filter: drop-shadow(0px calc(13px * var(--sizemultiplier)) calc(13px * var(--sizemultiplier)) black);
}

.style-5 .newsTitle::before {
	content: '';
	position: absolute;
    top: 50%;
    right: 100%;
    transform: rotateY(180deg) translateY(-50%);
    color: var(--headercolornews);
    border-left: calc(var(--barheight) / 2) solid;
    border-top: calc(var(--barheight) / 2) solid transparent;
    border-bottom: calc(var(--barheight) / 2) solid transparent;
}

.style-5 .leftright .newsTitle::after,
.style-5 .rightleft .newsTitle::after {
	content: '';
	display: block;
	background: var(--headerfontcolornews);
	height: calc(5px * var(--sizemultiplier));
	bottom: 0px;
	width: calc(15px * var(--sizemultiplier));
}

.style-6 .newsRight {
	position: relative;
	justify-content: center;
	z-index: 2;
	box-shadow: none;
}

.style-6 .newsTeam {
	width: calc(var(--logosizenews)* var(--panesplitnewsright) / 100* var(--sizemultiplier));
	height: calc(var(--logosizenews)* var(--panesplitnewsright) / 100* var(--sizemultiplier));
	margin: unset;
	filter: none;
	padding: calc(14px * var(--sizemultiplier));
}

.style-6 .newsTitle {
   margin-left: calc(4px * var(--sizemultiplier));
   padding-left: calc(7px * var(--sizemultiplier));
   box-shadow: none;
   background: none;
   filter: none;
   justify-content: center;
   display: flex;
   align-items: center;
   transform: rotate(3deg);
   width: fit-content;
	padding: 0% 5%;
	background-color: var(--headercolornews);
	box-shadow: rgba(0, 0, 0, 0.16) 0px calc(3px * var(--sizemultiplier)) calc(6px * var(--sizemultiplier)), rgba(0, 0, 0, 0.23) 0px calc(3px * var(--sizemultiplier)) calc(6px * var(--sizemultiplier));
}

.style-6 .textTogether {
	display: flex;
    flex-direction: column;
    align-items: center;
}

.style-6 .newsText {
	display: flex;
	justify-content: center;
}

.minimize-left {
	left: 0;
	width: fit-content !important;
}

.minimize-center {
	width: fit-content !important;
}

.minimize-right {
	right: 0;
	width: fit-content !important;
}

.sidePanelHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 10px;
	height: 27px;
}

.sidePanelHeader h5 {
	margin: 0;
	font-size: 18px;
	white-space: nowrap;
}

.sidePanelHeader .szh-menu-button {
	margin-left: 5px;
}

.form-switch {
	display: flex !important;
	align-items: center;
}

.form-switch .form-check-label {
	font-size: 11px;
	white-space: nowrap;
}

.form-switch .form-check-input {
	margin: 0 10px 0 0;
}

.form-switch .form-check-input.custom-switch-checked {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e") !important;
	background-color: var(--primarycolor);
	border-color: var(--primarycolor);
}

.flexRowHeader {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 8px;
}

.flexRowHeader h5 {
	margin: 0;
}

.noButton {
	border: none;
    background: none;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
	border-radius: 5px;
}

body.dark .noButton {
	color: white;
}

.noButton .material-symbols-outlined {
	font-size: 20px;
}

.spanstatbackground {
	display: flex;
	align-items: center;
}

.spanstatbackground.nottransparent {
	display: flex;
	justify-content: center;
	align-items: center;
	line-height: normal;
	padding: 0px calc(var(--poststatpadding) * var(--sizemultiplier));
	border-radius: calc(var(--poststatborderradius) * var(--sizemultiplier));
	width: 100%;
	height: var(--poststatheight);
}

.spanstatbackground.nottransparent .spanstat {
	transform: translateY(calc(var(--statmargin) * var(--sizemultiplier)));
}

.angleSlider {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 5px;
	position: relative;
	gap: 10px;
}

.angleSlider .editor-input, .gradientSettings .editor-input {
    display: flex;
    align-items: center;
}

.angleSlider .editor-input input,
.gradientSettings .editor-input input {
	width: 50px;
}

.gradientSettings {
    display: flex;
    margin: 7px;
    gap: 5px;
    align-items: center;
	justify-content: space-around;
}

.gradientName {
	display: flex;
	flex-direction: column;
	font-size: 12px;
	flex: 1;
	line-height: 1;
}

.gradientNameBottom {
	font-size: 10px;
	text-transform: capitalize;
}

.deleteStopBtn {
	display: flex;
	align-items: center;
    justify-content: center;
	border-radius: 4px;
	font-size: 10px;
	background-color: transparent;
	border: 2px solid transparent;
	font-weight: bold;
	width: 20px;
}

body.dark .deleteStopBtn {
	color: white;
}

.deleteStopSpacer {
	width: 20px;
}

.add-stop {
	border-radius: 4px;
	font-size: 13px;
	font-weight: bold;
	width: 100%;
    background: transparent;
}

.add-stop:hover {
	background: transparent;
}

body.dark .add-stop {
	color: white;
}

.gradientPresets {
	display: flex;
	justify-content: center;
	gap: 5px;
}

.gradientPresetButton {
	width: 40px;
	padding: 2px;
}

.gradientPresetButton span {
	width: 100%;
	height: 100%;
}

.gradientSettings .rc-slider {
	margin: 0;
	width: 45%;
}

.spacing-editor-top {
	display: flex;
	justify-content: center;
}

.spacing-editor-middle {
	display: flex;
	justify-content: space-between;
}

.spacing-editor-bottom {
	display: flex;
	justify-content: center;
}

.spacing-editor input {
	width: 56px;
}

.withTextGradient {
    background-clip: text !important;
   	-webkit-text-fill-color: transparent;
}

.all-spacing-editor {
	display: flex;
}

.spacing-editor {
	flex: 1;
}

.all-buttons {
	display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: center;
	margin-left: 5px;
}

.loadingSpan {
	height: calc(var(--postheight) - 32px);
}

.graphicPlaceholder {
	display: flex;
	align-items: center;
	justify-content: center;
	height: var(--postheight);
	width: var(--postwidth);
	background-color: black;
}

.rich-editor .form-switch {
	padding: 0;
	margin: 0px 5px 10px 5px;
	height: 100%;
}

/* no new styles here - use individual files */