.homedemo {
    background-color: rgb(111, 0, 255);
    background-image: linear-gradient(120deg, rgb(111, 0, 255), #7785d9), radial-gradient(ellipse 120% 100% at 50% -10%, #0064e040, #4775ff00), radial-gradient(ellipse 50% 100% at 50% -30%, #ffffff70, #ffffff00);
    padding: 20px;
    margin-bottom: 30px;
}

.graphicContainer {
    display: flex;
    justify-content: center;
    gap: 15px;
    align-items: center;
}

.graphicSidebar {
    padding: 10px 0px;
	display: flex;
	flex-direction: column;
    align-items: center;
    align-self: flex-start;
	overflow: auto;
    height: 100%;
    position: relative;
}

.graphicSidebar span {
    color: white;
    white-space: nowrap;
}

.graphicSidebar button {
    border-radius: 6px;
	font-size: 12px;
	padding: 7px;
	margin: 5px;
	background-color: white;
	color: var(--primarycolor);
	font-weight: bold;
	border: 2px solid var(--primarycolor);
    width: 250px;
    text-transform: capitalize;
    white-space: nowrap;
}

.graphicSidebar button.selected {
    background-color: var(--primarycolor);
	color: white;
}

.graphicSidebar button.backBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: none;
    color: white;
    font-weight: bold;
    font-size: 18px;
}


.graphicSidebar button.randomizeBtn {
    width: fit-content;
    position: absolute;
    right: 0;
    top: 0;
    background-color: transparent;
    border: none;
    color: white;
    font-weight: bold;
    font-size: 18px;
}

.moreReasons {
    margin-top: 20px;
}

.moreReasons li {
    margin: 10px 0px;
}

@media (max-width: 950px) {
    .graphicContainer {
        flex-direction: column;
    }

    .graphicSidebar {
        flex-direction: row;
        width: calc(100vw - 30px);
    }

    .graphicSidebar button.backBtn {
        width: fit-content;
        top: -20px;
    }
}