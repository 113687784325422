.handle {
	position: absolute;
	cursor: grab;
	font-size: calc(1.5em * var(--sizemultiplier));
	transform: rotate(90deg);
	text-shadow: 0px 0px calc(3px * var(--sizemultiplier)) black;
	z-index: 1;
}

.canMove {
	border: 2px dashed red;
}

.newsLeft.image-style-2, .newsLeft.image-style-3 {
	position: relative;

	.newsImage {
		overflow: visible;
	}

	.newsTeam {
		width: calc(calc(var(--postheight) / 16) + calc(var(--logosizenews) * var(--sizemultiplier) / 5));
		height: calc(calc(var(--postheight) / 16) + calc(var(--logosizenews) * var(--sizemultiplier) / 5));
		margin: unset;
		position: relative;
		filter: none;
		top: unset;
	}

	.background-text {
		position: absolute;
		width: stretch;
		top: calc(calc(calc(var(--postheight) / 16) + calc(var(--logosizenews) * var(--sizemultiplier) / 5)) + calc(16px * var(--sizemultiplier)));
		bottom: 0;
		left: 0;
		right: 0;
		line-height: 0.8;
		opacity: 0.2;
		font-family: 'theboldone';
	}

	.edit-box {
		position: absolute;
		top: 6px;
		left: 6px;
	}

	.newsTeamContainer {
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.file-box {
		background-color: #000000;
	}

	.pattern-div {
		--newsteamsize: calc(calc(var(--postheight) / 16) + calc(var(--logosizenews) * var(--sizemultiplier) / 5));
		position: absolute;
		z-index: 2;
		top: 0;
		left: var(--newsteamsize);
		height: calc(var(--newsteamsize) - 5%);
		width: calc(90% - var(--newsteamsize));
		margin: 5%;
		opacity: 0.2;
		background: repeating-linear-gradient(
			to bottom,
			transparent 0%,
			transparent 18%,
			var(--pattern-color, black) 18%,
			var(--pattern-color, black) 20%
		);
	}

	.floatingImage {
		overflow: hidden;
		margin: 45% -15% 10% 10%;
		z-index: 3;
	}
}

.topbottom {
	.newsLeft.image-style-3 {
		.floatingImage {
			margin: 5% 5% -5% 25%;
		}
	
		.background-text, .pattern-div {
			display: none;
		}
	}
}

.bottomtop {
	.newsLeft.image-style-3 {
		.edit-box {
			top: unset;
			bottom: 6px;
		}

		.newsTeamContainer {
			top: unset;
			bottom: 0;
		}

		.background-text, .pattern-div {
			display: none;
		}

		.floatingImage {
			margin: -5% 5% 5% 25%;
		}
	}
}

.leftright {
	.newsLeft.image-style-3 {
		.newsTeam {
			margin-right: unset;
		}
	}
}

.rightleft {
	.newsLeft.image-style-3 {
		.floatingImage {
			margin: 45% 10% 10% -15%;
		}

		.edit-box {
			left: unset;
			right: 6px;
		}

		.newsTeamContainer {
			left: unset;
			right: 0;
		}

		.newsTeam {
			margin-left: unset;
		}

		.pattern-div {
			left: unset;
			right: calc(calc(var(--postheight) / 16) + calc(var(--logosizenews) * var(--sizemultiplier) / 5));
		}
	}
}

.newsLeft.image-style-2 {
	.floatingImage {
		margin: 10%;
	}

	.topbottom, .bottomtop {
		.floatingImage {
			margin: 5%;
		}
	}
}


.topbottom {
	.hasImage3 {
		.highlightTitle,
		.lineupTitle,
		.noTitle {
			margin-top: 6%;
		}
	}
}

.bottomtop {
	.hasImage3 {
		.highlightTitle,
		.lineupTitle,
		.noTitle {
			margin-top: unset;
		}

		.textBoxes {
			margin-top: -8%;
		}
	}
}

.style-2 {
	.bottomtop {
		.hasImage3 {
			.lineupTitle {
				margin-bottom: 8%;
			}
		}
	}	
}

.hasImage3 {
	.lineupTitle {
		padding: calc(20px * var(--sizemultiplier));
	}

	.textBoxes {
		padding: calc(20px * var(--sizemultiplier)) calc(90px * var(--sizemultiplier));
	}
}

.new-style-4 {
	.newsRight {
		z-index: 2;
		box-shadow: none !important;
	}

	.lineupTitle {
		padding: calc(20px * var(--sizemultiplier));
	}

	.textBoxes {
		padding: calc(20px * var(--sizemultiplier)) calc(90px * var(--sizemultiplier));
	}

	.highlightTitle {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 0;

		.textBoxTop {
			margin-bottom: calc(var(--headermarginnews) * var(--sizemultiplier));
		}

		.textBoxBottom {
			display: flex;
			align-items: center;
			padding: 0% 15%;
			background-color: var(--headercolornews);
			box-shadow: rgba(0, 0, 0, 0.16) 0px calc(3px * var(--sizemultiplier)) calc(6px * var(--sizemultiplier)), rgba(0, 0, 0, 0.23) 0px calc(3px * var(--sizemultiplier)) calc(6px * var(--sizemultiplier));
			
			span {
				transform: translateY(var(--lineheightnews));
			}
		}
	}

	.textBoxBottom {
		display: flex;
		align-items: center;
		padding: 2% 30%;
		transform: rotate(-5deg);
		width: fit-content;
		margin-top: calc(-10px * var(--sizemultiplier));

		span {
			transform: translateY(var(--badgelineheightnews));
		}
	}

	.textBoxes {
		.textBoxTop {
			font-size: 300%;
			margin-bottom: calc(calc(var(--subtitlemarginnews) - 30px) * var(--sizemultiplier));
		}

		.textBoxBottom {
			align-self: baseline;
			margin-left: 30%;
			transform: rotate(-10deg);
		}
	}
}