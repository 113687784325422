:root {
	--headerfont: 'roboto condensed';
	--namefont: 'roboto condensed';
	--statfont: 'roboto condensed';
	--headersize: 20;
	--namesize: 24px;
	--statsize: 23px;
	--logoorder: 1;
	--logoopacity: 100%;
	--logosize: 60px;
	--headerspacing: 0px;
	--namemargin: 0px;
	--headercolor: #000000;
	--headerfontcolor: #ffffff;
	--watermarkfont: 'roboto condensed';
	--watermarksize: 15px;
	--watermarkwidth: 75px;
	--statmargin: 0px;
	--statmargin2: 0px;
	--watermarkcolor: #ffffff;
	--nameshadow: 0px;
	--bordercolor: #ffffff;
	--namespacing: 0px;
	--statspacing: 0px;
	--statshadow: 0px;
	--logoposition: 292px;
	--lineheight: 0.9;
	--namebackground: #000000;
	--statbackground: #000000;
	--namecolor: #ffffff;
	--statcolor: #ffffff;
	--imagehorizontalnews: 0px;
	--imageverticalnews: 0px;
	--imagezoomnews: 100%;
	--headersizenews: 20px;
	--headerfontnews: 'roboto condensed';
	--headermarginnews: 0px;
	--headerspacingnews: 0px;
	--headercolornews: #ffffff;
	--headerfontcolornews: #000000;
	--logoopacitynews: 100%;
	--logosizenews: 500px;
	--orientation: 'leftright';
	--orientationnews: 'leftright';
	--orientationhighlight: 'leftright';
	--orientationlineup: 'leftright';
	--orientationmatchup: 'topbottom';
	--borderradius: 5px;
	--scoremargin: 5px;
	--panesplitleft: 50;
	--panesplitright: 50;
	--panesplitnewsleft: 50;
	--panesplitnewsright: 50;
	--panesplitmatchupleft: 80;
	--panesplitmatchupright: 20;
	--panesplithighlightleft: 50;
	--panesplithighlightright: 50;
	--panesplitlineupleft: 50;
	--panesplitlineupright: 50;
	--logoshadow: 0px;
	--namemargin2: 10px;
	--postwidth: 800px;
	--postheight: 800px;
	--postwidthnews: 800px;
	--postheightnews: 800px;
	--tierfont: 'roboto condensed';
	--tiercolor: #ffffff;
	--tiersize: 75px;
	--duplicatelogosize: 500px;
	--duplicatelogoposition: 0px;
	--duplicatelogoopacity: 10%;
	--gridmargin: 1%;
	--headeroutlinecolor: #ffffff;
	--nameoutlinecolor: #ffffff;
	--statoutlinecolor: #ffffff;
	--headeroutlinesize: 0px;
	--nameoutlinesize: 0px;
	--statoutlinesize: 0px;
	--headertransform: 'none';
	--nametransform: 'none';
	--stattransform: 'none';
	--nameshadowcolor: #000000;
	--statshadowcolor: #000000;
	--sizemultiplier: 1;
	--postbackground: none;
	--titleheight: 10%;
	--subtitlefont: 'roboto condensed';
	--subtitlefontsize: 10;
	--subtitlespacing: 0px;
	--subtitlefontcolor: #ffffff;
	--subtitleoutlinecolor: #ffffff;
	--subtitleoutlinesize: 0px;
	--subtitletransform: 'none';
	--ranksize: 23px;
	--rankfont: 'roboto condensed';
	--rankfontcolor: #ffffff;
	--rankmargin: 0px;
	--rankspacing: 0px;
	--rankoutlinesize: 2px;
	--rankoutlinecolor: #000000;
	--logosizegrid: 100%;
	--logosizetier: 100%;
	--pointssize: 20px;
	--pointsfont: 'roboto condensed';
	--pointsfontcolor: #ffffff;
	--pointsmargin: 0px;
	--pointsmargin2: 0px;
	--pointsspacing: -1.2px;
	--pointsoutlinesize: 0px;
	--pointsoutlinecolor: #000000;
	--matchuppointsbackground: #00000000;
	--pointsbackground: #000000;
	--playerheight: 1;
	--statlineheight: 1;
	--playermargin: 0px;
	--texturesize: 5px;
	--textureopacity: 10%;
	--calcTextureSize: calc(var(--texturesize) * var(--sizemultiplier));
	--matchuprankbackground: #00000000;
	--rankbackground: #000000;
	--actualheadercolor: #00000000;
	--rankwidth: 40px;
	--rankmargin2: 0px;
	--boxrankoutlinesize: 0px;
	--bordersize: 2px;
	--headerbordersize: 2px;
	--headerbordercolor: #ffffff;
	--boximagebackground: 'none';
	--logodropshadow: 0px;
	--footerheight: 30px;
	--footerspacing: 0px;
	--footermargin: 0px;
	--footerfont: 'roboto condensed';
	--footersize: 20px;
	--footerfontcolor: #ffffff;
	--footerbackground: #000000;
	--footeroutlinesize: 0px;
	--footeroutlinecolor: #000000;
	--footeralignment: center;
	--tierbackground: #00000000;
	--tophighlight: #0eff00;
	--bottomhighlight: #ff0000;
	--subtitlefontnews: 'roboto condensed';
	--subtitlesizenews: 20px;
	--subtitlemarginnews: 20px;
	--subtitlespacingnews: 0px;
	--imageshadowcolor: #000000;
	--imageshadowsize: 20px;
	--descriptionfont: 'roboto condensed';
	--descriptionsize: 25px;
	--descriptionmargin: 0px;
	--descriptionspacing: 0px;
	--descriptionfontcolor: #ffffff;
	--descriptionoutlinecolor: #000000;
	--descriptionoutlinesize: 2px;
	--descriptiontransform: 'none';
	--comparisonradius: 10px;
	--comparisonmargin: 20px;
	--comparisonbordersize: 2px;
	--comparisonbordercolor: #ffffff;
	--labelfont: 'roboto condensed';
	--labelssize: 20px;
	--labelsmargin: 6px;
	--labelsspacing: 0px;
	--labelsfontcolor: #ffffff;
	--labelsbackground: #000000;
	--labelstransform: 'none';
	--boxglowwidth: 0px;
	--boxglowcolor: #ffffff;
	--lineheightnews: 0px;
	--lineheighttextnews: 1.3;
	--imagetitlecolor: #ffffff;
	--imagelinescolor: 'secondary';
	--badgelineheightnews: 0px;
	--titlebackground: 'secondary';
	--textbackground: 'secondary';
	--teamcontainerbackground: 'primary';
	--logoverticalmargin: 0px;
	--logohorizontalmargin: 0px;
	--logoverticalmarginnews: 0px;
	--logohorizontalmarginnews: 0px;
	--line1size: 100%;
	--line2size: 100%;
	--line3size: 100%;
	--line1sizestat: 100%;
	--line2sizestat: 100%;
	--line3sizestat: 100%;
	--footerpadding: 0px;
	--playerorder: 3;
	--rankmarginreal: 10px;
	--rankoutsidemargin: 0px;
	--rankglowwidth: 0px;
	--rankglowcolor: #ffffff;
	--rankborderradius: 5px;
	--poststatbackground: 'none';
	--poststatpadding: 0px;
	--poststatheight: 100%;
	--poststatborderradius: 0px;
	--gradientdirection: 90deg;
	--gradientdirectionnews: 180deg;
	--backgroundgradientdirectiondeg: 180deg;
	--leftbackgroundgradientdirection: 180deg;
	--playeraspectratio: 1.3;
	--boxmargintop: 0px;
	--boxmarginright: 0px;
	--boxmarginbottom: 0px;
	--boxmarginleft: 0px;
	--subtitlebackground: '#00000000';
	--subtitlemargintop: -7px;
	--subtitlemarginright: 0px;
	--subtitlemarginbottom: 0px;
	--subtitlemarginleft: 0px;
	--subtitlepaddingtop: 0px;
	--subtitlepaddingright: 0px;
	--subtitlepaddingbottom: 0px;
	--subtitlepaddingleft: 0px;
	--subtitlealignment: center;
	--subtitlealignmentnews: left;
	--titlebackgroundcolor: '#00000000';
	--titlemargintop: 0px;
	--titlemarginright: 0px;
	--titlemarginbottom: 0px;
	--titlemarginleft: 0px;
	--titlepaddingtop: 0px;
	--titlepaddingright: 0px;
	--titlepaddingbottom: 0px;
	--titlepaddingleft: 0px;
	--headeralignment: center;
	--headeralignmentnews: left;
	--thirdtitlefont: 'roboto condensed';
	--thirdtitlefontsize: 10;
	--thirdtitlespacing: 0px;
	--thirdtitlefontcolor: #ffffff;
	--thirdtitleoutlinecolor: #ffffff;
	--thirdtitleoutlinesize: 0px;
	--thirdtitletransform: 'none';
	--thirdtitlebackground: '#00000000';
	--thirdtitlemargintop: 0px;
	--thirdtitlemarginright: 0px;
	--thirdtitlemarginbottom: 0px;
	--thirdtitlemarginleft: 0px;
	--thirdtitlepaddingtop: 0px;
	--thirdtitlepaddingright: 0px;
	--thirdtitlepaddingbottom: 0px;
	--thirdtitlepaddingleft: 0px;
	--thirdtitlealignment: center;
	--headertextgradientdirection: 180deg;
	--subtitletextgradientdirection: 180deg;
	--thirdtitletextgradientdirection: 180deg;
	--pointstransform: 'uppercase';
	--playernamefont: 'jersey';
	--playernamesize: 165px;
	--playernamespacing: 0px;
	--playernamemargin: 5%;
	--playernumberfont: 'jersey';
	--playernumbersize: 325px;
	--playernumberspacing: 0px;
	--playernumbermargin: 26%;
	--iconfont: 'roboto condensed';
	--iconsize: 18px;
	--bannerfont: 'roboto bold';
	--bannersize: 12px;

	--boxstatminwidth: 0px;
	--playerimagewidth: 0px;
	--teamimagewidth: 0px;
	--pyramidboxwidth: 800px;

	/* https://coolors.co/00a199-0058a1-9900a1-a10008-08a100 */
	--primarycolor: #00a199;
}
