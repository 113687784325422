.flexHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .addCronBtn {
        width: fit-content;
        height: fit-content;
        margin: 0;
    }
}